/* eslint-disable react/self-closing-comp */
/* eslint-disable vars-on-top */
/* eslint-disable eqeqeq */
/* eslint-disable prefer-const */
/* eslint-disable no-var */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-use-before-define */
/* eslint-disable array-callback-return */
/* eslint-disable camelcase */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import {
  Modal,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Form,
  Box,
  TextField,
  Grid,
  FormGroup,
  IconButton
} from '@material-ui/core'
import WarningIcon from '@mui/icons-material/Warning'
import FormLabel from '@mui/material/FormLabel'
import * as FileSaver from 'file-saver'
import XLSX from 'sheetjs-style'
// for print user in consol
import jwt_decode from 'jwt-decode'
import { useSelector } from 'react-redux'
// for print user in consol
import axios from 'axios'

import clsx from 'clsx'
// import $ from 'jquery';
import { useHistory } from 'react-router-dom'
import Table from '../../components/Table/Table'
import styles from './BOMMaster.module.css'
import { CustomFormGroup, CustomSelect } from '../../components/FormComponents'
import BackButton from '../../components/BackButton/BackButton'
import {
  DATE_FORMAT,
  API_RESOURCE_URLS,
  DISPLAY_MESSAGES,
  MESSAGE_TYPE,
  MESSAGE_TYPE_CERTIFICATION,
  MESSAGE_TYPE_ANNEXURE,
  RESOURCE_TYPE,
  USER_OPERATIONS,
  Regex
} from '../../constants'
import { withAllowedOperationsProvider } from '../../hocs'
import { CustomTab, Button, AuthChecker } from '../../atomicComponents'
import { API } from '../../apis/api'
import { buildErrorMessage } from '../../apis/calls'
import { usePopupManager } from '../../providers/PopupManager/PopupManager'

import StepBar1 from '../../components/StepBar1/StepBar'
import { GridLoadingSpinner } from '../../components'

const VendarData = () => {
  const history = useHistory()
  const { showPopup } = usePopupManager()
  // const baseUrl='http://127.0.0.1:8000/file/'
  const baseUrl = useSelector(state => state.counter.baseUrl) // useSelector
  const auth = useSelector(state => state.auth) // useSelector
  const { userRole, userID, preferredUsername } = auth
  // console.log("auth in Supplier: ", auth);
  const [dynamicColumns, setDynamicColumns] = useState([])
  const [dynamicColumns1, setDynamicColumns1] = useState([])
  const [plant, setPlant] = useState(null)
  const [flag, show] = useState(false)
  const [checkDisable, setDisable] = useState(false)
  const [projectCode, setProjectCode] = useState(null)
  const [projectCodeArray, setProjectCodeArray] = useState([])
  console.log('bcArray ProjectCodeArray: ', projectCodeArray)
  const [isLatestProjectSelected, setIsLatestProjectSelected] = useState(false) // New state for button enable/disable
  // const [projectCodeArray, setProjectCodeArray] = useState([]);
  const [selectedProjectCode, setSelectedProjectCode] = useState(null)
  console.log('Project state: ', selectedProjectCode)
  const [latestProject, setLatestProject] = useState('')
  const [Bc, setBC] = useState(null)
  const [bcArray, setBcArray] = useState([])
  console.log('bcArray: ', bcArray)
  const [plantArray, setPlantArray] = useState([])
  const [tableArray, setTableArray] = useState([])
  const [fyArray, setFyArray] = useState([])
  const [Fy, setFY] = useState(null)
  const [quarterArray, setQuarterArray] = useState([])
  const [quarter, setQuarter] = useState(null)
  const [asnCount, setAsnCount] = useState(0)
  const [loading, setLoader] = useState(false)
  const [loader, setLoading] = useState(true)
  const [vendCode, setvendCode] = useState()
  const [datesData, setDates] = useState({
    endDate: '',
    startDate: ''
  })

  //
  const [isPopupOpenFormModal, setPopupOpenFormModal] = useState(false)
  const handleOpenFormModal = () => {
    setPopupOpenFormModal(true)
  }
  const handleCloseFormModal = () => setPopupOpenFormModal(false)

  //

  const ROW_HEIGHT = 38

  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const handleOpen = () => setIsPopupOpen(true)
  const handleClose = () => setIsPopupOpen(false)
  const goNext = () => {
    uploadFileData()
  }

  const redirectToApplicationLandingPage = () => {
    history.push('/')
  }

  const setCount = count => {
    setAsnCount(count)
  }

  useEffect(() => {
    // getdate();
    getRecentProjectCode()
    getAllProjectCode()
    // console.log('Preferred username: ', preferredUsername);
    // if(preferredUsername) {
    //   getVcForProjectCode()
    // }
  }, [])

  const handleDropdownChange = (selectedOption, name) => {
    console.log('selectedOption: ', selectedOption)
    if (name === 'Project_Code') {
      setSelectedProjectCode(selectedOption)
      // if (selectedProjectCode !== null) {
      //   getVcForProjectCode(selectedOption)
      // }
    }
  }

  // useEffect(() => {
  //   getAllProjectCode();
  // }, []);

  useEffect(() => {
    if (selectedProjectCode) {
      getVcForProjectCode(selectedProjectCode)
    }
  }, [selectedProjectCode])

  // const getdate = () => {
  //   const headerss = new Headers();
  //   headerss.append("Content-Type", "application/json");
  //   fetch(`${baseUrl}Milestone_calendarView/`, {
  //     method: "GET",
  //     headers: headerss,
  //   })
  //     .then((response) => response.json())
  //     .then((responseJson) => {
  //       const endDate = responseJson[0].Collect_DVA_from_vendors_EndDate;
  //       const startDate = responseJson[0].Collect_DVA_from_vendors_StartDate;
  //       setDates((prevData) => ({ ...prevData, endDate, startDate }));
  //     })
  //     .catch((error) => {});
  // };

  const showTable = () => {
    console.log('ProjectCode: ', projectCode)
    console.log('Project value: ', projectCode?.value)
    // if(projectCode === null || projectCode.value === ''){
    if (
      selectedProjectCode === null ||
      selectedProjectCode.value === '' ||
      selectedProjectCode.value === null
    ) {
      showPopup({
        type: MESSAGE_TYPE.INFORMATION,
        contextText: MESSAGE_TYPE.INFORMATION,
        info: 'Project Code is Mandatory'
      })
    }
    // else if (Bc === null || Bc?.value === '' || Bc?.value === null || Bc === undefined) {
    //   showPopup({
    //     type: MESSAGE_TYPE.INFORMATION,
    //     contextText: MESSAGE_TYPE.INFORMATION,
    //     info: "VC is Mandatory",
    //   })
    // }
    else {
      getTableData()
      show(true)
    }
  }

  // let Vendor_Code ='';
  // const handleSelect = (selectedOption, name) => {
  //   // alert(selectedOption.value);
  //   // console.log(selectedOption);
  //   setvendCode(selectedOption.value);
  //   if (name == "VC") {
  //     show(false);

  //     setBC(selectedOption);
  //     // getPlantData(selectedOption)
  //   } else {
  //     setPlant(selectedOption);
  //   }
  // };

  const getTableData = async () => {
    console.log('Selected Project Code:', selectedProjectCode?.value)
    console.log('Selected VC:', Bc?.value)

    const apiFormattedData = {
      Project_code: selectedProjectCode?.value,
      VC: Bc?.value
    }

    const apiFormattedDataJson = JSON.stringify(apiFormattedData)

    setLoader(true)
    try {
      const response = await axios.post(
        // const response = await axios.put(
        // `${baseUrl}ShowMasterDataofPCandVC/`,
        // `${baseUrl}ShowProductLineBOMData/`,
        `${baseUrl}MasterDataSheet/`,
        apiFormattedData,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )

      console.log('API Response:', response.data)

      if (response.data.error) {
        // alert(`API Error: ${response.data.error}`);
        setLoader(false)
        showPopup({
          type: MESSAGE_TYPE.FAILURE,
          contextText: MESSAGE_TYPE.FAILURE,
          info: 'Error fetching data, please try again.'
        })
        setLoading(false)
      } else if (Array.isArray(response.data)) {

        setLoader(false)
        // If the response is an array, set it directly
        console.log('Table Data:', response.data)
        // const arr = response.data.map((item) => ({
        //   ...item,
        //   Send_to_vendor: item?.Send_to_vendor === true ? "True" : "False",
        //   is_deleted: item?.is_deleted === true ? "True" : "False",
        //   AppliedForPLI: item?.AppliedForPLI === true ? "True" : "False",
        // }));
        setTableArray(response.data)
        setLoading(false)
      } else {

        setLoader(false)
        // Unexpected format
        // alert("Unexpected API response format.");
        showPopup({
          type: MESSAGE_TYPE.FAILURE,
          contextText: MESSAGE_TYPE.FAILURE,
          info: 'Error fetching data, please try again.'
        })
        console.log('Unexpected API response format:', response.data)
        setLoading(false)
      }
    } catch (error) {
      setLoader(false)
      console.error('Fetch error:', error)
      // alert("Error fetching data, please try again.");
      showPopup({
        type: MESSAGE_TYPE.FAILURE,
        contextText: MESSAGE_TYPE.FAILURE,
        info: 'Error fetching data, please try again.'
      })
      setLoading(false)
    }
  }

  const getAllData = () => {
    let headerss = new Headers()
    headerss.append('Content-Type', 'application/json')

    fetch(`${baseUrl}GetAllMasterData/`, {
      method: 'GET',
      headers: headerss
    })
      .then(response => response.json())
      .then(responseJson => {
        if (responseJson === null || responseJson.length === 0) {
          showPopup({
            type: MESSAGE_TYPE.FAILURE,
            contextText: MESSAGE_TYPE.FAILURE,
            info: 'No data available'
          })
        } else {
          const transformedData = responseJson.map(item => ({
            ...item,
            Send_to_vendor: item?.Send_to_vendor === true ? 'True' : 'False',
            is_deleted: item?.is_deleted === true ? 'True' : 'False',
            AppliedForPLI: item?.AppliedForPLI === true ? 'True' : 'False'
          }))

          setTableArray(transformedData)
          show(true)
          setLoading(false)
        }
      })
      .catch(error => {
        console.log('error', error)
        showPopup({
          type: MESSAGE_TYPE.FAILURE,
          contextText: MESSAGE_TYPE.FAILURE,
          info: 'Error while fetching data.'
        })
      })
  }

  const getAllProjectCode = async () => {
    try {
      // const { data } = await axios.get(`${baseUrl}AllProjectCode/`);
      const { data } = await axios.get(
        `${baseUrl}AllProjectCodeforProductline/`
      )
      const allProjectCodes = data.map(({ Project_code }) => Project_code)
      const uniqueProjects = [...new Set(allProjectCodes)]
      const projectCodeOptions = uniqueProjects.map(value => ({
        value,
        label: value
      }))

      setProjectCodeArray(projectCodeOptions)
      // await getRecentProjectCode(projectCodeOptions);
    } catch (error) {
      console.error('Error fetching project codes:', error)
    }
  }

  const getRecentProjectCode = async projectCodeOptions => {
    try {
      // const { data, status } = await axios.get(`${baseUrl}LatestProjectCode/`);
      const { data, status } = await axios.get(
        `${baseUrl}LatestProjectCodeforProductline/`
      )
      if (status !== 200) {
        throw new Error('Failed to fetch the latest project code')
      }
      console.log('data in Project_code: ', data)
      const recentProj = {
        label: data?.Project_code,
        value: data?.Project_code
      }
      setLatestProject(recentProj)
      setSelectedProjectCode(recentProj) // Automatically select the latest project code
      getVcForProjectCode(recentProj)
    } catch (error) {
      // console.error("Error fetching recent project codes:", error);
      showPopup({
        type: MESSAGE_TYPE.FAILURE,
        contextText: MESSAGE_TYPE.FAILURE,
        info: 'Could not fetch Project Code!'
      })
    }
  }

  // useEffect(() => {
  //   getAllProjectCode();
  // }, []);

  useEffect(() => {
    if (latestProject) {
      setSelectedProjectCode(latestProject)
    }
  }, [latestProject])

  // Check if the selected project code is the latest project code
  useEffect(() => {
    if (selectedProjectCode && latestProject) {
      setIsLatestProjectSelected(
        selectedProjectCode.value === latestProject.value
      )
    }
  }, [selectedProjectCode, latestProject])

  console.log('selected Bc: ', Bc)
  const getVcForProjectCode = async selectedCode => {
    console.log('selectedCode: ', selectedCode)
    // console.log('selectedCode ?.Project_code: ', selectedCode?.Project_code)
    console.log('selectedProjectCode', selectedProjectCode)

    const headerss = {
      'Content-Type': 'application/json'
    }

    const apiBody = {
      // project_code: selectedProjectCode?.value || selectedCode?.value,
      Project_code: selectedProjectCode?.value || selectedCode?.value
      // spoc: preferredUsername
    }

    try {
      const response = await axios.post(
        // `${baseUrl}GetVCsByProjectAndSPOC/`,
        `${baseUrl}GetVCsByProject/`,
        apiBody,
        { headers: headerss }
      )

      if (response.status >= 200 && response.status < 300) {
        const resArray = response.data?.map(value => ({ value, label: value }))
        console.log('resArray: ', resArray)
        setBcArray(resArray)
      } else {
        console.error(
          `Error fetching VC: ${response.status} - ${response.statusText}`
        )
        showPopup({
          type: MESSAGE_TYPE.FAILURE,
          contextText: MESSAGE_TYPE.FAILURE,
          info: `Failed to fetch data. Error: ${response.status} - ${response.statusText}`
        })
      }
    } catch (error) {
      if (error.response) {
        // Server responded with a status other than 2xx
        console.error(
          `Error fetching VC: ${error.response.status} - ${error.response.data}`
        )
        showPopup({
          type: MESSAGE_TYPE.FAILURE,
          contextText: MESSAGE_TYPE.FAILURE,
          info: `Failed to fetch data. Error: ${error.response.status} - ${error.response.data}`
        })
      } else if (error.request) {
        // Request was made but no response was received
        console.error('Error fetching VC: No response received', error.request)
        showPopup({
          type: MESSAGE_TYPE.FAILURE,
          contextText: MESSAGE_TYPE.FAILURE,
          info: 'Failed to fetch data. No response received from the server.'
        })
      } else {
        // Something else happened in setting up the request
        console.error('Error fetching VC:', error.message)
        showPopup({
          type: MESSAGE_TYPE.FAILURE,
          contextText: MESSAGE_TYPE.FAILURE,
          info: `Failed to fetch data. Error: ${error.message}`
        })
      }
    }
  }

  // const getVcForProjectCode = (selectedCode) => {
  //   console.log('selectedCode: ', selectedCode)
  //   console.log('selectedCode ?.Project_code: ', selectedCode?.Project_code);
  //   let headerss = new Headers();
  //   headerss.append("Content-Type", "application/json");

  //   const apiBody = {
  //     project_code: selectedCode?.value || selectedCode?.Project_code,
  //     spoc: preferredUsername
  //   }
  //   // fetch(`${baseUrl}VCForProjectCode/`, {
  //   fetch(`${baseUrl}GetVCsByProjectAndSPOC/`, {
  //     method: "POST",
  //     headers: headerss,
  //     body: JSON.stringify(apiBody),
  //   })
  //     .then((response) => response.json())
  //     .then((responseJson) => {

  //       if (responseJson?.ok) {
  //         console.log('res GetVCsBy: ', responseJson)
  //         const resArray = responseJson?.map(value => ({ value, label: value }))
  //         console.log('resArray: ', resArray)
  //         setBcArray(resArray)
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching VC:", error);
  //     });
  // };

  const uploadFileData = () => {
    var input = document.createElement('input')
    input.type = 'file'
    input.id = 'file'
    input.setAttribute(
      'accept',
      '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
    )
    input.click()
    input.onchange = e => {
      setLoader(true)
      const formData = new FormData()
      formData.append('file', e.target.files[0])
      fetch(`${baseUrl}SupplierDataaddition/`, {
        method: 'POST',
        body: formData
      })
        .then(response => {
          // eslint-disable-next-line radix
          let status1 = parseInt(response.status)
          if (status1 == '201') {
            //   document.removeChild(input);
            getTableData()
            showPopup({
              type: MESSAGE_TYPE.SUCCESS,
              contextText: MESSAGE_TYPE.SUCCESS,
              info: 'file uploaded sucessfully'
            })
          } else {
            showPopup({
              type: MESSAGE_TYPE.SUCCESS,
              contextText: MESSAGE_TYPE.SUCCESS,
              info: 'file uploaded failed'
            })
          }
        })

        .catch(error => {})
    }
  }

  const exportExcel = async excelData => {
    // const newExcelData = excelData?.map(
    //   (
    //     {
    //       Project_code,
    //       Project_type,
    //       Financial_year,
    //       Quarter,
    //       VC,
    //       VC_description,
    //       Plant_code,
    //       Part_number,
    //       Part_Description,
    //       Supplier_Code,
    //       Supplier_Name,
    //       AppliedForPLI,
    //       Part_UOM,
    //       GR_No,
    //       GR_date,
    //       SOB,
    //       ImportOrDomestic,
    //       Invoice_No,
    //       Invoice_Date,
    //       Supplementary_Invoice_No,
    //       Supplementary_Invoice_Date,
    //       Price_Per_Part_Regular_A,
    //       Price_Per_Part_Supplementary_B,
    //       Total_Part_Price_C,
    //       SPOC,
    //       CTL,
    //       HSN_CODE,
    //       Billing_Currency,
    //       Supplier_Plant_location_Address,
    //       IRN_Number,
    //       GSTIN,
    //       Part_Qty,
    //       BOE_Number,
    //       BOE_Date,
    //       CIF_Value,
    //       BOE_Part_Qty,
    //       BOE_IEC_Of_TML,
    //       is_deleted,
    //       Remark,
    //       created_at
    //     },
    //     id
    //   ) => {
    //     let obj = {
    //       id,
    //       Project_code,
    //       Project_type,
    //       Financial_year,
    //       Quarter,
    //       VC,
    //       VC_description,
    //       Plant_code,
    //       Part_number,
    //       Part_Description,
    //       Supplier_Code,
    //       Supplier_Name,
    //       AppliedForPLI,
    //       Part_UOM,
    //       GR_No,
    //       GR_date,
    //       SOB,
    //       ImportOrDomestic,
    //       Invoice_No,
    //       Invoice_Date,
    //       Supplementary_Invoice_No,
    //       Supplementary_Invoice_Date,
    //       Price_Per_Part_Regular_A,
    //       Price_Per_Part_Supplementary_B,
    //       Total_Part_Price_C,
    //       SPOC,
    //       CTL,
    //       HSN_CODE,
    //       Billing_Currency,
    //       Supplier_Plant_location_Address,
    //       IRN_Number,
    //       GSTIN,
    //       Part_Qty,
    //       BOE_Number,
    //       BOE_Date,
    //       CIF_Value,
    //       BOE_Part_Qty,
    //       BOE_IEC_Of_TML,
    //       is_deleted,
    //       Remark,
    //       created_at
    //     }
    //     return obj
    //   }
    // )
    const newExcelData = excelData?.map(
      (
        {
          // Project_code,
          // Project_type,
          // Project_type_details,
          // Project_Description,
          // Financial_year,
          // Quarter,
          // VC,
          // VC_description,
          // Plant_code,
          // Po_Plant,
          // Sr_Order,
          // Part_no,
          // Part_description,
          // Supplier_code,
          // Supplier_name,
          // PAN,
          // Run_version,
          // Operation,
          // Bom_item_no,
          // Assembly,
          // Sr_no,
          // Group_material,
          // Group_material_quantity,
          // Reference_material,
          // Followup_material,
          // Cost_run_date,
          // Bom_quantity,
          // Bom_uom,
          // Total_cost,
          // // -- Availing_PLI_Incentive_Yes_No,
          // Availing_PLI_Incentive,
          // Part_uom,
          // Gr_no,
          // Gr_date,
          // Gr_entry_date,
          // Total_gr_quantity,
          // Grn_sob,
          // Bom_sob,
          // Final_quantity,
          // Percentage_check,
          // Po_no,
          // Po_type,
          // Purchase_group,
          // Import_domestic,
          // Contract_no,
          // Invoice_no_r,
          // Invoice_date_r,
          // Invoice_uom_r,
          // Invoive_no_s,
          // Invoice_date_s,
          // Invoice_uom_s,
          // Sap_invoice_quantity,
          // Net_price,
          // Gross_price,
          // Supplementary_price,
          // Total_net_price,
          // Total_gross_price,
          // Price_considered,
          // SPOC,
          // CTL,
          // Buyer_ID,
          // // -- HSN_CODE,
          // HSN_code,
          // // Currency,
          // Supplier_plant_location_address,
          // // IRN_Number,
          // GSTIN,
          // Part_Qty,
          // BOE_number,
          // BOE_date,
          // CIF_value,
          // BOE_part_quantity,
          // BOE_IEC_of_TML,
          // CCR_NO,
          // IGST,
          // ASSESSABLE_VALUE,
          // BCD_RATE,
          // BCD_Percentage,
          // BCD,
          // SWS_RATE,
          // SWS_Percentage,
          // SWS,
          // CIF_COST_PER_UNIT,
          // IGST_Percentage,
          // Calculated_CIF,
          // // -- Ca_Sa,
          // Ca_Sa_self,
          // // -- UDIN,
          // UDINumber,
          // TA_certified_dva_percentage,
          // // -- DVA_certifying_egency,
          // DVA_certifying_egency_name,
          // PLI_claim_effective_date,
          // PLI_claim_effective_date_dd,
          // PLI_claim_effective_date_mm,
          // PLI_claim_effective_date_yyyy,
          // Invoice_selling_price,
          // Supplier_invoice_no,
          // Supplier_net_value,
          // Supplier_discounted_value,
          // Value_matched_Notmatched,
          // Invoice_submitted,
          // Invoice_considered_System_Supplier,
          // Invoice_no_GDC,
          // Supplier_selling_price,
          // Value_of_Direct_Import_by_us,
          // Broad_Description_of_parts_imported_by_us,
          // Value_of_parts_imported_by_our_suppliers,
          // Broad_Description_of_parts_imported_by_our_suppliers,
          // Local_Content,
          // DVAPercentage,
          // Currency_Name,
          // Reference_Date,
          // Foreign_Exchange_Rate,
          // Exchange_rate_date,
          // Irn_no,
          // Send_to_vendor,
          // Send_To_DVA_Collection,
          // Send_To_PLI_Declaration,
          // ProductLine_status,
          // ProductLine_validater_name,
          // ProductLine_validation_date,
          // ProductLine_status_validator2,
          // ProductLine_validation_date_validator2,
          // ProductLine_validater_name_validator2,
          // Overall_ProductLine_status_validator2,

          // PartSelection_status,
          // PartSelection_validater_name,
          // PartSelection_validation_date,
          // BOE_status,
          // BOE_validater_name,
          // BOE_validation_date,
          // TMLBSL_status,
          // Purchase_status,
          // PMT_status,
          // Admin_status,
          // Overall_status,
          // TMLBSL_approve_reject,
          // TMLBSL_approve_reject_remark,
          // Purchase_approve_reject,
          // Purchase_approve_reject_remark,
          // PMT_approve_reject,
          // PMT_approve_reject_remark,
          // Vendor_remark,
          // Vendor_submitted_status,
          // Vendor_submitted_Date,
          // TMLBSL_submitted_status,
          // TMLBSL_submitted_Name,
          // TMLBSL_submitted_Date,
          // PMT_submitted_status,
          // PMT_submitted_Name,
          // PMT_submitted_Date,
          // Purchase_submitted_status,
          // Purchase_submitted_Name,
          // Purchase_submitted_Date,
          // SupplierDVA_Overall_status,

          // Vendor_underPLI_submitted_status,
          // Vendor_underPLI_submitted_Date,
          // TMLBSL_underPLI_submitted_status,
          // TMLBSL_underPLI_submitted_Name,
          // TMLBSL_underPLI_submitted_Date,
          // // -- Purchase_underPLI_submitted_status,
          // // -- Purchase_underPLI_submitted_Name,
          // // -- Purchase_underPLI_submitted_Date,
          // PMT_underPLI_submitted_status,
          // PMT_underPLI_submitted_Name,
          // PMT_underPLI_submitted_Date,
          // UnderPLI_Overall_status,
          // TMLBSL_approve_or_reject,
          // TMLBSL_Remark,
          // Purchase_approve_or_reject,
          // Purchase_Remark,
          // PMT_approve_or_reject,
          // PMT_Remark,
          // Supplier_Email,
          // IEC_of_Tier_I_Supplier,
          // Filename,
          // Generate_certificate,
          // BuyerPartMapping_status,
          // IEC_Code,
          // Contract_Price,
          // Supplier_Credit_Invoices,
          // Supplier_Debit_Invoices,
          // PL_Remark,
          // Supplier_pli,
          // Overall_remark_status,
          // Deleted,
          // Deletion_remark,
          // Addition_remark,
          // System_Remark,
          // Created_date

          /* Newly sorted columns */
          Project_code,
          Project_type,
          Project_type_details,
          Project_Description,
          Financial_year,
          Quarter,
          VC,
          VC_description,
          Plant_code,
          Po_Plant,
          Sr_Order,
          Part_no,
          Part_description,
          Supplier_code,
          Supplier_name,
          PAN,
          GSTIN,
          Supplier_plant_location_address,
          HSN_code,
          Run_version,
          Operation,
          Bom_item_no,
          Assembly,
          Sr_no,
          Group_material,
          Group_material_quantity,
          Reference_material,
          Followup_material,
          Cost_run_date,
          Bom_quantity,
          Bom_uom,
          Total_cost,
          Part_uom,
          Gr_no,
          Gr_date,
          Gr_entry_date,
          Total_gr_quantity,
          Grn_sob,
          Bom_sob,
          Final_quantity,
          Po_no,
          Po_type,
          Purchase_group,
          Import_domestic,
          Contract_no,
          Contract_Price,
          Invoice_no_r,
          Invoice_date_r,
          Invoice_uom_r,
          Invoive_no_s,
          Supplier_Credit_Invoices,
          Supplier_Debit_Invoices,
          Invoice_date_s,
          Invoice_uom_s,
          Sap_invoice_quantity,
          Net_price,
          Gross_price,
          Supplementary_price,
          Total_net_price,
          Total_gross_price,
          Irn_no,
          System_Remark,
          PL_Remark,
          Addition_remark,
          Part_Qty,
          BOE_number,
          BOE_date,
          CIF_value,
          BOE_part_quantity,
          BOE_IEC_of_TML,
          CCR_NO,
          IGST,
          ASSESSABLE_VALUE,
          BCD_RATE,
          BCD_Percentage,
          BCD,
          SWS_RATE,
          SWS_Percentage,
          SWS,
          CIF_COST_PER_UNIT,
          IGST_Percentage,
          Calculated_CIF,
          Supplier_pli,
          // Availing_PLI_Incentive,
          Availing_PLI_Incentive_Yes_No,
          TA_certified_dva_percentage,
          PLI_claim_effective_date_dd,
          PLI_claim_effective_date_mm,
          PLI_claim_effective_date_yyyy,
          Supplier_selling_price,
          Value_of_Direct_Import_by_us,
          Broad_Description_of_parts_imported_by_us,
          Value_of_parts_imported_by_our_suppliers,
          Broad_Description_of_parts_imported_by_our_suppliers,
          Local_Content,
          DVAPercentage,
          Currency_Name,
          Reference_Date,
          Foreign_Exchange_Rate,
          IEC_Code,
          Vendor_remark,
          Filename,
          DVA_certifying_Agency_name,
          Ca_Sa_self,
          UDINumber,
          Supplier_invoice_no,
          Value_matched_Notmatched,
          Invoice_submitted,
          Invoice_considered_System_Supplier,
          SPOC,
          CTL,
          Buyer_ID,
          Supplier_Email,
          Vendor_underPLI_submitted_status,
          Vendor_underPLI_submitted_Date,
          TMLBSL_underPLI_submitted_status,
          TMLBSL_underPLI_submitted_Name,
          TMLBSL_underPLI_submitted_Date,
          PMT_underPLI_submitted_status,
          PMT_underPLI_submitted_Name,
          PMT_underPLI_submitted_Date,
          UnderPLI_Overall_status,
          TMLBSL_approve_or_reject,
          TMLBSL_Remark,
          PMT_approve_or_reject,
          PMT_Remark,
          Vendor_submitted_status,
          Vendor_submitted_Date,
          TMLBSL_submitted_status,
          TMLBSL_submitted_Name,
          TMLBSL_submitted_Date,
          PMT_submitted_status,
          PMT_submitted_Name,
          PMT_submitted_Date,
          Purchase_submitted_status,
          Purchase_submitted_Name,
          Purchase_submitted_Date,
          SupplierDVA_Overall_status,
          TMLBSL_approve_reject,
          TMLBSL_approve_reject_remark,
          Purchase_approve_reject,
          Purchase_approve_reject_remark,
          PMT_approve_reject,
          PMT_approve_reject_remark,
          ProductLine_status,
          ProductLine_validater_name,
          ProductLine_validation_date,
          Created_date
        },
        id
      ) => {
        let obj = {
          // id,
          // Project_code,
          // Project_type,
          // Project_type_details,
          // Project_Description,
          // Financial_year,
          // Quarter,
          // VC,
          // VC_description,
          // Plant_code,
          // Po_Plant,
          // Sr_Order,
          // Part_no,
          // Part_description,
          // Supplier_code,
          // Supplier_name,
          // PAN,
          // Run_version,
          // Operation,
          // Bom_item_no,
          // Assembly,
          // Sr_no,
          // Group_material,
          // Group_material_quantity,
          // Reference_material,
          // Followup_material,
          // Cost_run_date,
          // Bom_quantity,
          // Bom_uom,
          // 'BOM Price': Total_cost,
          // // -- Availing_PLI_Incentive_Yes_No,
          // Availing_PLI_Incentive,
          // Part_uom,
          // Gr_no,
          // Gr_date,
          // Gr_entry_date,
          // Total_gr_quantity,
          // Grn_sob,
          // Bom_sob,
          // Final_quantity,
          // Percentage_check,
          // Po_no,
          // Po_type,
          // Purchase_group,
          // Import_domestic,
          // Contract_no,
          // Invoice_no_r,
          // Invoice_date_r,
          // Invoice_uom_r,
          // Invoive_no_s,
          // Invoice_date_s,
          // Invoice_uom_s,
          // Sap_invoice_quantity,
          // Net_price,
          // Gross_price,
          // Supplementary_price,
          // Total_net_price,
          // Total_gross_price,
          // Price_considered,
          // SPOC,
          // CTL,
          // Buyer_ID,
          // // -- HSN_CODE,
          // HSN_code,
          // // Currency,
          // Supplier_plant_location_address,
          // // IRN_Number,
          // GSTIN,
          // Part_Qty,
          // BOE_number,
          // BOE_date,
          // CIF_value,
          // BOE_part_quantity,
          // BOE_IEC_of_TML,
          // CCR_NO,
          // IGST,
          // ASSESSABLE_VALUE,
          // BCD_RATE,
          // BCD_Percentage,
          // BCD,
          // SWS_RATE,
          // SWS_Percentage,
          // SWS,
          // CIF_COST_PER_UNIT,
          // IGST_Percentage,
          // Calculated_CIF,
          // // -- Ca_Sa,
          // Ca_Sa_self,
          // // -- UDIN,
          // UDINumber,
          // TA_certified_dva_percentage,
          // // -- DVA_certifying_egency,
          // DVA_certifying_egency_name,
          // PLI_claim_effective_date,
          // PLI_claim_effective_date_dd,
          // PLI_claim_effective_date_mm,
          // PLI_claim_effective_date_yyyy,
          // Invoice_selling_price,
          // Supplier_invoice_no,
          // Supplier_net_value,
          // Supplier_discounted_value,
          // Value_matched_Notmatched,
          // Invoice_submitted,
          // Invoice_considered_System_Supplier,
          // Invoice_no_GDC,
          // Supplier_selling_price,
          // Value_of_Direct_Import_by_us,
          // Broad_Description_of_parts_imported_by_us,
          // Value_of_parts_imported_by_our_suppliers,
          // Broad_Description_of_parts_imported_by_our_suppliers,
          // Local_Content,
          // DVAPercentage,
          // Currency_Name,
          // Reference_Date,
          // Foreign_Exchange_Rate,
          // Exchange_rate_date,
          // Irn_no,
          // Send_to_vendor,
          // Send_To_DVA_Collection,
          // Send_To_PLI_Declaration,
          // ProductLine_status,
          // ProductLine_validater_name,
          // ProductLine_validation_date,
          // ProductLine_status_validator2,
          // ProductLine_validation_date_validator2,
          // ProductLine_validater_name_validator2,
          // Overall_ProductLine_status_validator2,

          // PartSelection_status,
          // PartSelection_validater_name,
          // PartSelection_validation_date,
          // BOE_status,
          // BOE_validater_name,
          // BOE_validation_date,
          // TMLBSL_status,
          // Purchase_status,
          // PMT_status,
          // Admin_status,
          // Overall_status,
          // TMLBSL_approve_reject,
          // TMLBSL_approve_reject_remark,
          // Purchase_approve_reject,
          // Purchase_approve_reject_remark,
          // PMT_approve_reject,
          // PMT_approve_reject_remark,
          // Vendor_remark,
          // Vendor_submitted_status,
          // Vendor_submitted_Date,
          // TMLBSL_submitted_status,
          // TMLBSL_submitted_Name,
          // TMLBSL_submitted_Date,
          // PMT_submitted_status,
          // PMT_submitted_Name,
          // PMT_submitted_Date,
          // Purchase_submitted_status,
          // Purchase_submitted_Name,
          // Purchase_submitted_Date,
          // SupplierDVA_Overall_status,

          // Vendor_underPLI_submitted_status,
          // Vendor_underPLI_submitted_Date,
          // TMLBSL_underPLI_submitted_status,
          // TMLBSL_underPLI_submitted_Name,
          // TMLBSL_underPLI_submitted_Date,
          // // -- Purchase_underPLI_submitted_status,
          // // -- Purchase_underPLI_submitted_Name,
          // // -- Purchase_underPLI_submitted_Date,
          // PMT_underPLI_submitted_status,
          // PMT_underPLI_submitted_Name,
          // PMT_underPLI_submitted_Date,
          // UnderPLI_Overall_status,
          // TMLBSL_approve_or_reject,
          // TMLBSL_Remark,
          // Purchase_approve_or_reject,
          // Purchase_Remark,
          // PMT_approve_or_reject,
          // PMT_Remark,
          // Supplier_Email,
          // IEC_of_Tier_I_Supplier,
          // Filename,
          // Generate_certificate,
          // BuyerPartMapping_status,
          // IEC_Code,
          // Contract_Price,
          // Supplier_Credit_Invoices,
          // Supplier_Debit_Invoices,
          // PL_Remark,
          // Supplier_pli,
          // Overall_remark_status,
          // Deleted,
          // Deletion_remark,
          // Addition_remark,
          // System_Remark,
          // Created_date

          /* Newly sorted column names */
          id,
          Project_code,
          Project_type,
          Project_type_details,
          Project_Description,
          Financial_year,
          Quarter,
          VC,
          VC_description,
          Plant_code,
          Po_Plant,
          Sr_Order,
          Part_no,
          Part_description,
          Supplier_code,
          Supplier_name,
          PAN,
          GSTIN,
          Supplier_plant_location_address,
          HSN_code,
          Run_version,
          Operation,
          Bom_item_no,
          Assembly,
          Sr_no,
          Group_material,
          Group_material_quantity,
          Reference_material,
          Followup_material,
          Cost_run_date,
          Bom_quantity,
          Bom_uom,
          'BOM Price': Total_cost,
          Part_uom,
          Gr_no,
          Gr_date,
          Gr_entry_date,
          Total_gr_quantity,
          Grn_sob,
          Bom_sob,
          Final_quantity,
          Po_no,
          Po_type,
          Purchase_group,
          Import_domestic,
          Contract_no,
          Contract_Price,
          Invoice_no_r,
          Invoice_date_r,
          Invoice_uom_r,
          Invoive_no_s,
          Supplier_Credit_Invoices,
          Supplier_Debit_Invoices,
          Invoice_date_s,
          Invoice_uom_s,
          Sap_invoice_quantity,
          Net_price,
          Gross_price,
          Supplementary_price,
          Total_net_price,
          Total_gross_price,
          Irn_no,
          System_Remark,
          PL_Remark,
          Addition_remark,
          Part_Qty,
          BOE_number,
          BOE_date,
          CIF_value,
          BOE_part_quantity,
          BOE_IEC_of_TML,
          CCR_NO,
          IGST,
          ASSESSABLE_VALUE,
          BCD_RATE,
          BCD_Percentage,
          BCD,
          SWS_RATE,
          SWS_Percentage,
          SWS,
          CIF_COST_PER_UNIT,
          IGST_Percentage,
          Calculated_CIF,
          Supplier_pli,
          // Availing_PLI_Incentive,
          Availing_PLI_Incentive_Yes_No,
          TA_certified_dva_percentage,
          PLI_claim_effective_date_dd,
          PLI_claim_effective_date_mm,
          PLI_claim_effective_date_yyyy,
          Supplier_selling_price,
          Value_of_Direct_Import_by_us,
          Broad_Description_of_parts_imported_by_us,
          Value_of_parts_imported_by_our_suppliers,
          Broad_Description_of_parts_imported_by_our_suppliers,
          Local_Content,
          DVAPercentage,
          Currency_Name,
          Reference_Date,
          Foreign_Exchange_Rate,
          IEC_Code,
          Vendor_remark,
          Filename,
          DVA_certifying_Agency_name: '',
          Ca_Sa_self,
          UDINumber,
          Supplier_invoice_no,
          Value_matched_Notmatched,
          Invoice_submitted,
          Invoice_considered_System_Supplier,
          SPOC,
          CTL,
          Buyer_ID,
          Supplier_Email,
          Vendor_underPLI_submitted_status,
          Vendor_underPLI_submitted_Date,
          TMLBSL_underPLI_submitted_status,
          TMLBSL_underPLI_submitted_Name,
          TMLBSL_underPLI_submitted_Date,
          PMT_underPLI_submitted_status,
          PMT_underPLI_submitted_Name,
          PMT_underPLI_submitted_Date,
          UnderPLI_Overall_status,
          TMLBSL_approve_or_reject,
          TMLBSL_Remark,
          PMT_approve_or_reject,
          PMT_Remark,
          Vendor_submitted_status,
          Vendor_submitted_Date,
          TMLBSL_submitted_status,
          TMLBSL_submitted_Name,
          TMLBSL_submitted_Date,
          PMT_submitted_status,
          PMT_submitted_Name,
          PMT_submitted_Date,
          Purchase_submitted_status,
          Purchase_submitted_Name,
          Purchase_submitted_Date,
          SupplierDVA_Overall_status,
          TMLBSL_approve_reject,
          TMLBSL_approve_reject_remark,
          Purchase_approve_reject,
          Purchase_approve_reject_remark,
          PMT_approve_reject,
          PMT_approve_reject_remark,
          ProductLine_status,
          ProductLine_validater_name,
          ProductLine_validation_date,
          Created_date
        }
        return obj
      }
    )

    var fileTypel =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    var fileExt = '.xlsx'
    const ws = XLSX.utils.json_to_sheet(newExcelData)
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
    const excelBuffer = XLSX.write(wb, { booktype: 'xlsx', type: 'array' })
    const data = new Blob([excelBuffer], { type: fileTypel })
    FileSaver.saveAs(data, `BOM_Master_Data${fileExt}`)
  }

  // const baseDefaultColumnsGDC = [
  //   {
  //     width: 130,
  //     title: "Project Code",
  //     field: "Project_code",
  //     enableSearch: true,
  //     enableFilter: true,
  //   },
  // ];

  // const baseDefaultColumns = [
  //   {
  //     width: 130,
  //     title: "Project Code",
  //     field: "Project_code",
  //     enableSearch: true,
  //     enableFilter: true,
  //   },
  //   {
  //     width: 130,
  //     title: "Project Type",
  //     field: "Project_type",
  //     enableSearch: true,
  //     enableFilter: true,
  //   },
  //   {
  //     width: 130,
  //     title: "Financial Year",
  //     field: "Financial_year",
  //     enableSearch: true,
  //     enableFilter: true,
  //   },
  //   {
  //     width: 130,
  //     title: "Quarter",
  //     field: "Quarter",
  //     enableSearch: true,
  //     enableFilter: true,
  //   },
  //   {
  //     width: 130,
  //     title: "VC",
  //     field: "VC",
  //     enableSearch: true,
  //     enableFilter: true,
  //   },
  //   {
  //     width: 130,
  //     title: "VC Description",
  //     field: "VC_description",
  //     enableSearch: true,
  //     enableFilter: true,
  //   },
  //   {
  //     width: 130,
  //     title: "Plant Code",
  //     field: "Plant_code",
  //     enableSearch: true,
  //     enableFilter: true,
  //   },
  //   {
  //     width: 150,
  //     title: "Part Number",
  //     field: "Part_no",
  //     enableSearch: true,
  //     enableFilter: true,
  //   },
  //   {
  //     width: 180,
  //     title: "Part Description",
  //     field: "Part_description",
  //     enableSearch: true,
  //     enableFilter: true,
  //   },
  //   {
  //     width: 130,
  //     title: "Supplier Code",
  //     field: "Supplier_code",
  //     enableSearch: true,
  //     enableFilter: true,
  //   },
  //   {
  //     width: 180,
  //     title: "Supplier Name",
  //     field: "Supplier_name",
  //     enableSearch: true,
  //     enableFilter: true,
  //   },
  //   {
  //     width: 100,
  //     // title: "Applied For PLI(Y/N)",
  //     title: "Availing PLI Incentive (Y/N)",
  //     field: "Availing_PLI_Incentive_Yes_No",
  //     enableSearch: true,
  //     enableFilter: true,
  //   },
  //   {
  //     width: 100,
  //     title: "Part UOM",
  //     field: "Part_uom",
  //     enableSearch: true,
  //     enableFilter: true,
  //   },
  //   {
  //     width: 130,
  //     title: "GR No",
  //     field: "Gr_no",
  //     enableSearch: true,
  //     enableFilter: true,
  //   },
  //   {
  //     width: 130,
  //     title: "GR Date",
  //     field: "Gr_date",
  //     enableSearch: true,
  //     enableFilter: true,
  //   },
  //   {
  //     width: 130,
  //     // title: "SOB",
  //     title: "GR SOB",
  //     field: "Grn_sob",
  //     enableSearch: true,
  //     enableFilter: true,
  //   },
  //   {
  //     width: 130,
  //     title: "Import/Domestic",
  //     field: "Import_domestic",
  //     enableSearch: true,
  //     enableFilter: true,
  //   },
  //   {
  //     width: 130,
  //     title: "Contract No.",
  //     field: "Contract_no",
  //     enableSearch: true,
  //     enableFilter: true,
  //   },
  //   {
  //     width: 150,
  //     title: "Invoice No(R)",
  //     field: "Invoice_no_r",
  //     enableSearch: true,
  //     enableFilter: true,
  //   },
  //   {
  //     width: 150,
  //     title: "Invoice Date(R)",
  //     field: "Invoice_date_r",
  //     enableSearch: true,
  //     enableFilter: true,
  //   },
  //   {
  //     width: 150,
  //     title: "Invoice UOM(R)",
  //     field: "Invoice_uom_r",
  //     enableSearch: true,
  //     enableFilter: true,
  //   },
  //   {
  //     width: 150,
  //     title: "Supplementary Invoice No",
  //     field: "Invoive_no_s",
  //     enableSearch: true,
  //     enableFilter: true,
  //   },
  //   {
  //     width: 150,
  //     title: "Supplementary Invoice Date",
  //     field: "Invoice_date_s",
  //     enableSearch: true,
  //     enableFilter: true,
  //   },
  //   {
  //     width: 150,
  //     title: "Invoice UOM(S)",
  //     field: "Invoice_uom_s",
  //     enableSearch: true,
  //     enableFilter: true,
  //   },
  //   {
  //     width: 150,
  //     title: "SAP Invoice Quantity",
  //     field: "Sap_invoice_quantity",
  //     enableSearch: true,
  //     enableFilter: true,
  //   },
  //   {
  //     width: 150,
  //     title: "Net Price",
  //     field: "Net_price",
  //     enableSearch: true,
  //     enableFilter: true,
  //   },
  //   {
  //     width: 150,
  //     title: "Gross Price",
  //     field: "Gross_price",
  //     enableSearch: true,
  //     enableFilter: true,
  //   },
  //   {
  //     width: 150,
  //     title: "Supplementary Price",
  //     field: "Supplementary_price",
  //     enableSearch: true,
  //     enableFilter: true,
  //   },
  //   {
  //     width: 150,
  //     title: "Total Net Price",
  //     field: "Total_net_price",
  //     enableSearch: true,
  //     enableFilter: true,
  //   },
  //   {
  //     width: 150,
  //     title: "Total Gross Price",
  //     field: "Total_gross_price",
  //     enableSearch: true,
  //     enableFilter: true,
  //   },
  //   {
  //     width: 100,
  //     title: "SPOC",
  //     field: "SPOC",
  //     enableSearch: true,
  //     enableFilter: true,
  //   },
  //   {
  //     width: 100,
  //     title: "CTL",
  //     field: "CTL",
  //     enableSearch: true,
  //     enableFilter: true,
  //   },
  //   {
  //     width: 100,
  //     title: "HSN Code",
  //     field: "HSN_CODE",
  //     enableSearch: true,
  //     enableFilter: true,
  //   },
  //   {
  //     width: 100,
  //     title: "Billing Currency",
  //     field: "Currency",
  //     enableSearch: true,
  //     enableFilter: true,
  //   },
  //   {
  //     width: 100,
  //     title: "Supplier Plant Location Address",
  //     field: "Supplier_plant_location_address",
  //     enableSearch: true,
  //     enableFilter: true,
  //   },
  //   {
  //     width: 100,
  //     title: "IRN Number",
  //     field: "IRN_Number",
  //     enableSearch: true,
  //     enableFilter: true,
  //   },
  //   {
  //     width: 100,
  //     title: "GSTIN",
  //     field: "GSTIN",
  //     enableSearch: true,
  //     enableFilter: true,
  //   },
  //   {
  //     width: 100,
  //     title: "Part Quantity",
  //     field: "Part_Qty",
  //     enableSearch: true,
  //     enableFilter: true,
  //   },
  //   {
  //     width: 100,
  //     title: "BOE Number",
  //     field: "BOE_Number",
  //     enableSearch: true,
  //     enableFilter: true,
  //   },
  //   {
  //     width: 100,
  //     title: "BOE Date",
  //     field: "BOE_Date",
  //     enableSearch: true,
  //     enableFilter: true,
  //   },
  //   {
  //     width: 100,
  //     title: "CIF Value",
  //     field: "CIF_Value",
  //     enableSearch: true,
  //     enableFilter: true,
  //   },
  //   {
  //     width: 100,
  //     title: "BOE Part Quantity",
  //     field: "BOE_Part_Qty",
  //     enableSearch: true,
  //     enableFilter: true,
  //   },
  //   {
  //     width: 100,
  //     title: "BOE IEC Of TML",
  //     field: "BOE_IEC_Of_TML",
  //     enableSearch: true,
  //     enableFilter: true,
  //   },
  //   {
  //     width: 100,
  //     title: "CA/SA/Self",
  //     field: "Ca_Sa",
  //     enableSearch: true,
  //     enableFilter: true,
  //   },
  //   {
  //     width: 100,
  //     title: "UDIN",
  //     field: "UDIN",
  //     enableSearch: true,
  //     enableFilter: true,
  //   },
  //   {
  //     width: 100,
  //     title: "TA Certified DVA Percentage",
  //     field: "TA_certified_DVA_percentage",
  //     enableSearch: true,
  //     enableFilter: true,
  //   },
  //   {
  //     width: 100,
  //     title: "PLI Claim Effective Date",
  //     field: "PLI_claim_effective_date",
  //     enableSearch: true,
  //     enableFilter: true,
  //   },
  //   {
  //     width: 100,
  //     title: "Supplier Selling Price",
  //     field: "Supplier_selling_price",
  //     enableSearch: true,
  //     enableFilter: true,
  //   },
  //   {
  //     width: 100,
  //     title: "Value Of Direct Import By Us",
  //     field: "Value_of_Direct_Import_by_us",
  //     enableSearch: true,
  //     enableFilter: true,
  //   },
  //   {
  //     width: 100,
  //     title: "Broad Description of parts imported by us",
  //     field: "Broad_Description_of_parts_imported_by_us",
  //     enableSearch: true,
  //     enableFilter: true,
  //   },
  //   {
  //     width: 100,
  //     title: "Value of parts imported by our suppliers",
  //     field: "Value_of_parts_imported_by_our_suppliers",
  //     enableSearch: true,
  //     enableFilter: true,
  //   },
  //   {
  //     width: 100,
  //     title: "Broad Description of parts imported by our suppliers",
  //     field: "Broad_Description_of_parts_imported_by_our_suppliers",
  //     enableSearch: true,
  //     enableFilter: true,
  //   },
  //   {
  //     width: 100,
  //     title: "Local Content",
  //     field: "Local_Content",
  //     enableSearch: true,
  //     enableFilter: true,
  //   },
  //   {
  //     width: 100,
  //     title: "DVA Percentage",
  //     field: "DVAPercentage",
  //     enableSearch: true,
  //     enableFilter: true,
  //   },
  //   // {
  //   //   width: 100,
  //   //   title: "Currency Name",
  //   //   field: "Currency_Name",
  //   //   enableSearch: true,
  //   //   enableFilter: true,
  //   // },
  //   {
  //     width: 100,
  //     title: "Reference Date",
  //     field: "Reference_Date",
  //     enableSearch: true,
  //     enableFilter: true,
  //   },
  //   {
  //     width: 100,
  //     title: "Exchange Rate",
  //     field: 'Foreign_Exchange_Rate',
  //     enableSearch: true,
  //     enableFilter: true,
  //   },
  //   // {
  //   //   width: 100,
  //   //   title: "Financial_year",
  //   //   field: "Financial_year",
  //   //   enableSearch: true,
  //   //   enableFilter: true,
  //   // },
  //   // {
  //   //   width: 100,
  //   //   title: "Send To Vendor",
  //   //   field: "Send_to_vendor",
  //   //   enableSearch: true,
  //   //   enableFilter: true,
  //   // },
  //   {
  //     width: 100,
  //     title: "Is deleted",
  //     field: "Deleted",
  //     enableSearch: true,
  //     enableFilter: true,
  //   },
  //   {
  //     width: 100,
  //     title: "Remark",
  //     field: "Remark",
  //     enableSearch: true,
  //     enableFilter: true,
  //   },
  //   {
  //     width: 100,
  //     title: "Deletion Remark",
  //     field: "Deletion_remark",
  //     enableSearch: true,
  //     enableFilter: true,
  //   },
  //   {
  //     width: 100,
  //     title: "Addition Remark",
  //     field: "Addition_remark",
  //     enableSearch: true,
  //     enableFilter: true,
  //   },
  //   {
  //     width: 100,
  //     title: "created At",
  //     // field: "created_at",
  //     field: "Created_date",
  //     enableSearch: true,
  //     enableFilter: true,
  //   },
  // ]

  const baseDefaultColumns = [
    {
      width: 130,
      title: 'Project Code',
      field: 'Project_code',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 130,
      title: 'Project Type',
      field: 'Project_type',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 130,
      title: 'Project Type Details',
      field: 'Project_type_details',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 130,
      title: 'Project Type Description',
      field: 'Project_Description',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 130,
      title: 'Financial Year',
      field: 'Financial_year',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 130,
      title: 'Quarter',
      field: 'Quarter',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 130,
      title: 'VC',
      field: 'VC',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 130,
      title: 'VC Description',
      field: 'VC_description',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 130,
      title: 'Plant Code',
      field: 'Plant_code',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 130,
      title: 'PO Plant',
      field: 'Po_Plant',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 130,
      title: 'Sr. Order',
      field: 'Sr_Order',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 150,
      title: 'Part Number',
      field: 'Part_no',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 180,
      title: 'Part Description',
      field: 'Part_description',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 130,
      title: 'Supplier Code',
      field: 'Supplier_code',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 180,
      title: 'Supplier Name',
      field: 'Supplier_name',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 180,
      title: 'PAN',
      field: 'PAN',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 180,
      title: 'Run Version',
      field: 'Run_version',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 180,
      title: 'Operation',
      field: 'Operation',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 180,
      title: 'Bom Item No.',
      field: 'Bom_item_no',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 180,
      title: 'Assembly',
      field: 'Assembly',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 180,
      title: 'Sr No.',
      field: 'Sr_no',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 180,
      title: 'Group Material',
      field: 'Group_material',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 180,
      title: 'Group Material Quantity',
      field: 'Group_material_quantity',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 180,
      title: 'Reference Material',
      field: 'Reference_material',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 180,
      title: 'Followup Material',
      field: 'Followup_material',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 180,
      title: 'Cost Run Date',
      field: 'Cost_run_date',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 180,
      title: 'Bom Quantity',
      field: 'Bom_quantity',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 180,
      title: 'Bom UOM',
      field: 'Bom_uom',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 180,
      title: 'BOM Price',
      field: 'Total_cost',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      // title: "Applied For PLI(Y/N)",
      title: 'Availing PLI Incentive (Y/N)',
      field: "Availing_PLI_Incentive_Yes_No",
      // field: 'Availing_PLI_Incentive',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'Part UOM',
      field: 'Part_uom',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 130,
      title: 'GR No',
      field: 'Gr_no',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 130,
      title: 'GR Date',
      field: 'Gr_date',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 130,
      title: 'GR Entry Date',
      field: 'Gr_entry_date',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 130,
      title: 'Total GR Quantity',
      field: 'Total_gr_quantity',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 130,
      // title: "SOB",
      title: 'GR SOB',
      field: 'Grn_sob',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 130,
      title: 'BOM SOB',
      field: 'Bom_sob',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 130,
      title: 'Final Quantity',
      field: 'Final_quantity',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 130,
      title: 'Percentage Check',
      field: 'Percentage_check',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 130,
      title: 'Po No.',
      field: 'Po_no',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 130,
      title: 'Po Type',
      field: 'Po_type',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 130,
      title: 'Purchase Group',
      field: 'Purchase_group',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 130,
      title: 'Import/Domestic',
      field: 'Import_domestic',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 130,
      title: 'Contract No.',
      field: 'Contract_no',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 150,
      title: 'Invoice No(R)',
      field: 'Invoice_no_r',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 150,
      title: 'Invoice Date(R)',
      field: 'Invoice_date_r',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 150,
      title: 'Invoice UOM(R)',
      field: 'Invoice_uom_r',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 150,
      title: 'Supplementary Invoice No',
      field: 'Invoive_no_s',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 150,
      title: 'Supplementary Invoice Date',
      field: 'Invoice_date_s',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 150,
      title: 'Invoice UOM(S)',
      field: 'Invoice_uom_s',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 150,
      title: 'SAP Invoice Quantity',
      field: 'Sap_invoice_quantity',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 150,
      title: 'Net Price',
      field: 'Net_price',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 150,
      title: 'Gross Price',
      field: 'Gross_price',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 150,
      title: 'Supplementary Price',
      field: 'Supplementary_price',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 150,
      title: 'Total Net Price',
      field: 'Total_net_price',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 150,
      title: 'Total Gross Price',
      field: 'Total_gross_price',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 150,
      title: 'Price Considered',
      field: 'Price_considered',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'SPOC',
      field: 'SPOC',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'CTL',
      field: 'CTL',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'Buyer ID',
      field: 'Buyer_ID',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'HSN Code',
      // field: "HSN_CODE",
      field: 'HSN_code',
      enableSearch: true,
      enableFilter: true
    },
    // {
    //   width: 100,
    //   title: "Billing Currency",
    //   field: "Currency",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    {
      width: 100,
      title: 'Supplier Plant Location Address',
      field: 'Supplier_plant_location_address',
      enableSearch: true,
      enableFilter: true
    },
    // {
    //   width: 100,
    //   title: "IRN Number",
    //   field: "IRN_Number",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    {
      width: 100,
      title: 'GSTIN',
      field: 'GSTIN',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'Part Quantity',
      field: 'Part_Qty',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'BOE Number',
      // field: "BOE_Number",
      field: 'BOE_number',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'BOE Date',
      // field: "BOE_Date",
      field: 'BOE_date',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'CIF Value',
      // field: "CIF_Value",
      field: 'CIF_value',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'BOE Part Quantity',
      // field: "BOE_Part_Qty",
      field: 'BOE_part_quantity',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'BOE IEC Of TML',
      // field: "BOE_IEC_Of_TML",
      field: 'BOE_IEC_of_TML',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 120,
      title: 'CCR Number',
      field: 'CCR_NO',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'IGST',
      field: 'IGST',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 120,
      title: 'IGST Percentage',
      field: 'IGST_Percentage',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 180,
      title: 'Assessable Value',
      field: 'ASSESSABLE_VALUE',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'BCD Rate',
      field: 'BCD_RATE',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 120,
      title: 'BCD Percentage',
      field: 'BCD_Percentage',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'BCD',
      field: 'BCD',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'SWS Rate',
      field: 'SWS_RATE',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 120,
      title: 'SWS Percentage',
      field: 'SWS_Percentage',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'SWS',
      field: 'SWS',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 120,
      title: 'CIF Cost/Unit',
      field: 'CIF_COST_PER_UNIT',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 180,
      title: 'Calculated CIF',
      field: 'Calculated_CIF',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'CA/SA/Self',
      // field: "Ca_Sa",
      field: 'Ca_Sa_self',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'UDIN',
      // field: "UDIN",
      field: 'UDINumber',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'TA Certified DVA Percentage',
      // field: "TA_certified_DVA_percentage",
      field: 'TA_certified_dva_percentage',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'DVA Certifying Egency',
      // field: "DVA_certifying_egency",
      field: 'DVA_certifying_egency_name',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'PLI Claim Effective Date',
      field: 'PLI_claim_effective_date',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'PLI Claim Effective Date DD',
      field: 'PLI_claim_effective_date_dd',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'PLI Claim Effective Date MM',
      field: 'PLI_claim_effective_date_mm',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'PLI Claim Effective Date YYYY',
      field: 'PLI_claim_effective_date_yyyy',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'Invoice Selling Price',
      field: 'Invoice_selling_price',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'Supplier Invoice No.',
      field: 'Supplier_invoice_no',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'Supplier Net Value',
      field: 'Supplier_net_value',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'Supplier Discounted Value',
      field: 'Supplier_discounted_value',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'Value Matched Notmatched',
      field: 'Value_matched_Notmatched',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'Invoice Submitted',
      field: 'Invoice_submitted',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'Invoice Considered System Supplier',
      field: 'Invoice_considered_System_Supplier',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'Invoice No GDC',
      field: 'Invoice_no_GDC',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'Supplier Selling Price',
      field: 'Supplier_selling_price',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'Value Of Direct Import By Us',
      field: 'Value_of_Direct_Import_by_us',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'Broad Description of parts imported by us',
      field: 'Broad_Description_of_parts_imported_by_us',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'Value of parts imported by our suppliers',
      field: 'Value_of_parts_imported_by_our_suppliers',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'Broad Description of parts imported by our suppliers',
      field: 'Broad_Description_of_parts_imported_by_our_suppliers',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'Local Content',
      field: 'Local_Content',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'DVA Percentage',
      field: 'DVAPercentage',
      enableSearch: true,
      enableFilter: true
    },

    /* Added 14-10-2024 */
    {
      width: 100,
      title: 'Currency Name',
      field: 'Currency_Name',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'Reference Date',
      field: 'Reference_Date',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'Exchange Rate',
      // field: 'Foreign_Exchange_Rate',
      field: 'Foreign_Exchange_Rate',
      // field: 'Exchange_rate',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'Exchange Rate Date',
      field: 'Exchange_rate_date',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'IRN No.',
      field: 'Irn_no',
      enableSearch: true,
      enableFilter: true
    },
    // {
    //   width: 100,
    //   title: "Financial_year",
    //   field: "Financial_year",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    {
      width: 100,
      title: 'Send To Vendor',
      field: 'Send_to_vendor',
      enableSearch: true,
      enableFilter: true
    },

    {
      width: 100,
      title: 'Send To DVA Collection',
      field: 'Send_To_DVA_Collection',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'Send To PLI Declaration',
      field: 'Send_To_PLI_Declaration',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'ProductLine Status',
      field: 'ProductLine_status',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'ProductLine Validator Name',
      field: 'ProductLine_validater_name',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'ProductLine Validation Date',
      field: 'ProductLine_validation_date',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'ProductLine-2 Validation Status',
      field: 'ProductLine_status_validator2',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'ProductLine-2 Validation Date',
      field: 'ProductLine_validation_date_validator2',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'ProductLine-2 Validator Name',
      field: 'ProductLine_validater_name_validator2',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'Overall ProductLine-2 Status Of Validator',
      field: 'Overall_ProductLine_status_validator2',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'PartSelection Status',
      field: 'PartSelection_status',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'PartSelection Validator Name',
      field: 'PartSelection_validater_name',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'PartSelection Validation Date',
      field: 'PartSelection_validation_date',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'BOE Status',
      field: 'BOE_status',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'BOE Validater Name',
      field: 'BOE_validater_name',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'BOE Validation Date',
      field: 'BOE_validation_date',
      enableSearch: true,
      enableFilter: true
    },

    {
      width: 100,
      title: 'TMLBSL Status',
      field: 'TMLBSL_status',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'Purchase Status',
      field: 'Purchase_status',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'PMT Status',
      field: 'PMT_status',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'Admin Status',
      field: 'Admin_status',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'Overall Status',
      field: 'Overall_status',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'TMLBSL Approve Reject',
      field: 'TMLBSL_approve_reject',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'TMLBSL Approve Reject Remark',
      field: 'TMLBSL_approve_reject_remark',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'Purchase Approve Reject',
      field: 'Purchase_approve_reject',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'Purchase Approve Reject Remark',
      field: 'Purchase_approve_reject_remark',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'PMT Approve Reject',
      field: 'PMT_approve_reject',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'PMT Approve Reject Remark',
      field: 'PMT_approve_reject_remark',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'Vendor Remark',
      field: 'Vendor_remark',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'Vendor Submitted Status',
      field: 'Vendor_submitted_status',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'Vendor Submitted Date',
      field: 'Vendor_submitted_Date',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'TMLBSL Submitted Status',
      field: 'TMLBSL_submitted_status',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'TMLBSL Submitted Name',
      field: 'TMLBSL_submitted_Name',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'TMLBSL Submitted Date',
      field: 'TMLBSL_submitted_Date',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'PMT Submitted Status',
      field: 'PMT_submitted_status',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'PMT Submitted Name',
      field: 'PMT_submitted_Name',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'PMT Submitted Date',
      field: 'PMT_submitted_Date',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'Purchase Submitted Status',
      field: 'Purchase_submitted_status',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'Purchase Submitted Name',
      field: 'Purchase_submitted_Name',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'Purchase Submitted Date',
      field: 'Purchase_submitted_Date',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'SupplierDVA Overall Status',
      field: 'SupplierDVA_Overall_status',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'Vendor Under PLI Submitted Status',
      field: 'Vendor_underPLI_submitted_status',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'Vendor Under PLI Submitted Date',
      field: 'Vendor_underPLI_submitted_Date',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'TMLBSL Under PLI Submitted Status',
      field: 'TMLBSL_underPLI_submitted_status',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'TMLBSL Under PLI Submitted Name',
      field: 'TMLBSL_underPLI_submitted_Name',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'TMLBSL Under PLI Submitted Date',
      field: 'TMLBSL_underPLI_submitted_Date',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'Purchase Under PLI Submitted Status',
      field: 'Purchase_underPLI_submitted_status',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'Purchase Under PLI Submitted Name',
      field: 'Purchase_underPLI_submitted_Name',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'Purchase Under PLI Submitted Date',
      field: 'Purchase_underPLI_submitted_Date',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'PMT Under PLI Submitted Status',
      field: 'PMT_underPLI_submitted_status',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'PMT Under PLI Submitted Name',
      field: 'PMT_underPLI_submitted_Name',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'PMT UnderPLI Submitted Date',
      field: 'PMT_underPLI_submitted_Date',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'Under PLI Overall Status',
      field: 'UnderPLI_Overall_status',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'TMLBSL Approve Or Reject',
      field: 'TMLBSL_approve_or_reject',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'TMLBSL Remark',
      field: 'TMLBSL_Remark',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'Purchase Approve Or Reject',
      field: 'Purchase_approve_or_reject',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'Purchase Remark',
      field: 'Purchase_Remark',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'PMT Approve Or Reject',
      field: 'PMT_approve_or_reject',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'PMT Remark',
      field: 'PMT_Remark',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'Supplier Email',
      field: 'Supplier_Email',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'IEC of Tier I Supplier',
      field: 'IEC_of_Tier_I_Supplier',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'Filename',
      field: 'Filename',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'Generate Certificate',
      field: 'Generate_certificate',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'BuyerPartMapping Status',
      field: 'BuyerPartMapping_status',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'IEC Code',
      field: 'IEC_Code',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'Contract Price',
      field: 'Contract_Price',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'Supplier Credit Invoices',
      field: 'Supplier_Credit_Invoices',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'Supplier Debit Invoices',
      field: 'Supplier_Debit_Invoices',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'Supplier PLI',
      field: 'Supplier_pli',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'Overall Remark Status',
      field: 'Overall_remark_status',
      enableSearch: true,
      enableFilter: true
    },

    {
      width: 100,
      title: 'PL Remark',
      field: 'PL_Remark',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'Deleted',
      field: 'Deleted',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'BOM Validation-2 Remark',
      field: 'BOM DVA Master Data',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'Addition Remark',
      field: 'Addition_remark',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'System Remark',
      field: 'System_Remark',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'Created At',
      // field: "created_at",
      field: 'Created_date',
      enableSearch: true,
      enableFilter: true
    }
  ]

  const BOMvalidationAPI = () => {
    // BOMvalidationAPIView
    console.log('inside submit api: ', Bc?.value)
    const apiBody = {
      Project_code: selectedProjectCode?.value,
      VC: Bc?.value,
      email_to: 'dp928042.ttl@tatamotors.com'
    }
    console.log('Submit body: ', apiBody)
    var body = JSON.stringify(apiBody)
    let headerss = new Headers()
    headerss.append('Content-Type', 'application/json')
    // fetch(`${baseUrl}BOMvalidationAPIView/`, {
    fetch(`${baseUrl}UpdateStatus/`, {
      method: 'POST',
      headers: headerss,
      body
    })
      .then(response => response.json())
      .then(responseJson => {
        console.log('responseJson', responseJson)
        showPopup({
          type: MESSAGE_TYPE.SUCCESS,
          contextText: MESSAGE_TYPE.SUCCESS,
          info: 'BOm Validation Successful!'
        })
        history.push('/')
      })
      .catch(error => {
        console.log('error in submit: ', error)
      })
  }

  const goNextForm = () => {
    // var url;
    // var urlMethod;
    // let { userRole, userID } = auth;
    // if (userRole.includes("Purchase") || userRole.includes("Purchase-CV")) {
    //   url = "SupplierDataaddition";
    //   urlMethod = "POST";
    // }
    // const apiFormatedData = {
    //   VC: Bc?.value,
    // };
    // var apiFormatedDataJson = JSON.stringify(apiFormatedData);
    // let headerss = new Headers();
    // headerss.append("Content-Type", "application/json");
    // fetch(`${baseUrl}${url}/`, {
    //   method: urlMethod,
    //   headers: headerss,
    //   body: apiFormatedDataJson,
    // })
    //   .then((response) => response.json())
    //   .then((responseJson) => {
    //     showPopup({
    //       type: MESSAGE_TYPE.SUCCESS,
    //       contextText: MESSAGE_TYPE.SUCCESS,
    //       info: "Data is Succesfully Saved",
    //     });
    //     history.push("/");
    //   })
    //   .catch((error) => {});
  }

  // const redirectToCreateProjectPage = () => history.push("/");

  // const data = [
  //   { Column1: '12345', Column2: 'kzjjsabcjbscka124567' },
  //   { Column1: '67890', Column2: 'exampledata' },
  // ];

  // const exportExcelWithTemplate = async () => {
  //   try {
  //     // Step 1: Fetch the template file
  //     const response = await fetch('/Template.xlsx')
  //     console.log('response 1: ', response);

  //     const arrayBuffer = await response.arrayBuffer()
  //     console.log('response 1: arrayBuffer 1: ', arrayBuffer)

  //     // Step 2: Read the array buffer as an Excel workbook
  //     const workbook = XLSX.read(arrayBuffer, { type: 'array' })
  //     console.log('response 1: workbook: ', workbook)

  //     // Step 3: Select the first sheet
  //     const worksheet = workbook.Sheets[workbook.SheetNames[0]]
  //     console.log('response 1: worksheet: ', worksheet)

  //     // Step 4: Populate data starting from the second row
  //     tableArray?.forEach((rowData, rowIndex) => {
  //     // data.forEach((rowData, rowIndex) => {
  //       const rowNum = rowIndex + 2 // Start from the second row
  //       console.log('response 1, forloop: ', rowData)
  //       // Fill each column in the current row
  //       Object.keys(rowData).forEach((col, colIndex) => {
  //         const cellAddress = XLSX.utils.encode_cell({
  //           c: colIndex,
  //           r: rowNum - 1
  //         })
  //         worksheet[cellAddress] = { v: rowData[col] }
  //       })
  //     })

  //     const range = XLSX.utils.decode_range(worksheet['!ref'])
  //     range.e.r = data.length + 1 // Update the row end to include new data
  //     worksheet['!ref'] = XLSX.utils.encode_range(range)

  //     // Step 5: Generate a new workbook and save it as an Excel file
  //     const newWorkbook = XLSX.write(workbook, {
  //       bookType: 'xlsx',
  //       type: 'array'
  //     })
  //     console.log('response 1: newWorkbook: ', newWorkbook);

  //     const blob = new Blob([newWorkbook], {
  //       type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
  //     })
  //     console.log('response 1: blob: ', blob);

  //     FileSaver.saveAs(blob, 'Modified_Template.xlsx')
  //   } catch (error) {
  //     console.error('Error exporting Excel file:', error)
  //   }
  // }

  /* Trial */

  const exportExcelWithTemplate = async () => {
    // try {
    //   // Step 1: Fetch the template file
    //   // const response = await fetch('/Template.xlsx');
    //   const response = await fetch('/BOM_Master_Data_Template.xlsm')
    //   const arrayBuffer = await response.arrayBuffer()

    //   // Step 2: Read the array buffer as an Excel workbook
    //   const workbook = XLSX.read(arrayBuffer, { type: 'array' })
    //   console.log('inside workbook: ', workbook)

    //   // Step 3: Select the first sheet
    //   const worksheet = workbook.Sheets[workbook.SheetNames[1]]
    //   console.log('inside worksheet: ', worksheet)

    //   // Step 4: Extract column headers from the first row
    //   const headers = []
    //   const range = XLSX.utils.decode_range(worksheet['!ref'])
    //   for (let col = range.s.c; col <= range.e.c; col += 1) {
    //     const cellAddress = XLSX.utils.encode_cell({ c: col, r: 0 })
    //     console.log('inside cellAddress: ', cellAddress)

    //     const cell = worksheet[cellAddress]
    //     if (cell) headers.push(cell.v)
    //   }
    //   console.log('Headers:', headers)
    //   console.log('inside tableArray: ', tableArray)

    //   // Step 5: Populate data starting from the second row
    //   tableArray?.forEach((rowData, rowIndex) => {
    //     const rowNum = rowIndex + 2 // Start from the second row
    //     headers.forEach((header, colIndex) => {
    //       if (rowData[header] !== undefined) {
    //         const cellAddress = XLSX.utils.encode_cell({
    //           c: colIndex,
    //           r: rowNum - 1
    //         })
    //         worksheet[cellAddress] = { v: rowData[header] }
    //       }
    //     })
    //   })

    //   // Update the worksheet range to include new rows
    //   range.e.r = tableArray?.length + 1
    //   worksheet['!ref'] = XLSX.utils.encode_range(range)

    //   // Step 6: Generate a new workbook and save it as an Excel file
    //   const newWorkbook = XLSX.write(workbook, {
    //     bookType: 'xlsx',
    //     type: 'array'
    //   })
    //   const blob = new Blob([newWorkbook], {
    //     type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    //   })
    //   FileSaver.saveAs(blob, 'Output_Modified_Template.xlsm')
    // } catch (error) {
    //   console.error('Error exporting Excel file:', error)
    // }



















    try {
      // Step 1: Fetch the template file
      // const response = await fetch('/BOM_Master_Data_Template.xlsm');

      setLoader(true)
      // const response = await fetch('/BOM_Master_Data_Template - Copy.xlsm');
      const response = await fetch('/TemplateWithWorkingMacro.xlsm');
      if (!response.ok) throw new Error('Failed to fetch the template file.');
      const arrayBuffer = await response.arrayBuffer();
    
      // Step 2: Load the workbook with macros support
      const workbook = XLSX.read(arrayBuffer, { type: 'array', bookVBA: true });
      console.log('Workbook loaded:', workbook);
    
      // Step 3: Access the editable sheet (sheet at index 0)
      const editableSheet = workbook.Sheets[workbook.SheetNames[0]];
      if (!editableSheet) throw new Error('Editable sheet not found.');
      console.log('Editable Sheet:', editableSheet);
    
      // Step 4: Extract column headers from the first row
      const headers = [];
      const range = XLSX.utils.decode_range(editableSheet['!ref']);
      for (let col = range.s.c; col <= range.e.c; col += 1) {
        const cellAddress = XLSX.utils.encode_cell({ c: col, r: 0 });
        const cell = editableSheet[cellAddress];
        if (cell) headers.push(cell.v);
      }
      console.log('Headers:', headers);
      console.log('tableArray:', tableArray);
    
      // Step 5: Populate data starting from the second row
      tableArray?.forEach((rowData, rowIndex) => {
        const rowNum = rowIndex + 2; // Start from the second row
        headers.forEach((header, colIndex) => {
          if (rowData[header] !== undefined) {
            const cellAddress = XLSX.utils.encode_cell({ c: colIndex, r: rowNum - 1 });
            editableSheet[cellAddress] = { v: rowData[header], t: typeof rowData[header] === 'number' ? 'n' : 's' }; // Ensure type is set
          }
        });
      });
    
      // Step 6: Update the worksheet range to include new rows
      range.e.r = (tableArray?.length || 0) + 1;
      editableSheet['!ref'] = XLSX.utils.encode_range(range);
    
      // Step 7: Save the workbook, preserving macros
      const newWorkbook = XLSX.write(workbook, {
        bookType: 'xlsm',
        type: 'array',
        bookVBA: true,
      });
    
      // Step 8: Create a Blob and save the file
      const blob = new Blob([newWorkbook], {
        type: 'application/vnd.ms-excel.sheet.macroEnabled.12',
      });
      setLoader(false)
      FileSaver.saveAs(blob, 'BOM_Master_Data.xlsm');
    } catch (error) {
      setLoader(false)
      console.error('Error exporting Excel file:', error);
    }
  }

  const renderHTML = () => (
    <div className={styles.formGroup}>
      {loading && (
        <div className={styles.gridLoader}>
          <GridLoadingSpinner />
        </div>
      )}
      <Grid
        container
        spacing={1}
        style={{ display: 'flex', justifyContent: 'left' }}
        columns={12}
      >
        <Grid
          item
          // sm={12}
          md={4}
          lg={3}
          style={{ marginTop: '5px', marginBottom: '5px' }}
        >
          <div
            className={styles.rowDiv}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <div
              className={clsx(styles.lableDiv)}
              style={{ width: '50%', display: 'flex', alignItems: 'center' }}
            >
              <label className={styles.label}>Project Code</label>
            </div>
            <div className={styles.formDiv}>
              <CustomSelect
                name='Project_Code'
                options={projectCodeArray}
                className={clsx(styles.select, styles.sel1)}
                value={selectedProjectCode}
                isMulti={false}
                defaultValue={latestProject}
                isClearable
                isMandatory
                onChange={selectedOption => {
                  handleDropdownChange(selectedOption)
                  setSelectedProjectCode(selectedOption)
                  // if (selectedOption !== null) {
                  // getVcForProjectCode(selectedOption)
                  // }
                  // getVcForProjectCode(selectedOption); // Fetch VC for selected project code
                }}
              />
            </div>
          </div>
        </Grid>

        <Grid
          item
          md={3}
          lg={3}
          // sm={12}
          style={{ marginTop: '5px', marginBottom: '5px' }}
        >
          <div
            className={styles.rowDiv}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <div
              className={clsx(styles.lableDiv)}
              style={{ width: '9%', display: 'flex', alignItems: 'center' }}
            >
              <label className={styles.label}>VC</label>
            </div>
            <div className={styles.formDiv}>
              <CustomSelect
                name='bc'
                options={bcArray}
                // isDisabled={checkDisable}
                className={clsx(styles.select, styles.sel1)}
                value={Bc}
                isMulti={false}
                isClearable
                onChange={selectedOption => {
                  setBC(selectedOption)
                }}
              />
            </div>
          </div>
        </Grid>

        {/* <Grid item md={2} lg={2} sm={12}>
        */}

        <Grid
          item
          md={4}
          lg={4}
          // sm={12}
          style={{ marginTop: '5px', marginBottom: '5px' }}
        >
          <div
            style={{
              marginLeft: '5px',
              // paddingRight: "10px",
              marginTop: '1px',
              display: 'flex'
              // justifyContent: "right",
            }}
          >
            <AuthChecker operation='search'>
              {isAuthorized => (
                <div>
                  <Button
                    className={clsx(
                      styles.actionButton,
                      styles.primaryActionButton
                    )}
                    style={{ marginRight: '12px !important' }}
                    variant='primary'
                    onClick={showTable}
                    disabled={!isAuthorized}
                    data-testid='confirm-action'
                  >
                    Show Data
                  </Button>
                  {/* <Button></Button> */}
                  {/* <a href='www.google.com'>Link</a> */}
                  {/* <button type= 'submit' onClick={showTable}><a href=''>Link</a></button> */}
                  {/* <button
                      type='submit'
                      onClick={exportExcelWithTemplate}
                      style={{
                        background: 'none',
                        border: 'none',
                        color: 'blue',
                        textDecoration: 'underline',
                        cursor: 'pointer'
                      }}
                    >
                      Link
                    </button> */}
                </div>
              )}
            </AuthChecker>

            {/* {
                flag && (
                  <Button
                    className={clsx(styles.actionButton, styles.primaryActionButton)}
                    style={{ marginRight: '12px !important' }}
                    variant='primary'
                    onClick={exportExcelWithTemplate}
                    // disabled={!isAuthorized}
                    // data-testid='confirm-action'
                  >
                    Download Master Data
                  </Button>
                )
              } */}

            {/* {!auth.userRole.includes("Vendor") ? (
                <AuthChecker operation="search">
                  {(isAuthorized) => (
                    <Button
                      className={clsx(
                        styles.actionButton,
                        styles.primaryActionButton
                      )}
                      style={{
                        marginRight: "12px !important",
                        marginLeft: "5px",
                      }}
                      variant="primary"
                      onClick={() => {
                        getAllData();
                      }}
                      disabled={!isAuthorized}
                      data-testid="confirm-action"
                    >
                      Show All
                    </Button>
                  )}
                </AuthChecker>
              ) : null} */}

            {/* {
        loader==false&&
    
          <>
        <AuthChecker operation="download">

          {isAuthorized => (
              <Button
                className={clsx(
                  styles.actionButton,
                  styles.primaryActionButton,
                )}
                variant="primary"
                //   onClick={() => runPreSubmissionChecks() && handleSubmit()}
                onClick={()=>{

                  if(tableArray.length>=1){exportExcel(tableArray);}
                }}
                  disabled={!isAuthorized}
                data-testid="confirm-action"
                style={{marginLeft:"5px"}}

              >
                download
              </Button>
            )}
  
          </AuthChecker>
      
      </> 
    } */}
          </div>
        </Grid>
      </Grid>
    </div>
  )

  const uploadDocument = () => {
    var input = document.createElement('input')
    input.type = 'file'
    input.id = 'file'
    input.setAttribute('accept', '.xlsx')
    input.click()

    input.onchange = e => {
      const formData = new FormData()
      formData.append('file', e.target.files[0])
      // formData.append("Supplier_Code", Bc?.value);
      // formData.append('Project_code', projectCode?.value)
      // formData.append('')
      // fetch(`${baseUrl}UpdateMasterData/`, {
      // fetch(`${baseUrl}ProductLineBOMData/`, {
      fetch(`${baseUrl}ProductLineBOMData/`, {
        // method: "PUT",
        method: 'POST',
        body: formData
      })
        // .then((response) => {
        //   // console.log(response)
        //   // return response.json()
        // }

        .then(response => {
          console.log(response)
          if (response.status == 200) {
            showPopup({
              type: MESSAGE_TYPE.SUCCESS,
              //  contextText:getUrl(certificateUrl),
              contextText: MESSAGE_TYPE.SUCCESS,
              info: 'Document Uploaded Successfully'
            })
            // return response.json()
            getTableData()
          } else if (response.status == 400) {
            showPopup({
              type: MESSAGE_TYPE.FAILURE,
              contextText: MESSAGE_TYPE.FAILURE,
              info: `${response.data.status}`
            })
          } else {
            showPopup({
              type: MESSAGE_TYPE.FAILURE,
              contextText: MESSAGE_TYPE.FAILURE,
              info: `Server error: ${response.data.status}`
            })
          }
        })
        // .then((responseJson) => {
        //   console.log(responseJson);
        // //  var certificateurlstampurl = 'https://tml-test-digital-invoice-s3.s3.amazonaws.com/Certificate%20%2838%29_23-12-2022T11%3A02%3A38.pdf?response-content-disposition=attachment&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAQGDNMWVUMVQVJ4YB%2F20221223%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20221223T110240Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=4da98e95872b5a7d4f2b15a2f524ce1d18178ba15b0923a4ca1b8605ad79bd1a';

        //   if (responseJson) {
        //     showPopup({
        //       type: MESSAGE_TYPE.SUCCESS,
        //       //  contextText:getUrl(certificateUrl),
        //         contextText:MESSAGE_TYPE.SUCCESS,
        //       info:"Document Uploaded Successfully"

        //     });
        //   } else {
        //     showPopup({
        //       type: MESSAGE_TYPE.FAILURE,
        //       contextText: MESSAGE_TYPE.FAILURE,
        //        info: responseJson.url,
        //     });
        //   }

        //   // eslint-disable-next-line radix
        //   // let status1 = parseInt(response.status);
        //   //  console.log(response.status)
        //   // if (status1 == "200") {
        //   //   //   document.removeChild(input);
        //   // //  getTableData();

        //   // }
        // })

        .catch(error => {
          showPopup({
            type: MESSAGE_TYPE.FAILURE,
            contextText: MESSAGE_TYPE.FAILURE,
            info: MESSAGE_TYPE.FAILURE
          })
        })
    }
  }

  const secondaryActions = [
    // {
    //   name: "Upload",
    //   authOperation: "upload",
    //   shouldEnable: () => isLatestProjectSelected, // Enable only if the latest project is selected
    //   actionFn: () => {
    //     uploadDocument();
    //   },
    //   customClass: "",
    // },

    {
      name: 'Download',
      authOperation: 'download',
      shouldEnable: selected => true,
      actionFn: selected => {exportExcelWithTemplate()},
      customClass: ''
    }
  ]

  const handleSecondaryActionOnRole = () => {
    if (auth.userRole.includes('admin')) {
      return secondaryActions
    }
    if (auth.userRole.includes('GDC') || auth.userRole.includes('TMLBSL')) {
      return secondaryActions.filter(
        item =>
          item.name === 'Download Documents' ||
          item.name === 'Download Certificate' ||
          item.name === 'Upload'
      )
    }
    if (auth.userRole.includes('Vendor')) {
      return secondaryActions.filter(
        item =>
          item.name !== 'Download Documents' &&
          item.name !== 'Download Certificate'
      )
    }
    if (auth.userRole.includes('Purchase')) {
      return secondaryActions.filter(
        item =>
          item.name === 'Download Documents' ||
          item.name === 'Download Certificate'
      )
    }
    return secondaryActions
  }

  return (
    <>
      <div className={styles.container} style={{ boxSizing: 'border-box' }}>
        <div>
          <div
            className={styles.backButtonLastRefreshedDateWrapper}
            style={{ marginTop: '10px' }}
          >
            <BackButton
              action='Back'
              handleClick={redirectToApplicationLandingPage}
              style={{ marginBottom: '0px' }}
            />
            <div
              style={{ width: '100%' }}
              // style={{ display: 'flex', flexDirection: 'row-reverse' }}
            >
              <div className={styles.topDiv}>
                <div
                  className={styles.processHeader}
                  style={{ display: 'flex', height: '3em' }}
                >
                  <div style={{ width: '100%' }}>
                    <CustomTab
                      title='BOM DVA Master Data'
                      withOutCount
                      isSelected
                    />
                  </div>

                  {/* <StepBar1 enable={5} /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className={styles.formContainer}>
            <CustomFormGroup body={renderHTML()} />
          </div>
        </div>
        {flag && (
          <div
            className={styles.tableView}
            style={{ height: '86%', padding: '0 2em' }}
          >
            <Table
              columns={
                !auth.userRole.includes('Vendor')
                  ? [...baseDefaultColumns]
                  : [...baseDefaultColumns]
              }
              defaultColumns={[...baseDefaultColumns]}
              rowHeight={38}
              rows={tableArray}
              // primaryAction={{
              //  name: "Submit Data",
              //  authOperation: "submit",
              //  shouldEnable: () => isLatestProjectSelected, // Enable only if the latest project is selected
              //  actionFn: handleOpenFormModal,
              // }}
              secondaryActions={secondaryActions}
              setAsnCount={setCount}
              isDataLoading={loader}
              actionButtons
              onRowSelect={e => {}}
              removeRowSelection
            />
          </div>
        )}
      </div>
      {/* <Button
            className={clsx(
              styles.actionButton,
              styles.primaryActionButton,
            )}
            variant="primary"
            // onClick={onDelete}
          >
            Yes
          </Button> */}
      <ResetModalF
        isPopupOpen={isPopupOpenFormModal}
        handleCloseFormModal={handleCloseFormModal}
        BOMvalidationAPI={BOMvalidationAPI}
      />

      <ResetModal
        isPopupOpen={isPopupOpen}
        handleClose={handleClose}
        goNext={goNext}
      />
    </>
  )
}
function ResetModalF ({ isPopupOpen, handleCloseFormModal, BOMvalidationAPI }) {
  return (
    <Dialog
      open={isPopupOpen}
      className={styles.popContainer}
      classes={{
        paper: styles.popupBox
      }}
      data-testid='reset-popup'
    >
      <>
        <DialogTitle>
          <span className={styles.title}>
            <WarningIcon style={{ height: '18px', width: '18px' }} />
            <span style={{ marginLeft: '8px' }} className={styles.txt}>
              Confirm Validation
            </span>
          </span>
        </DialogTitle>
        <DialogContent className={styles.content}>
          <div>
            {/* <p> On confirmation, All existing data will be overwritten by the uploaded data.</p> */}
            <p>On confirmation, BOM will be validated</p>

            <p>Are you sure ?</p>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            className={clsx(styles.actionButton, styles.transparentButton)}
            onClick={handleCloseFormModal}
          >
            Cancel
          </Button>
          <Button
            className={clsx(styles.actionButton, styles.primaryActionButton)}
            variant='primary'
            onClick={e => {
              e.preventDefault()
              handleCloseFormModal()
              BOMvalidationAPI()
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </>
    </Dialog>
  )
}
function ResetModal ({ isPopupOpen, handleClose, goNext }) {
  return (
    <Dialog
      open={isPopupOpen}
      className={styles.popContainer}
      classes={{
        paper: styles.popupBox
      }}
      data-testid='reset-popup'
    >
      <>
        <DialogTitle>
          <span className={styles.title}>
            <WarningIcon style={{ height: '18px', width: '18px' }} />
            <span style={{ marginLeft: '8px' }} className={styles.txt}>
              Confirm file Upload
            </span>
          </span>
        </DialogTitle>
        <DialogContent className={styles.content}>
          <div>
            <p> On confirmation ,Records will be appended.</p>
            <p>Are you sure ?</p>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            className={clsx(styles.actionButton, styles.transparentButton)}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            className={clsx(styles.actionButton, styles.primaryActionButton)}
            variant='primary'
            onClick={e => {
              e.preventDefault()
              handleClose()
              goNext()
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </>
    </Dialog>
  )
}
ResetModalF.propTypes = {
  isPopupOpen: PropTypes.bool.isRequired,
  handleCloseFormModal: PropTypes.func.isRequired,
  BOMvalidationAPI: PropTypes.func.isRequired
}

ResetModal.propTypes = {
  isPopupOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  goNext: PropTypes.func.isRequired
}

export default withAllowedOperationsProvider(VendarData, RESOURCE_TYPE.PROJECT)
