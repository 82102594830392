/* eslint-disable react/self-closing-comp */
/* eslint-disable vars-on-top */
/* eslint-disable eqeqeq */
/* eslint-disable prefer-const */
/* eslint-disable no-var */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-use-before-define */
/* eslint-disable array-callback-return */
/* eslint-disable camelcase */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import {
  Modal,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Form,
  Box,
  TextField,
  Grid,
  FormGroup,
  IconButton
} from '@material-ui/core'
import WarningIcon from '@mui/icons-material/Warning'
import FormLabel from '@mui/material/FormLabel'
import * as FileSaver from 'file-saver'
import XLSX from 'sheetjs-style'
// for print user in consol
import jwt_decode from 'jwt-decode'
import { useSelector } from 'react-redux'
// for print user in consol
import axios from 'axios'

import clsx from 'clsx'
// import $ from 'jquery';
import { useHistory } from 'react-router-dom'
import Table from '../../components/Table/Table'
import styles from './PartBuyerMapping.module.css'
import { CustomFormGroup, CustomSelect } from '../../components/FormComponents'
import BackButton from '../../components/BackButton/BackButton'
import {
  DATE_FORMAT,
  API_RESOURCE_URLS,
  DISPLAY_MESSAGES,
  MESSAGE_TYPE,
  MESSAGE_TYPE_CERTIFICATION,
  MESSAGE_TYPE_ANNEXURE,
  RESOURCE_TYPE,
  USER_OPERATIONS,
  Regex
} from '../../constants'
import { withAllowedOperationsProvider } from '../../hocs'
import { CustomTab, Button, AuthChecker } from '../../atomicComponents'
import { API } from '../../apis/api'
import { buildErrorMessage } from '../../apis/calls'
import { usePopupManager } from '../../providers/PopupManager/PopupManager'

import StepBar1 from '../../components/StepBar1/StepBar'
import { GridLoadingSpinner } from '../../components'

const PartBuyerMapping = () => {
  const history = useHistory()
  const { showPopup } = usePopupManager()
  // const baseUrl='http://127.0.0.1:8000/file/'
  const baseUrl = useSelector(state => state.counter.baseUrl) // useSelector
  const auth = useSelector(state => state.auth) // useSelector
  console.log('auth in Supplier: ', auth)
  const [dynamicColumns, setDynamicColumns] = useState([])
  const [dynamicColumns1, setDynamicColumns1] = useState([])
  const [plant, setPlant] = useState(null)
  const [flag, show] = useState(false)
  const [checkDisable, setDisable] = useState(true)
  const [projectCode, setProjectCode] = useState(null)
  const [projectCodeArray, setProjectCodeArray] = useState([])
  const [isLatestProjectSelected, setIsLatestProjectSelected] = useState(false) // New state for button enable/disable
  // const [projectCodeArray, setProjectCodeArray] = useState([]);
  const [selectedProjectCode, setSelectedProjectCode] = useState(null)
  console.log('Project state: ', selectedProjectCode)
  const [latestProject, setLatestProject] = useState('')
  const [Bc, setBC] = useState(null)
  const [bcArray, setBcArray] = useState([])
  const [plantArray, setPlantArray] = useState([])
  const [tableArray, setTableArray] = useState([])
  const [buyerPartMappingArray, setBuyerPartMappingArray] = useState([])
  const [fyArray, setFyArray] = useState([])
  const [Fy, setFY] = useState(null)
  const [quarterArray, setQuarterArray] = useState([])
  const [quarter, setQuarter] = useState(null)
  const [asnCount, setAsnCount] = useState(0)
  const [loading, setLoader] = useState(false)
  const [loader, setLoading] = useState(true)

  //
  const [isPopupOpenFormModal, setPopupOpenFormModal] = useState(false)
  const handleOpenFormModal = () => {
    setPopupOpenFormModal(true)
  }
  const handleCloseFormModal = () => setPopupOpenFormModal(false)

  //

  const [confirmWelcomeEmailPopup, setConfirmWelcomeEmailPopup] = useState(false);

const handleOpenConfirmEmailPopup = () => setConfirmWelcomeEmailPopup(true);
const handleCloseConfirmEmailPopup = () => setConfirmWelcomeEmailPopup(false);

  const ROW_HEIGHT = 38

  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const handleOpen = () => setIsPopupOpen(true)
  const handleClose = () => setIsPopupOpen(false)
  const goNext = () => {
    uploadFileData()
  }

  const redirectToApplicationLandingPage = () => {
    history.push('/')
  }

  const setCount = count => {
    setAsnCount(count)
  }

  //   const getTableData = async (param) => {
  //     const payload = { Project_code: param };

  //     try {
  //         const response = await axios.post(`${baseUrl}PurchaseBuyerMapping/`, payload, {
  //             headers: {
  //                 'Content-Type': 'application/json'
  //             }
  //         });

  //         console.log("Response JSON:", response.data);

  //         if (Array.isArray(response.data)) {
  //             setTableArray(response.data);
  //         } else {
  //             console.error('Response is not an array:', response.data);
  //             setTableArray([]);
  //         }
  //     } catch (error) {
  //         if (error.response) {
  //             const { status, data } = error.response;
  //             if (status === 400) {
  //                 console.error('Bad Request:', data);
  //             } else if (status === 404) {
  //                 console.error('Not Found:', data);
  //             } else if (status === 500) {
  //                 console.error('Internal Server Error:', data);
  //             } else {
  //                 console.error('Error fetching table data:', data);
  //             }
  //         } else {
  //             console.error('Network or other error:', error.message);
  //         }
  //         setTableArray([]);
  //     }
  // };

  const getTableData = async param => {
    const payload = { Project_code: param }

    try {
      console.log('Sending payload:', payload)

      const [tableDataResponse, buyerPartMappingResponse] = await Promise.all([
        axios.post(`${baseUrl}PurchaseBuyerMapping/`, payload, {
          headers: {
            'Content-Type': 'application/json'
          }
        }),
        axios.post(`${baseUrl}BuyerPartMappingForbutton/`, payload, {
          headers: {
            'Content-Type': 'application/json'
          }
        })
      ])

      console.log('Table Data Response JSON:', tableDataResponse.data)
      console.log(
        'Buyer Part Mapping Response JSON:',
        buyerPartMappingResponse.data
      )

      if (Array.isArray(tableDataResponse.data)) {
        setTableArray(tableDataResponse.data)
      } else {
        console.error(
          `Table data response is not an array: ${JSON.stringify(
            tableDataResponse.data
          )}`
        )
        setTableArray([])
      }

      if (Array.isArray(buyerPartMappingResponse.data)) {
        setBuyerPartMappingArray(buyerPartMappingResponse.data)
      } else {
        console.error(
          `Buyer part mapping response is not an array: ${JSON.stringify(
            buyerPartMappingResponse.data
          )}`
        )
        setBuyerPartMappingArray([])
      }
    } catch (error) {
      console.error('Error during data fetching:', error)

      let errorMessage

      if (error.response) {
        const { status, data } = error.response

        switch (status) {
          case 400:
            errorMessage = `Bad Request: ${
              data.message || 'The server could not understand the request.'
            }`
            break
          case 404:
            errorMessage = `Not Found: ${
              data.message || 'The requested resource could not be found.'
            }`
            break
          case 500:
            errorMessage = `Internal Server Error: ${
              data.message || 'The server encountered an error.'
            }`
            break
          default:
            errorMessage = `Error fetching data: ${
              data.message || 'An unexpected error occurred.'
            }`
        }

        console.error(errorMessage)
      } else {
        errorMessage = `Network or other error: ${
          error.message || 'An unknown error occurred.'
        }`
        console.error(errorMessage)
      }

      setTableArray([])
      setBuyerPartMappingArray([])
    }
  }

  const getAllProjectCode = async () => {
    try {
      const { data } = await axios.get(
        `${baseUrl}AllProjectCodeforProductline/`
      )
      const allProjectCodes = data.map(({ Project_code }) => Project_code)
      const uniqueProjects = [...new Set(allProjectCodes)]
      const projectCodeOptions = uniqueProjects.map(value => ({
        value,
        label: value
      }))

      setProjectCodeArray(projectCodeOptions)
      await getRecentProjectCode(projectCodeOptions)
    } catch (error) {
      console.error('Error fetching project codes:', error)
    }
  }

  const getRecentProjectCode = async projectCodeOptions => {
    try {
      const { data, status } = await axios.get(
        `${baseUrl}LatestProjectCodeforProductline/`
      )
      if (status !== 200) {
        throw new Error('Failed to fetch the latest project code')
      }
      const recentProj = {
        label: data?.Project_code,
        value: data?.Project_code
      }
      setLatestProject(recentProj)
      setSelectedProjectCode(recentProj) // latest project code
    } catch (error) {
      console.error('Error fetching recent project codes:', error)
    }
  }

  useEffect(() => {
    getAllProjectCode()
  }, [])

  useEffect(() => {
    if (latestProject) {
      setSelectedProjectCode(latestProject)
    }
  }, [latestProject])

  // Check if the selected project code is the latest project code
  useEffect(() => {
    if (selectedProjectCode && latestProject) {
      setIsLatestProjectSelected(
        selectedProjectCode.value === latestProject.value
      )
    }
  }, [selectedProjectCode, latestProject])

  const showTable = () => {
    if (selectedProjectCode === null || selectedProjectCode.value === '') {
      showPopup({
        type: MESSAGE_TYPE.INFORMATION,
        contextText: MESSAGE_TYPE.INFORMATION,
        info: 'Project Code is Mandatory'
      })
    } else {
      getTableData(selectedProjectCode.value)
      show(true)
      setLoading(false)
    }
  }

  const uploadFileData = () => {
    const input = document.createElement('input')
    input.type = 'file'
    input.id = 'file'
    input.setAttribute(
      'accept',
      '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
    )
    input.click()

    input.onchange = async e => {
      setLoader(true)
      const formData = new FormData()
      formData.append('file', e.target.files[0])

      setLoader(true)
      try {
        const response = await axios.post(
          `${baseUrl}UploadPurchaseBuyerMapping/`,
          formData
        )

        // Extract message from response data
        const message = response.data.status || 'File uploaded successfully'

        if (response.status === 200 || response.status === 201) {
          showPopup({
            type: MESSAGE_TYPE.SUCCESS,
            contextText: MESSAGE_TYPE.SUCCESS,
            info: message
          })
          getTableData(selectedProjectCode.value)
        } else {
          showPopup({
            type: MESSAGE_TYPE.FAILURE,
            contextText: MESSAGE_TYPE.FAILURE,
            info: message
          })
        }
      } catch (error) {
        console.error('Error during file upload:', error)

        // Extract error message from response if available
        const errorMessage =
          error?.response && error?.response?.data && error?.response?.data?.error
            ? error?.response.data.error
            : error?.message || 'File upload failed due to an error'

        // Show failure message in popup
        showPopup({
          type: MESSAGE_TYPE.FAILURE,
          contextText: MESSAGE_TYPE.FAILURE,
          info: errorMessage
        })
        console.log('error MSg: ', errorMessage)

        // Optionally throw the error message if needed for further handling
        // throw new Error(errorMessage)
      } finally {
        setLoader(false)
      }
      setLoader(false)
    }
  }

  // const exportExcel = async excelData => {
  //   var fileTypel =
  //     'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
  //   var fileExt = '.xlsx'
  //   const ws = XLSX.utils.json_to_sheet(excelData)
  //   const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
  //   const excelBuffer = XLSX.write(wb, { booktype: 'xlsx', type: 'array' })
  //   const data = new Blob([excelBuffer], { type: fileTypel })
  //   FileSaver.saveAs(data, `Part_Buyer_Mapping${fileExt}`)
  // }

  
  const exportExcel = async (excelData) => {
    setLoader(true);
  
    // Map the data and include the "Send E-mail" field correctly
    const newExcelData = excelData?.map(
      ({
        Project_code,
        Plant_code,
        Po_Plant,
        Supplier_code,
        Part_no,
        Purchase_group,
        SPOC,
        CTL,
        Buyer_ID,
        Test4, // Original key for "Send E-mail"
      }) => ({
        Project_code,
        Plant_code,
        Po_Plant,
        Supplier_code,
        Part_no,
        Purchase_group,
        SPOC,
        CTL,
        Buyer_ID,
        "Send_mail_Yes_No": Test4, // Correctly include "Send E-mail"
      })
    );
  
    console.log("new excel: ", newExcelData);
  
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExt = ".xlsx";
  
    // Generate the Excel sheet
    const ws = XLSX.utils.json_to_sheet(newExcelData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { booktype: "xlsx", type: "array" });
  
    // Create a Blob and trigger the download
    const data = new Blob([excelBuffer], { type: fileType });
    setLoader(false);
    FileSaver.saveAs(data, `Part_Buyer_Mapping${fileExt}`);
  };
  
  
  const baseDefaultColumns = [
    {
      width: 170,
      title: 'Project Code',
      field: 'Project_code',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 170,
      title: 'Plant Code',
      field: 'Plant_code',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 170,
      title: 'PO Plant',
      field: 'Po_Plant',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 170,
      title: 'Supplier Code',
      field: 'Supplier_code',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 130,
      title: 'Part No',
      field: 'Part_no',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 150,
      title: 'Purchase Group',
      field: 'Purchase_group',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 130,
      title: 'Buyer ID',
      field: 'Buyer_ID',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 150,
      title: 'SPOC',
      field: 'SPOC',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 130,
      title: 'CTL',
      field: 'CTL',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 130,
      title: 'Send E-mail(Yes/No)',
      field: 'Test4',
      enableSearch: true,
      enableFilter: true
    }
  ]

  const BOMvalidationAPI = () => {
    console.log('inside submit api: ', selectedProjectCode?.value)

    if (!selectedProjectCode?.value) {
      console.error('Project code is missing.')
      showPopup({
        type: MESSAGE_TYPE.FAILURE,
        contextText: MESSAGE_TYPE.FAILURE,
        info: 'Project code is required.'
      })
      return
    }

    const apiBody = {
      Project_code: selectedProjectCode.value
    }
    setLoader(true)
    axios
      .post(`${baseUrl}BuyerPartMappingSubmissionstatus/`, apiBody, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        console.log('responseJson', response.data)
        if (response.data.error) {
          throw new Error(response.data.error)
        }
        showPopup({
          type: MESSAGE_TYPE.SUCCESS,
          contextText: MESSAGE_TYPE.SUCCESS,
          info: 'Part Buyer Mapping Submitted Successfully'
        })
        history.push('/')
      })
      .catch(error => {
        let errorMessage = error.response?.data?.details
          ? JSON.parse(error.response.data.details).messages
          : error.response?.data?.error || error.message
        console.error('Error in submit:', errorMessage)
        showPopup({
          type: MESSAGE_TYPE.FAILURE,
          contextText: MESSAGE_TYPE.FAILURE,
          info: `Error: ${errorMessage}`
        })
      })
    setLoader(false)
  }


  const SendWelcomeEmail = ( submitDate) => {
    let headerss = new Headers();
    headerss.append("Content-Type", "application/json");
  
    const payload = {
      Project_code: selectedProjectCode.value, // Use the selected project code
      submit_date: submitDate, // Use the date from the input
    };
  
    fetch(`${baseUrl}SendEmailToAllVendors_PV_New_change/`, {
      method: "POST",
      headers: headerss,
      body: JSON.stringify(payload), // Add payload to the request body
    })
      .then((response) =>
        response.json().then((jsonRes) => ({
          status: response.status,
          body: jsonRes,
        }))
      )
      .then((responseJson) => {
        if (responseJson?.status < 400) {
          showPopup({
            type: MESSAGE_TYPE.SUCCESS,
            contextText: MESSAGE_TYPE.SUCCESS,
            info: "Email sent successfully!",
          });
        } else {
          showPopup({
            type: MESSAGE_TYPE.FAILURE,
            contextText: MESSAGE_TYPE.FAILURE,
            info: "Email trigger failed!",
          });
        }
      })
      .catch((error) => {
        showPopup({
          type: MESSAGE_TYPE.FAILURE,
          contextText: MESSAGE_TYPE.FAILURE,
          info: error,
        });
      });
  };
  
  


  const renderHTML = () => (
    <div className={styles.formGroup}>
      {loading && (
        <div className={styles.gridLoader}>
          <GridLoadingSpinner />
        </div>
      )}
      <Grid
        container
        spacing={2}
        style={{ display: 'flex', justifyContent: 'left' }}
        columns={12}
      >
        <Grid
          item
          // sm={12}
          md={4}
          lg={4}
          // style={{ marginTop: "5px", marginBottom: "5px" }}
        >
          <div
            className={styles.rowDiv}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <div
              className={clsx(styles.lableDiv)}
              style={{ width: '40%', display: 'flex', alignItems: 'center' }}
            >
              <label className={styles.label}>Project Code</label>
            </div>
            <div className={styles.formDiv}>
              <CustomSelect
                name='Project Code'
                options={projectCodeArray}
                className={clsx(styles.select, styles.sel1)}
                value={selectedProjectCode}
                isMulti={false}
                defaultValue={latestProject}
                isClearable
                isMandatory
                onChange={selectedOption => {
                  setSelectedProjectCode(selectedOption)
                  //   getVcForProjectCode(selectedOption); // Fetch VC for selected project code
                }}
              />
            </div>
          </div>
        </Grid>

        {/* <Grid item md={2} lg={2} sm={12}>
         */}

        <Grid
          item
          md={3}
          lg={3}
          // sm={12}
          // style={{ marginTop: "5px", marginBottom: "5px" }}
        >
          <div
            style={{
              marginLeft: '5px',
              paddingRight: '10px',
              marginTop: '1px',
              display: 'flex',
              justifyContent: 'left'
            }}
          >
            <AuthChecker operation='search'>
              {isAuthorized => (
                <Button
                  className={clsx(
                    styles.actionButton,
                    styles.primaryActionButton
                  )}
                  style={{ marginRight: '12px !important' }}
                  variant='primary'
                  onClick={showTable}
                  disabled={!isAuthorized}
                  data-testid='confirm-action'
                >
                  Show Data
                </Button>
              )}
            </AuthChecker>
          </div>
        </Grid>
      </Grid>
    </div>
  )

  const secondaryActions = [
    {
      name: 'Upload',
      authOperation: 'upload',
      shouldEnable: selected => true,
      actionFn: () => {
        uploadFileData()
      },
      customClass: ''
    },

    {
      name: 'Download',
      authOperation: 'download',
      shouldEnable: selected => true,
      actionFn: selected => {
        //  getDataForExcel()
        exportExcel(tableArray)
      },
      customClass: ''
    },
    {
      name: "Send E-mail",
      authOperation: "sendWelcomeEmail",
      shouldEnable: () => true, // Always enable the button
      actionFn: () => {
        handleOpenConfirmEmailPopup(); // Open the confirmation popup
      },
    },
  ]

  return (
    <>
      <div className={styles.container} style={{ boxSizing: 'border-box' }}>
        <div>
          <div
            className={styles.backButtonLastRefreshedDateWrapper}
            style={{ marginTop: '10px' }}
          >
            <BackButton
              action='Back'
              handleClick={redirectToApplicationLandingPage}
              style={{ marginBottom: '0px' }}
            />
            <div
              style={{ width: '100%' }}
              // style={{ display: 'flex', flexDirection: 'row-reverse' }}
            >
              <div className={styles.topDiv}>
                <div
                  className={styles.processHeader}
                  style={{ display: 'flex', height: '3em' }}
                >
                  <div style={{ width: '100%' }}>
                    <CustomTab
                      title='Part Buyer Mapping'
                      withOutCount
                      isSelected
                    />
                  </div>

                  {/* <StepBar1 enable={5} /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className={styles.formContainer}>
            <CustomFormGroup body={renderHTML()} />
          </div>
        </div>
        {flag && (
          <div
            className={styles.tableView}
            style={{ height: '86%', padding: '0 2em' }}
          >
            <Table
              columns={
                !auth.userRole.includes('Vendor')
                  ? [...baseDefaultColumns]
                  : [...baseDefaultColumns]
              }
              defaultColumns={[...baseDefaultColumns]}
              rowHeight={38}
              rows={tableArray}
              primaryAction={{
                name: 'Submit',
                authOperation: 'submit',
                // shouldEnable: () => isLatestProjectSelected, // Enable only if the latest project is selected
                shouldEnable: selected => true,
                actionFn: handleOpenFormModal
              }}
              secondaryActions={secondaryActions}
              setAsnCount={setCount}
              isDataLoading={loader}
              actionButtons
              onRowSelect={e => {}}
              removeRowSelection
            />
          </div>
        )}
      </div>
      {/* <Button
            className={clsx(
              styles.actionButton,
              styles.primaryActionButton,
            )}
            variant="primary"
            // onClick={onDelete}
          >
            Yes
          </Button> */}
      <ResetModalF
        isPopupOpen={isPopupOpenFormModal}
        handleCloseFormModal={handleCloseFormModal}
        BOMvalidationAPI={BOMvalidationAPI}
      />

      <ResetModal
        isPopupOpen={isPopupOpen}
        handleClose={handleClose}
        goNext={goNext}
      />
      <WelcomeEmailConfirmationPopup
        confirmWelcomeEmailPopup={confirmWelcomeEmailPopup}
        handleCloseConfirmEmailPopup={handleCloseConfirmEmailPopup}
        SendWelcomeEmail={SendWelcomeEmail}
      />
      {/* <SummaryEmailConfirmationPopup
        confirmSummaryEmailPopup={confirmSummaryEmailPopup}
        handleCloseConfirmSummaryEmailPopup={handleCloseConfirmSummaryEmailPopup}
        SendSummaryEmail={SendSummaryEmail}
      /> */}
    </>
  )
}
function WelcomeEmailConfirmationPopup({
  confirmWelcomeEmailPopup,
  handleCloseConfirmEmailPopup,
  SendWelcomeEmail,
}) {
  const [selectedDate, setSelectedDate] = useState(""); // State for the selected date

  const handleDateChange = (e) => {
    setSelectedDate(e.target.value); // Update state when date changes
  };

  return (
    <Dialog
      open={confirmWelcomeEmailPopup}
      className={styles.popContainer}
      classes={{
        paper: styles.popupBox,
      }}
      data-testid="reset-popup"
    >
      <>
        <DialogTitle>
          <span className={styles.title}>
            <WarningIcon style={{ height: "18px", width: "18px" }} />
            <span style={{ marginLeft: "8px" }} className={styles.txt}>
              Confirm Email Trigger
            </span>
          </span>
        </DialogTitle>
        <DialogContent className={styles.content}>
          <div>
            <p>
              On confirmation, An e-mail will be sent to the Send Email-Yes vendors.
            </p>
            <p>Are you sure?</p>
            <label htmlFor="datePicker" style={{ display: "block", marginTop: "1em" }}>
              Select Date:
            </label>
            <input
              id="datePicker"
              type="date"
              value={selectedDate}
              onChange={handleDateChange}
              style={{
                width: "100%",
                padding: "10px",
                fontSize: "16px",
                marginTop: "5px",
                borderRadius: "4px",
                border: "1px solid #ccc",
              }}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            className={clsx(styles.actionButton, styles.transparentButton)}
            onClick={handleCloseConfirmEmailPopup}
          >
            Cancel
          </Button>
          <Button
            className={clsx(styles.actionButton, styles.primaryActionButton)}
            variant="primary"
            onClick={(e) => {
              e.preventDefault();
              if (selectedDate) {
                SendWelcomeEmail(selectedDate); // Pass the selected date
              } else {
                alert("Please select a date before proceeding.");
              }
              handleCloseConfirmEmailPopup();
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </>
    </Dialog>
  );
}



function ResetModalF ({ isPopupOpen, handleCloseFormModal, BOMvalidationAPI }) {
  return (
    <Dialog
      open={isPopupOpen}
      className={styles.popContainer}
      classes={{
        paper: styles.popupBox
      }}
      data-testid='reset-popup'
    >
      <>
        <DialogTitle>
          <span className={styles.title}>
            <WarningIcon style={{ height: '18px', width: '18px' }} />
            <span style={{ marginLeft: '8px' }} className={styles.txt}>
              Confirm Validation
            </span>
          </span>
        </DialogTitle>
        <DialogContent className={styles.content}>
          <div>
            {/* <p> On confirmation, All existing data will be overwritten by the uploaded data.</p> */}
            <p>On confirmation, Part Buyer Mapping Will be Submitted.</p>

            <p>Are you sure ?</p>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            className={clsx(styles.actionButton, styles.transparentButton)}
            onClick={handleCloseFormModal}
          >
            Cancel
          </Button>
          <Button
            className={clsx(styles.actionButton, styles.primaryActionButton)}
            variant='primary'
            onClick={e => {
              e.preventDefault()
              handleCloseFormModal()
              BOMvalidationAPI()
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </>
    </Dialog>
  )
}
function ResetModal ({ isPopupOpen, handleClose, goNext }) {
  return (
    <Dialog
      open={isPopupOpen}
      className={styles.popContainer}
      classes={{
        paper: styles.popupBox
      }}
      data-testid='reset-popup'
    >
      <>
        <DialogTitle>
          <span className={styles.title}>
            <WarningIcon style={{ height: '18px', width: '18px' }} />
            <span style={{ marginLeft: '8px' }} className={styles.txt}>
              Confirm file Upload
            </span>
          </span>
        </DialogTitle>
        <DialogContent className={styles.content}>
          <div>
            <p> On confirmation ,Records will be appended.</p>
            <p>Are you sure ?</p>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            className={clsx(styles.actionButton, styles.transparentButton)}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            className={clsx(styles.actionButton, styles.primaryActionButton)}
            variant='primary'
            onClick={e => {
              e.preventDefault()
              handleClose()
              goNext()
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </>
    </Dialog>
  )
}
ResetModalF.propTypes = {
  isPopupOpen: PropTypes.bool.isRequired,
  handleCloseFormModal: PropTypes.func.isRequired,
  BOMvalidationAPI: PropTypes.func.isRequired
}

ResetModal.propTypes = {
  isPopupOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  goNext: PropTypes.func.isRequired
}

WelcomeEmailConfirmationPopup.propTypes = {
  confirmWelcomeEmailPopup: PropTypes.bool.isRequired,
  handleCloseConfirmEmailPopup: PropTypes.func.isRequired,
  SendWelcomeEmail: PropTypes.func.isRequired
}

export default withAllowedOperationsProvider(
  PartBuyerMapping,
  RESOURCE_TYPE.PROJECT
)
