/* eslint-disable spaced-comment */

import {MANAGERCOUNTER, BOXCOUNTER,SETID,DECREMENT_COUNTER, INCREMENT_COUNTER, STEP1,STEP2,STEP3 ,STEP4,STEP5,OFFBOAED,REJECTLIST} from '../actions/counterActions';

const counterReducer = (state = {

        // baseUrl:'https://plibackend.esakha-dev.tatamotors.com/AFS/', // Dev//
        baseUrl:'https://plibackend.esakha-pv-dev.tatamotors.com/AFS_PV/', // DEV//
        // baseUrl: 'http://172.31.153.124:9001/AFS_PV/',
        // baseUrl: 'http://127.0.0.1:8000/file/',
        // baseUrl: 'http://127.0.0.1:9000/file/',
        // baseUrl: 'http://172.31.153.124:9000/AFS/',     //New DEV ENV URL
        // baseUrl:'https://plibackend.esakha.tatamotors.com/AFS/',   
        // baseUrl:'https://plibackend.esakha-dev.tatamotors.com/AFS/',   // Dev URL AFS
       // baseUrl:'https://plibackend-pv.esakha.tatamotors.com/AFS_PV/',
        // baseUrl:'https://plibackend.esakha.tatamotors.com/AFS/', // CV PROD BE URL

    dataArray:[],
    rejectArray:[],
    countData:{},
    countDataManager:{},

    requestId:'',
    mimeType:{
        xlsx:"data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,",
        xls:"application/vnd.ms-excel;base64",
        docx:"data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,",
        rtf:"data:application/msword;base64,",
        PDF:"data:application/pdf;base64,",
        pdf:"data:application/pdf;base64,", 
        png:"data:image/png;base64,",
        PNG:"data:image/png;base64,",
        jpeg:"data:image/jpeg;base64,",
        JPEG:"data:image/jpeg;base64,",
        doc:"data:application/msword;base64,",
        DOC:"data:application/msword;base64,", 
        txt:"data:text/plain;base64,"
},
    value: 0,
    master: 1,
    currentValidate: false,

    step1: {
        taskIstanceIdStep1: 0,
        processIstanceIdStep1: 0,
        step: 1,
        step1Validate: false,
        step1Data:{
            prvDoc: [],
            recallDescription:'',
            recallTitle:'',
            sorDescription:''
        } 

    },
    step2: {
        taskIstanceIdStep2: 0,
        processIstanceIdStep2: 0,
        step: 2,
        step2Validate: false,
        step2Data:{
            prvDoc: [],
            recallCccDescription:'' 
        } 

    },
    step3: {
        taskIstanceIdStep3: 0,
        processIstanceIdStep3: 0,
        step: 3,
        step3Validate: false,
        step3Data:{
            prvDoc: [],
            recallTecDescription:'' 
        } 


    },
    step4: {
        taskIstanceIdStep4: 0,
        processIstanceIdStep4: 0,
        step: 4,
        step4Validate: false,
        step4Data:{
            prvDoc: [],
            contentBase64:'',
            recallDecision: '',
            recallNote:''
        }  
    },
    step5: {
        taskIstanceIdStep5: 0,
        processIstanceIdStep5: 0,
        step: 5,
        step5Validate: false,
        step5Data:{
            prvDoc: [],
            recComment: '',
        }  
    },
    



}, action) => {
    switch (action.type) {
        case SETID:
            return{...state, requestId:action.payload.requestId }

        case INCREMENT_COUNTER:
            return { ...state, value: state.value + 1 };
        case DECREMENT_COUNTER:
            return { ...state, value: state.value - 1 };
        case STEP1:
            return {
                ...state, master:action.payload.step,currentValidate:false,step1: {
                    taskIstanceIdStep1: action.payload.taskIstanceIdStep1,
                    processIstanceIdStep1: action.payload.processIstanceIdStep1,
                    step: action.payload.step,
                    step1Validate: action.payload.step1Validate,
                    step1Data:{
                        prvDoc: action.payload.step1Data.prvDoc,
                        recallDescription:action.payload.step1Data.recallDescription,
                        recallTitle:action.payload.step1Data.recallTitle,
                        sorDescription:action.payload.step1Data.sorDescription,
                    } 
                } 
            };
            case STEP2:
                return {
                    ...state,master:action.payload.step,currentValidate:false, step2: {
                        taskIstanceIdStep2: action.payload.taskIstanceIdStep2,
                        processIstanceIdStep2: action.payload.processIstanceIdStep2,
                        step: action.payload.step,
                        step2Validate: true,
                        step2Data:{
                            prvDoc: action.payload.step2Data.prvDoc,
                            recallCccDescription:action.payload.step2Data.recallCccDescription, 
                        } 
                    }
                };
                case STEP3:
                    return {
                        ...state,master:action.payload.step,currentValidate:false, step3: {
                            taskIstanceIdStep3: action.payload.taskIstanceIdStep3,
                            processIstanceIdStep3: action.payload.processIstanceIdStep3,
                            step: action.payload.step,
                            step3Validate: true,
                            step3Data:{
                                prvDoc: action.payload.step3Data.prvDoc,
                                recallTecDescription:action.payload.step3Data.recallTecDescription, 
                            } 
                        }
                    };
                    case STEP4:
                        return {
                            ...state,master:action.payload.step,currentValidate:false, step4: {
                                taskIstanceIdStep4: action.payload.taskIstanceIdStep4,
                                processIstanceIdStep4: action.payload.processIstanceIdStep4,
                                step: action.payload.step,
                                step4Validate: true,
                                step4Data:{
                                    prvDoc: action.payload.step4Data.prvDoc,
                                    contentBase64:action.payload.step4Data.contentBase64,
                                    recallDecision:action.payload.step4Data.recallDecision, 
                                    recallNote: action.payload.step4Data.recallNote,

                                   
                            }
                        }
                        };
                        case STEP5:
                            return {
                                ...state,master:action.payload.step,currentValidate:false, step5: {
                                    taskIstanceIdStep5: action.payload.taskIstanceIdStep5,
                                    processIstanceIdStep5: action.payload.processIstanceIdStep5,
                                    step: action.payload.step,
                                    step5Validate: true,
                                    step5Data:{
                                        prvDoc: action.payload.step5Data.prvDoc,
                                        recComment:action.payload.step5Data.recComment, 
                                    } 
                                }
                            };
                            case OFFBOAED:
                                return {
                                    ...state,dataArray:action.payload.dataArray,
                                };
                            case REJECTLIST:
                                return {
                                    ...state,rejectArray:action.payload.dataArray,
                                };
                            case BOXCOUNTER:
                                return {
                                    ...state,countData:action.payload.dataArray,
                                };
                            case MANAGERCOUNTER:
                                return {
                                    ...state,countDataManager:action.payload.dataArray,
                                };

        default:
            return { ...state };
    }
};

export default counterReducer;
