/* eslint-disable react/self-closing-comp */
/* eslint-disable vars-on-top */
/* eslint-disable eqeqeq */
/* eslint-disable prefer-const */
/* eslint-disable no-var */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-use-before-define */
/* eslint-disable array-callback-return */
/* eslint-disable camelcase */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import {
  Modal,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Form,
  Box,
  TextField,
  Grid,
  FormGroup,
  IconButton,
} from "@material-ui/core";
import WarningIcon from "@mui/icons-material/Warning";
import FormLabel from "@mui/material/FormLabel";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
// for print user in consol
import jwt_decode from "jwt-decode";
import { useSelector } from "react-redux";
// for print user in consol
import axios from "axios";

import clsx from "clsx";
// import $ from 'jquery';
import { useHistory } from "react-router-dom";
import Table from "../../components/Table/Table";
import styles from "./BOM_PMT.module.css";
import { CustomFormGroup, CustomSelect, ValidatingTextField } from "../../components/FormComponents";
import BackButton from "../../components/BackButton/BackButton";
import {
  DATE_FORMAT,
  API_RESOURCE_URLS,
  DISPLAY_MESSAGES,
  MESSAGE_TYPE,
  MESSAGE_TYPE_CERTIFICATION,
  MESSAGE_TYPE_ANNEXURE,
  RESOURCE_TYPE,
  USER_OPERATIONS,
  Regex,
} from "../../constants";
import { withAllowedOperationsProvider } from "../../hocs";
import { CustomTab, Button, AuthChecker } from "../../atomicComponents";
import { API } from "../../apis/api";
import { buildErrorMessage } from "../../apis/calls";
import { usePopupManager } from "../../providers/PopupManager/PopupManager";

import StepBar1 from "../../components/StepBar1/StepBar";
import { GridLoadingSpinner } from "../../components";

const VendarData = () => {
  const history = useHistory();
  const { showPopup } = usePopupManager();
  // const baseUrl='http://127.0.0.1:8000/file/'
  const baseUrl = useSelector((state) => state.counter.baseUrl); // useSelector
  const auth = useSelector((state) => state.auth); // useSelector
  // console.log("auth in Supplier: ", auth);
  const { userRole, userName } = auth
  // console.log('userRole: ', userRole, auth);
  const [dynamicColumns, setDynamicColumns] = useState([]);
  const [dynamicColumns1, setDynamicColumns1] = useState([]);
  const [plant, setPlant] = useState(null);
  const [flag, show] = useState(false);
  const [checkDisable, setDisable] = useState(true);
  const [projectCode, setProjectCode] = useState(null);
  const [projectCodeArray, setProjectCodeArray] = useState([]);
  const [isLatestProjectSelected, setIsLatestProjectSelected] = useState(false); // New state for button enable/disable
  // const [projectCodeArray, setProjectCodeArray] = useState([]);
  const [selectedProjectCode, setSelectedProjectCode] = useState(null);
  // console.log("Project state: ", selectedProjectCode);
  const [latestProject, setLatestProject] = useState("");
  const [Bc, setBC] = useState(null);
  const [bcArray, setBcArray] = useState([]);
  const [plantArray, setPlantArray] = useState([]);
  const [tableArray, setTableArray] = useState([]);
  const [fyArray, setFyArray] = useState([]);
  const [Fy, setFY] = useState(null);
  const [quarterArray, setQuarterArray] = useState([]);
  const [quarter, setQuarter] = useState(null);
  const [asnCount, setAsnCount] = useState(0);
  const [loading, setLoader] = useState(false);
  const [loader, setLoading] = useState(true);
  const [vendCode, setvendCode] = useState();
  const [datesData, setDates] = useState({
    endDate: "",
    startDate: "",
  })
  const [projectDescription, setProjectDescription] = useState('')
  const [vcStatus, setVcStatus] = useState()

  //
  const [isPopupOpenFormModal, setPopupOpenFormModal] = useState(false);
  const handleOpenFormModal = () => {
    setPopupOpenFormModal(true);
  };
  const handleCloseFormModal = () => setPopupOpenFormModal(false);

  //

  const ROW_HEIGHT = 38;

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const handleOpen = () => setIsPopupOpen(true);
  const handleClose = () => setIsPopupOpen(false);
  const goNext = () => {
    uploadFileData();
  };

  const redirectToApplicationLandingPage = () => {
    history.push("/");
  };

  const setCount = (count) => {
    setAsnCount(count);
  };

  useEffect(() => {
    // getdate();
    getRecentProjectCode();
  }, [])

  useEffect(() => {
    if (selectedProjectCode) {
      getProjectDescription()
    }
  }, [selectedProjectCode]);

  useEffect(() => {
    getAllProjectCode();
  }, []);

  const isPMTUser = userRole.includes('PMT')
  useEffect(() => {
    if (selectedProjectCode) {
      // getVcForProjectCode(selectedProjectCode);
    }
  }, [selectedProjectCode]);

  /* 
    false = disable
    true = enable
  */
  function submitButtonFlag() {
    let buttonFlag
    if (vcStatus?.PartSelection_status === 'Submitted' 
      // && isLatestProjectSelected
    ) {
      buttonFlag = false
    }
    else if (vcStatus?.PartSelection_status === 'Submitted' 
      // && !isLatestProjectSelected
    ) {
      buttonFlag = false
    }
    else if (vcStatus?.PartSelection_status === 'Not submitted' 
      // && isLatestProjectSelected
    ) {
      buttonFlag = true
    }
    else if (vcStatus?.PartSelection_status == 'Not submitted'
      //  && !isLatestProjectSelected
      ) {
      buttonFlag = false
    }
    return buttonFlag
  }

  console.log('submitButtonFlag: ', submitButtonFlag())
  console.log('submitButtonFlag assad: ', vcStatus?.PartSelection_status)

  const handleDropdownChange = (selectedOption, name) => {
    // console.log('selectedOption: ', selectedOption)
    if (name === 'Project_Code') {
      setSelectedProjectCode(selectedOption)
      getProjectDescription()
      // if (selectedProjectCode !== null) {
      //   getVcForProjectCode(selectedOption)
      // }
    }
    // else if (name === 'projDesc') {
    //   console.log('inside projDesc');
      
    //   setSelectedProjectCode(selectedOption)
    //   getProjectDescription(selectedOption)
    // }
  }
    // Fetch project description based on project code
    const getProjectDescription = async () => {
      try {
        const headerss = { 'Content-Type': 'application/json' }
        const body = { Project_code: selectedProjectCode?.value }
        const { data } = await axios.post(
          `${baseUrl}ProjectDescriptionView/`,
          body,
          { headers: headerss }
        )
        // console.log('pro desc: ', data)
        setProjectDescription(data?.Project_Description)
      } catch (error) {
        console.error('Error fetching project description:', error)
      }
    }

  // const getdate = () => {
  //   const headerss = new Headers();
  //   headerss.append("Content-Type", "application/json");
  //   fetch(`${baseUrl}Milestone_calendarView/`, {
  //     method: "GET",
  //     headers: headerss,
  //   })
  //     .then((response) => response.json())
  //     .then((responseJson) => {
  //       const endDate = responseJson[0].Collect_DVA_from_vendors_EndDate;
  //       const startDate = responseJson[0].Collect_DVA_from_vendors_StartDate;
  //       setDates((prevData) => ({ ...prevData, endDate, startDate }));
  //     })
  //     .catch((error) => {});
  // };

  const showTable = () => {
    console.log("ProjectCode: ", projectCode);
    console.log("Project value: ", projectCode?.value);
    // if(projectCode === null || projectCode.value === ''){
    if (
      selectedProjectCode === null ||
      selectedProjectCode.value === "" ||
      selectedProjectCode === null
    ) {
      showPopup({
        type: MESSAGE_TYPE.INFORMATION,
        contextText: MESSAGE_TYPE.INFORMATION,
        info: "Project Code is Mandatory",
      });
    } else {
      getTableData()
      getPMTBOMStatusForMIS()
      show(true)
    }
  }

  const getPMTBOMStatusForMIS = async () => {
    const body = {
      Project_code: selectedProjectCode?.value,
      VC: Bc?.value,
    }
    try {
      const response = await axios.post(
        `${baseUrl}PMTBOMStatusForMIS/`,
        body,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      console.log("API Response:", response.data)
      if (response.data.error) {
        // alert(`API Error: ${response.data.error}`)
        showPopup({
          type: MESSAGE_TYPE.FAILURE,
          contextText: MESSAGE_TYPE.FAILURE,
          info: response?.data?.error,
        })
        setLoading(false);
      } else if (Array.isArray(response.data)) {
        setVcStatus(response?.data[0])
      } else {
        // showPopup({
        //   type: MESSAGE_TYPE.INFORMATION,
        //   contextText: MESSAGE_TYPE.INFORMATION,
        //   info: "Unexpected API response format.",
        // })
        // setLoading(false)
      }
    } catch (error) {
      // showPopup({
      //   type: MESSAGE_TYPE.INFORMATION,
      //   contextText: MESSAGE_TYPE.INFORMATION,
      //   info: "Error fetching data, please try again.",
      // })
      // setLoading(false)
    }
  }

  // let Vendor_Code ='';
  // const handleSelect = (selectedOption, name) => {
  //   // alert(selectedOption.value);
  //   // console.log(selectedOption);
  //   setvendCode(selectedOption.value);
  //   if (name == "VC") {
  //     show(false);

  //     setBC(selectedOption);
  //     // getPlantData(selectedOption)
  //   } else {
  //     setPlant(selectedOption);
  //   }
  // };

  // const getTableData = async () => {
  //   console.log("Selected Project Code:", selectedProjectCode?.value);
  //   console.log("Selected VC:", Bc?.value);

  //   const apiFormattedData = {
  //     Project_code: selectedProjectCode?.value,
  //     // VC: Bc?.value,
  //   };

  //   const apiFormattedDataJson = JSON.stringify(apiFormattedData);
  //   console.log("Formatted API Body:", apiFormattedDataJson);

  //   setLoader(true)
  //   try {
  //     const response = await axios.post(
  //       // `${baseUrl}PMTBOMData/`,
  //       `${baseUrl}PMTBOMDataShow/`,
  //       // `${baseUrl}ShowMasterDataofPCandVC/`,
  //       apiFormattedData,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     );

  //     console.log("API Response:", response.data);

  //     if (response.data.error) {
  //       // alert(`API Error: ${response.data.error}`)
  //       setLoader(false)
  //       showPopup({
  //         type: MESSAGE_TYPE.FAILURE,
  //         contextText: MESSAGE_TYPE.FAILURE,
  //         info: `API Error: ${response.data.error}`,
  //       });
  //       setLoading(false);
  //     } else if (Array.isArray(response.data)) {
  //       setLoader(false)
  //       // If the response is an array, set it directly
  //       console.log("Table Data:", response.data);
  //       const arr = response.data.map((item) => ({
  //         ...item,
  //         // Send_to_vendor: item?.Send_to_vendor === true ? "True" : "False",
  //         // is_deleted: item?.is_deleted === true ? "True" : "False",
  //         // AppliedForPLI: item?.AppliedForPLI === true ? "True" : "False",
  //         // Supplier_pli: item?.Supplier_pli === true ? "True" : "False",
  //       }));
  //       setTableArray(arr);
  //       setLoading(false);
  //     } else {
  //       // Unexpected format
  //       // alert("Unexpected API response format.");
  //       setLoader(false)
  //       showPopup({
  //         type: MESSAGE_TYPE.FAILURE,
  //         contextText: MESSAGE_TYPE.FAILURE,
  //         // info: "No data available",
  //         info: "Unexpected API response format.",
  //       });
  //       console.log("Unexpected API response format:", response.data);
  //       setLoading(false);
  //     }
  //   } catch (error) {
  //     console.error("Fetch error:", error);
  //     // alert("Error fetching data, please try again.");
  //     setLoader(false)
  //     showPopup({
  //       type: MESSAGE_TYPE.FAILURE,
  //       contextText: MESSAGE_TYPE.FAILURE,
  //       // info: "No data available",
  //       info: "Error fetching data, please try again.",
  //     });
  //     setLoading(false);
  //   }
  // };

  const getTableData = async () => {
    console.log("Selected Project Code:", selectedProjectCode?.value);
    console.log("Selected VC:", Bc?.value);
  
    const apiFormattedData = {
      Project_code: selectedProjectCode?.value,
      // VC: Bc?.value,
    };
  
    const apiFormattedDataJson = JSON.stringify(apiFormattedData);
    console.log("Formatted API Body:", apiFormattedDataJson);
  
    setLoader(true);
    try {
      const response = await axios.post(
        `${baseUrl}PMTBOMDataShow/`,
        apiFormattedData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
  
      console.log("API Response:", response.data);
  
      if (response.data.error) {
        setLoader(false);
        showPopup({
          type: MESSAGE_TYPE.FAILURE,
          contextText: MESSAGE_TYPE.FAILURE,
          info: `API Error: ${response.data.error}`,
        });
        setLoading(false);
      } else if (Array.isArray(response.data)) {
        setLoader(false);
        console.log("Table Data:", response.data);
        const arr = response.data.map((item) => ({
          ...item,
          Grn_sob: item?.Grn_sob ? parseFloat(item.Grn_sob).toFixed(2) : item.Grn_sob,
          Bom_sob: item?.Bom_sob ? parseFloat(item.Bom_sob).toFixed(2) : item.Bom_sob,
        }));
        setTableArray(arr);
        setLoading(false);
      } else {
        setLoader(false);
        showPopup({
          type: MESSAGE_TYPE.FAILURE,
          contextText: MESSAGE_TYPE.FAILURE,
          info: "Unexpected API response format.",
        });
        console.log("Unexpected API response format:", response.data);
        setLoading(false);
      }
    } catch (error) {
      console.error("Fetch error:", error);
      setLoader(false);
      showPopup({
        type: MESSAGE_TYPE.FAILURE,
        contextText: MESSAGE_TYPE.FAILURE,
        info: "Error fetching data, please try again.",
      });
      setLoading(false);
    }
  };
  
  



  // const getAllData = () => {
  //   let headerss = new Headers();
  //   headerss.append("Content-Type", "application/json");
    
  //   // fetch(`${baseUrl}GetAllMasterData/`, {
  //   fetch(`${baseUrl}GetAllPMTData/`, {
  //     method: "GET",
  //     headers: headerss,
  //   })
  //     .then((response) => response.json())
  //     .then((responseJson) => {
  //       if (responseJson === null || responseJson.length === 0) {
  //         showPopup({
  //           type: MESSAGE_TYPE.FAILURE,
  //           contextText: MESSAGE_TYPE.FAILURE,
  //           info: "No data available",
  //         });
  //       } else {
  //         const transformedData = responseJson.map((item) => ({
  //           ...item,
  //           // Send_to_vendor: item?.Send_to_vendor === true ? "True" : "False",
  //           // is_deleted: item?.is_deleted === true ? "True" : "False",
  //           // AppliedForPLI: item?.AppliedForPLI === true ? "True" : "False",
  //           // Supplier_pli: item?.Supplier_pli === true ? "True" : "False",
  //         }));
  
  //         setTableArray(transformedData);
  //         show(true);
  //         setLoading(false);
  //       }
  //     })
  //     .catch((error) => {
  //       console.log("error", error);
  //       showPopup({
  //         type: MESSAGE_TYPE.FAILURE,
  //         contextText: MESSAGE_TYPE.FAILURE,
  //         info: "Error while fetching data.",
  //       });
  //     });
  // };
  


  const getAllProjectCode = async () => {
    try {
      // const { data } = await axios.get(`${baseUrl}AllProjectCode/`);
      const { data } = await axios.get(`${baseUrl}AllProjectCodeforPMT/`);
      const allProjectCodes = data.map(({ Project_code }) => Project_code);
      const uniqueProjects = [...new Set(allProjectCodes)];
      const projectCodeOptions = uniqueProjects.map((value) => ({
        value,
        label: value,
      }));

      setProjectCodeArray(projectCodeOptions);
      // await getRecentProjectCode(projectCodeOptions);
    } catch (error) {
      // console.error("Error fetching project codes:", error);
      showPopup({
        type: MESSAGE_TYPE.FAILURE,
        contextText: MESSAGE_TYPE.FAILURE,
        info: "Error while fetching data",
      });
    }
  };

  const getRecentProjectCode = async (projectCodeOptions) => {
    try {
      // const { data, status } = await axios.get(`${baseUrl}LatestProjectCode/`);
      const { data, status } = await axios.get(`${baseUrl}LatestProjectCodeforBOE/`);
      if (status !== 200) {
        throw new Error("Failed to fetch the latest project code");
      }
      const recentProj = {
        label: data?.Project_code,
        value: data?.Project_code,
      };
      setLatestProject(recentProj);
      setSelectedProjectCode(recentProj); // Automatically select the latest project code
    } catch (error) {
      console.error("Error fetching recent project codes:", error);
    }
  };

  // useEffect(() => {
  //   getAllProjectCode();
  // }, []);

  useEffect(() => {
    if (latestProject) {
      setSelectedProjectCode(latestProject);
    }
  }, [latestProject]);

  // Check if the selected project code is the latest project code
  useEffect(() => {
    if (selectedProjectCode && latestProject) {
      setIsLatestProjectSelected(selectedProjectCode.value === latestProject.value);
    }
  }, [selectedProjectCode, latestProject]);

  // const getVcForProjectCode = (selectedCode) => {
  //   let headerss = new Headers();
  //   headerss.append("Content-Type", "application/json");

  //   fetch(`${baseUrl}VCForProjectCode/`, {
  //     method: "POST",
  //     headers: headerss,
  //     body: JSON.stringify({ Project_code: selectedCode?.value }),
  //   })
  //     .then((response) => response.json())
  //     .then((responseJson) => {
  //       const uniqueVc = [
  //         ...new Set(
  //           responseJson
  //             .filter((item) => item.VC !== null)
  //             .map((item) => item.VC)
  //         ),
  //       ];
  //       setBcArray(uniqueVc.map((value) => ({ value, label: value })));

  //       const { userRole, userID } = auth;

  //       if (userRole.includes("Vendor-CV") || userRole.includes("Vendor")) {
  //         const singleVendor = uniqueVc.find((item) => item === userID);
  //         if (singleVendor) {
  //           setBC({ value: singleVendor, label: singleVendor });
  //           setDisable(true);
  //         }
  //       } else {
  //         setDisable(false);
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching VC:", error);
  //     });
  // };

  const uploadFileData = () => {
    var input = document.createElement("input");
    input.type = "file";
    input.id = "file";
    input.setAttribute(
      "accept",
      ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
    );
    input.click();
    input.onchange = (e) => {
      setLoader(true);
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      fetch(`${baseUrl}SupplierDataaddition/`, {
        method: "POST",
        body: formData,
      })
        .then((response) => {
          // eslint-disable-next-line radix
          let status1 = parseInt(response.status);
          if (status1 == "201") {
            //   document.removeChild(input);
            getTableData();
            showPopup({
              type: MESSAGE_TYPE.SUCCESS,
              contextText: MESSAGE_TYPE.SUCCESS,
              info: "file uploaded sucessfully",
            });
          } else {
            showPopup({
              type: MESSAGE_TYPE.SUCCESS,
              contextText: MESSAGE_TYPE.SUCCESS,
              info: "file uploaded failed",
            });
          }
        })

        .catch((error) => {});
    };
  };

  const exportExcel = async (excelData) => {
    setLoader(true)
    console.log('inside excel: ', excelData);
    const newExcelData = excelData?.map(
      (
        {
          Project_code,
          Project_type,
          Project_type_details,
          Financial_year,
          Quarter,
          VC,
          VC_description,
          Plant_code,
          Po_Plant,
          Supplier_code,
          Supplier_name,
          Part_no,
          Part_description,
          Run_version,
          Operation,
          Bom_item_no,
          // Availing_PLI_Incentive_Yes_No,
          Supplier_pli,
          Part_uom,
          Assembly,
          Sr_no,

          Group_material,
          Group_material_quantity,
          Reference_material,
          Followup_material,
          Cost_run_date,
          Bom_quantity,
          Bom_uom,

          /* Editing as said by Pritish on 03-10-2024 */
          // Total_cost,
          BOM_price,

          Grn_sob,
          Bom_sob,
          Gr_no,
          Gr_date,
          Gr_entry_date,
          Total_gr_quantity,
          Final_quantity,
          Percentage_check,
          Po_no,
          Po_type,
          Purchase_group,
          Import_domestic,
          Contract_no,
          Contract_Price,
          Invoice_no_r,
          Invoice_date_r,
          Invoice_uom_r,
          Invoive_no_s,
          Supplier_Credit_Invoices,
          Supplier_Debit_Invoices,
          Invoice_date_s,
          Invoice_uom_s,
          Sap_invoice_quantity,
          Net_price,
          Supplementary_price,
          Gross_price,
          Total_net_price,
          Total_gross_price,
          HSN_code,
          Currency,
          PAN,
          Exchange_rate,
          Exchange_rate_date,
          Irn_no,
          // Deleted,
          // Deletion_remark,
          Addition_remark,
          System_Remark,
          PL_Remark,
          Total_Vendor_Price,
          Import_Content,
          Local_Content,
          Last_Declared_Date,
          Ca_Sa,
          Send_To_DVA_Collection,
          Send_To_PLI_Declaration,
          Created_date
        },
        id
      ) => {
        let obj = {
          id,
          Project_code,
          Project_type,
          Project_type_details,
          Financial_year,
          Quarter,
          VC,
          VC_description,
          Plant_code,
          Po_Plant,
          Supplier_code,
          Supplier_name,
          Part_no,
          Part_description,
          Run_version,
          Operation,
          Bom_item_no,
          // Availing_PLI_Incentive_Yes_No,
          Supplier_pli,
          Part_uom,
          Assembly,
          Sr_no,

          Group_material,
          Group_material_quantity,
          Reference_material,
          Followup_material,
          Cost_run_date,
          Bom_quantity,
          Bom_uom,

          /* Editing as said by Pritish on 03-10-2024 */
          // Total_cost,
          BOM_price,

          Grn_sob,
          Bom_sob,
          Gr_no,
          Gr_date,
          Gr_entry_date,
          Total_gr_quantity,
          Final_quantity,
          Percentage_check,
          Po_no,
          Po_type,
          Purchase_group,
          Import_domestic,
          Contract_no,
          Contract_Price,
          Invoice_no_r,
          Invoice_date_r,
          Invoice_uom_r,
          Invoive_no_s,
          Supplier_Credit_Invoices,
          Supplier_Debit_Invoices,
          Invoice_date_s,
          Invoice_uom_s,
          Sap_invoice_quantity,
          Net_price,
          Supplementary_price,
          Gross_price,
          Total_net_price,
          Total_gross_price,
          HSN_code,
          Currency,
          PAN,
          Exchange_rate,
          Exchange_rate_date,
          Irn_no,
          // Deleted,
          // Deletion_remark,
          Addition_remark,
          System_Remark,
          PL_Remark,
          Total_Vendor_Price,
          Import_Content,
          Local_Content,
          Last_Declared_Date,
          Ca_Sa,
          Send_To_DVA_Collection,
          Send_To_PLI_Declaration,
          Created_date
        }
        return obj
      }
    )
    var fileTypel =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    var fileExt = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(newExcelData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { booktype: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileTypel })
    setLoader(false)
    FileSaver.saveAs(data, `BOM_PMT_Data${fileExt}`);
  }

  const getAllQRRDataAndExportExcel = async () => {
    setLoader(true)
    try {
      const response = await axios.get(`${baseUrl}GetAllQRRData/`, {
        headers: { 'Content-Type': 'application/json' }
      })
      // if (response.data.error) {
      //   showPopup({
      //     type: MESSAGE_TYPE.FAILURE,
      //     contextText: MESSAGE_TYPE.FAILURE,
      //     info: 'No data available'
      //   })
      // }

      console.log('QRR response: ', response?.data)
      if(response?.status === 200 || response?.status === 201){
        setLoader(false)
        const excelData = response?.data?.map(
          (
            {
              id,
              Plant_code,
              Po_Plant,
              Part_no,
              Part_description,
              Supplier_code,
              Supplier_name,
              Total_Vendor_Price,
              Import_Content,
              Local_Content,
              Last_Declared_Date,
              // VC,
              // VC_description,
              Ca_Sa,
              // Ca_Sa_Date
            },
            // id
          ) => {
            let obj = {
              id,
              Plant_code,
              Po_Plant,
              Part_no,
              Part_description,
              Supplier_code,
              Supplier_name,
              Total_Vendor_Price,
              Import_Content,
              Local_Content,
              Last_Declared_Date,
              // VC,
              // VC_description,
              Ca_Sa,
              // Ca_Sa_Date
            }
            return obj
          }
        )
        var fileTypel =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"
        var fileExt = ".xlsx"
        const ws = XLSX.utils.json_to_sheet(excelData)
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] }
        const excelBuffer = XLSX.write(wb, { booktype: "xlsx", type: "array" })
        const data = new Blob([excelBuffer], { type: fileTypel })
        FileSaver.saveAs(data, `Reference_Data${fileExt}`)
      }
    
    } catch (error) {
      console.error('Fetch error:', error)
      setLoader(false)
      showPopup({
        type: MESSAGE_TYPE.FAILURE,
        contextText: MESSAGE_TYPE.FAILURE,
        info: 'Error while fetching data.'
      })
    }
  }

  const baseDefaultColumnsGDC = [
    {
      width: 130,
      title: "Project Code",
      field: "Project_code",
      enableSearch: true,
      enableFilter: true,
    },
  ];

  const baseDefaultColumns = [
    {
      width: 130,
      title: "Project Code",
      field: "Project_code",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 130,
      title: "Project Type",
      field: "Project_type",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 170,
      title: "Project Type Details",
      // field: "Project_Type_Details",
      field: "Project_type_details",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 130,
      title: "Financial Year",
      field: "Financial_year",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 130,
      title: "Quarter",
      field: "Quarter",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 130,
      title: "VC",
      field: "VC",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 130,
      title: "VC Description",
      field: "VC_description",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 130,
      title: "Plant Code",
      field: "Plant_code",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 130,
      title: "PO Plant",
      field: "Po_Plant",
      enableSearch: true,
      enableFilter: true,
    },

    {
      width: 130,
      title: "Supplier Code",
      // field: "Supplier_Code",
      field: "Supplier_code",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 180,
      title: "Supplier Name",
      // field: "Supplier_Name",
      field: "Supplier_name",
      enableSearch: true,
      enableFilter: true,
    },

    {
      width: 150,
      title: "Part Number",
      // field: "Part_number",
      field: "Part_no",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 180,
      title: "Part Description",
      // field: "Part_Description",
      field: "Part_description",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 180,
      title: "Run Version",
      // field: "Part_Description",
      field: "Run_version",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 180,
      title: "Operation",
      // field: "Part_Description",
      field: "Operation",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 160,
      title: "Bom Item No.",
      // field: "Part_Description",
      field: "Bom_item_no",
      enableSearch: true,
      enableFilter: true,
    },

    {
      width: 160,
      // title: "Applied For PLI(Y/N)",
      title: "Supplier Under PLI(Y/N)",
      // field: "AppliedForPLI",
      field: "Supplier_pli",
      // field: "Availing_PLI_Incentive_Yes_No",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 120,
      title: "Part UOM",
      field: "Part_uom",
      enableSearch: true,
      enableFilter: true,
    },
    // {
    //   width: 100,
    //   title: "Part UOM",
    //   field: "Part_UOM",
    //   enableSearch: true,
    //   enableFilter: true,
    // },

    /*  */
    {
      width: 120,
      title: "Assembly",
      field: "Assembly",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 120,
      title: "Sr. No.",
      field: "Sr_no",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 100,
      title: "Group Material",
      // field: "Group_Material",
      field: "Group_material",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 100,
      title: "Group Material Quantity",
      // field: "Group_Material_Quantity",
      field: "Group_material_quantity",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 100,
      title: "Reference Material",
      field: "Reference_material",
      enableSearch: true,
      enableFilter: true,
    },
    // {
    //   width: 100,
    //   title: "Reference",
    //   field: "Reference",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    {
      width: 100,
      title: "Follow up Material",
      field: "Followup_material",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 100,
      title: "Cost Run Date",
      // field: "Cost_Run_Date",
      field: "Cost_run_date",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 100,
      title: "BOM Quantity",
      // field: "BOM_Quantity",
      field: "Bom_quantity",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 100,
      title: "BOM UOM",
      // field: "BOM_UOM",
      field: "Bom_uom",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 100,
      // title: "Total Cost",
      // field: "Total_Cost",
      // field: "Total_cost",
      /* Editing as said by Pritish on 03-10-2024 */
      title: "BOM Price",
      field: "BOM_price",
      enableSearch: true,
      enableFilter: true,
    },

    // {
    //   width: 100,
    //   title: "Part UOM",
    //   field: "Part_UOM",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    /*  */

    // {
    //   width: 130,
    //   title: "GRN UOM",
    //   field: "GRN_UOM",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    {
      width: 130,
      title: "GRN SOB",
      field: "Grn_sob",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 130,
      title: "BOM SOB",
      field: "Bom_sob",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 130,
      title: "GR No",
      // field: "GR_No",
      field: "Gr_no",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 130,
      title: "GR Date",
      // field: "GR_date",
      field: "Gr_date",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 130,
      title: "GR Entry Date",
      // field: "GR_Entry_Date",
      field: "Gr_entry_date",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 130,
      title: "Total GR Quantity",
      field: "Total_gr_quantity",
      enableSearch: true,
      enableFilter: true,
    },
    // {
    //   width: 130,
    //   // title: "SOB",
    //   title: "GR SOB",
    //   field: "GR_SOB",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    {
      width: 130,
      title: "Final Quantity",
      // field: "Final_Quantity",
      field: "Final_quantity",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 130,
      title: "Percentage Check",
      field: "Percentage_check",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 130,
      title: "PO No.",
      // field: "PO_No",
      field: "Po_no",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 130,
      title: "PO Type",
      // field: "PO_Type",
      field: "Po_type",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 130,
      title: "Purchase Group",
      field: "Purchase_group",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 130,
      title: "Import/Domestic",
      // field: "ImportOrDomestic",
      field: "Import_domestic",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 130,
      title: "Contract No.",
      // field: "Contact_No",
      field: "Contract_no",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 130,
      title: "Contract Price",
      field: "Contract_Price",
      enableSearch: true,
      enableFilter: true,
    },
    // {
    //   width: 150,
    //   title: "Invoice No",
    //   field: "Invoice_No",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    {
      width: 150,
      title: "Invoice No(R)",
      field: "Invoice_no_r",
      enableSearch: true,
      enableFilter: true,
    },
    // {
    //   width: 150,
    //   title: "Invoice Date(s)",
    //   field: "Invoice_Date",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    {
      width: 150,
      title: "Invoice Date(R)",
      field: "Invoice_date_r",
      enableSearch: true,
      enableFilter: true,
    },
    // {
    //   width: 130,
    //   title: "Invoice UOM",
    //   field: "Invoice_UOM",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    {
      width: 130,
      title: "Invoice UOM(R)",
      field: "Invoice_uom_r",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 130,
      title: "Invoice N(S)",
      field: "Invoive_no_s",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 130,
      title: "Supplier Credit Invoices",
      field: "Supplier_Credit_Invoices",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 130,
      title: "Supplier Debit Invoices",
      field: "Supplier_Debit_Invoices",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 130,
      title: "Invoice Date(S)",
      field: "Invoice_date_s",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 130,
      title: "Invoice UOM(S)",
      field: "Invoice_uom_s",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 130,
      title: "SAP Invoice Quantity",
      field: "Sap_invoice_quantity",
      enableSearch: true,
      enableFilter: true,
    },

    // {
    //   width: 150,
    //   title: "Supplementary Invoice No",
    //   field: "Supplementary_Invoice_No",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 150,
    //   title: "Supplementary Invoice Date",
    //   field: "Supplementary_Invoice_Date",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 150,
    //   title: "Invoice UOM(s)",
    //   field: "Invoice_UOM_S",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    {
      width: 150,
      // title: "Price/Part Regular A",
      title: "Net Price Per Part",
      // field: "Price_Per_Part_Regular_A",
      // field: "Net_Price_Per_Part",
      field: "Net_price",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 150,
      // title: "Price/Part Supplementary B",
      // title: "Supplementary Price Per Part",
      title: "Supplementary Price",
      // field: "Price_Per_Part_Supplementary_B",
      // field: "Supplementary_Price_Per_Part",
      field: "Supplementary_price",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 150,
      // title: "Total Part Price C",
      // title: "Gross Price Per Part",
      title: "Gross Price",
      // field: "Total_Part_Price_C",
      // field: "Gross_price_Per_Part",
      field: "Gross_price",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 100,
      // title: "Total Net Part Price",
      title: "Total Net Price",
      // field: "Total_Net_Part_Price",
      field: "Total_net_price",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 100,
      // title: "Total Gross Part Price",
      title: "Total Gross Price",
      // field: "Total_Gross_Part_Price",
      field: "Total_gross_price",
      enableSearch: true,
      enableFilter: true,
    },
    /* SPOC not present in response */
    // {
    //   width: 100,
    //   title: "SPOC",
    //   field: "SPOC",
    //   enableSearch: true,
    //   enableFilter: true,
    // },

    /* CTL not present in response */
    // {
    //   width: 100,
    //   title: "CTL",
    //   field: "CTL",
    //   enableSearch: true,
    //   enableFilter: true,
    // },

    {
      width: 100,
      title: "HSN Code",
      // field: "HSN_CODE",
      field: "HSN_code",
      enableSearch: true,
      enableFilter: true,
    },
    
    {
      width: 100,
      title: "Billing Currency",
      // field: "Billing_Currency",
      field: "Currency",
      enableSearch: true,
      enableFilter: true,
    },
    // {
    //   width: 100,
    //   title: "Supplier Plant Location Address",
    //   field: "Supplier_Plant_location_Address",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 100,
    //   title: "IRN Number",
    //   field: "IRN_Number",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 100,
    //   title: "GSTIN",
    //   field: "GSTIN",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    {
      width: 100,
      title: "PAN",
      // field: "PAN_number",
      field: "PAN",
      enableSearch: true,
      enableFilter: true,
    },
    // {
    //   width: 100,
    //   title: "Part Quantity",
    //   field: "Part_Qty",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    /* Commeting BOE section */
    // {
    //   width: 100,
    //   title: "BOE Number",
    //   field: "BOE_Number",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 100,
    //   title: "BOE Date",
    //   field: "BOE_Date",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 100,
    //   title: "CIF Value",
    //   field: "CIF_Value",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 100,
    //   title: "BOE Part Quantity",
    //   field: "BOE_Part_Qty",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 100,
    //   title: "BOE IEC Of TML",
    //   field: "BOE_IEC_Of_TML",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 100,
    //   title: "Availing PLI Incentive",
    //   field: "Availing_PLI_Incentive",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 100,
    //   title: "TA Certified DVA Percentage",
    //   field: "TA_certified_DVA_percentage",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 100,
    //   title: "PLI Claim Effective Date",
    //   field: "PLI_claim_effective_date",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 100,
    //   title: "Invoice Selling Price",
    //   field: "Invoice_Selling_price",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 100,
    //   title: "Value Of Direct Import By Us",
    //   field: "Value_of_Direct_Import_by_us",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 100,
    //   title: "Broad Description of parts imported by us",
    //   field: "Broad_Description_of_parts_imported_by_us",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 100,
    //   title: "Value of parts imported by our suppliers",
    //   field: "Value_of_parts_imported_by_our_suppliers",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 100,
    //   title: "Broad Description of parts imported by our suppliers",
    //   field: "Broad_Description_of_parts_imported_by_our_suppliers",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 100,
    //   title: "Local Content",
    //   field: "Local_Content",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 100,
    //   title: "DVA Percentage",
    //   field: "DVAPercentage",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 100,
    //   title: "Currency Name",
    //   field: "Currency_Name",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 100,
    //   title: "Reference Date",
    //   field: "Reference_Date",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    {
      width: 100,
      title: "Foreign Exchange Rate",
      // field: 'Foreign_Exchange_Rate',
      field: 'Exchange_rate',
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 100,
      title: "Foreign Exchange Rate Date",
      // field: 'Foreign_Exchange_Rate',
      field: 'Exchange_rate_date',
      enableSearch: true,
      enableFilter: true,
    },
    // {
    //   width: 100,
    //   title: "Financial_year",
    //   field: "Financial_year",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 100,
    //   title: "Send To Vendor",
    //   field: "Send_to_vendor",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    {
      width: 100,
      title: "IRN No.",
      field: "Irn_no",
      enableSearch: true,
      enableFilter: true,
    },
    // {
    //   width: 100,
    //   title: "Deleted",
    //   // field: "is_deleted",
    //   field: "Deleted",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    //  {
    //   width: 100,
    //   title: "Deletion Remark",
    //   field: "Deletion_remark",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    {
      width: 150,
      title: "Addition Remark",
      field: "Addition_remark",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 150,
      title: "System Remark",
      field: "System_Remark",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 150,
      title: "PL Remark",
      field: "PL_Remark",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 150,
      title: "Last Declared SP",
      field: "Total_Vendor_Price",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 150,
      title: "Last Declared Import Content",
      field: "Import_Content",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 150,
      title: "Last Declared Local Content",
      field: "Local_Content",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 150,
      title: "Last Declared Date",
      field: "Last_Declared_Date",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 150,
      title: "Last Signing Authority",
      field: "Ca_Sa",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 150,
      // title: "Send to Vendor For Supplier DVA Colletion",
      title: "Send To DVA Collection",
      // field: "Send_to_vendor_For_PMT_Role_DVA_Colletion",
      field: "Send_To_DVA_Collection",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 150,
      // title: "Send to Vendor For PMT Role DVA Under PLI",
      title: "Send To PLI Declaration",
      // field: "Send_to_vendor_For_PMT_Role_DVA_Under_PLI",
      field: "Send_To_PLI_Declaration",
      enableSearch: true,
      enableFilter: true,
    },
    {
      width: 100,
      // title: "created At",
      title: "Created Date",
      // field: "created_at",
      field: "Created_date",
      enableSearch: true,
      enableFilter: true,
    },
  ]

  const formattedCurrentDate = new Date().toLocaleDateString('en-GB').split('/').join('/');
    /* New axios way */
    const BOMvalidationAPI = () => {
      const apiBody = {
        Project_code: selectedProjectCode?.value,
        // email_to: 'dp928042.ttl@tatamotors.com'
        PartSelection_validation_date: formattedCurrentDate,
        PartSelection_validater_name: userName
      }
  
      console.log('Submit body: ', apiBody)
      setLoader(true)
      axios
        // .post(`${baseUrl}UpdateStatus/`, apiBody, {
        .post(`${baseUrl}UpdatePartSelctionStatus/`, apiBody, {
          headers: {
            'Content-Type': 'application/json'
          }
        })
        .then(response => {
          const responseJson = response.data
          console.log('responseJson', responseJson)
          if (responseJson?.message) {
            setLoader(false)
            showPopup({
              type: MESSAGE_TYPE.SUCCESS,
              contextText: MESSAGE_TYPE.SUCCESS,
              info: 'BOM Unique Part Selection Completed Successfully!'
            })
            history.push('/')
          } else {
            setLoader(false)
            showPopup({
              type: MESSAGE_TYPE.FAILURE,
              contextText: MESSAGE_TYPE.FAILURE,
              info: responseJson?.error
            })
          }
        })
        .catch(error => {
          setLoader(false)
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log('Error response:', error.response)
            if (error.response.status === 400) {
              showPopup({
                type: MESSAGE_TYPE.FAILURE,
                contextText: MESSAGE_TYPE.FAILURE,
                info: 'Bad Request: Please check your input!'
              })
            } else if (error.response.status === 404) {
              showPopup({
                type: MESSAGE_TYPE.FAILURE,
                contextText: MESSAGE_TYPE.FAILURE,
                info: 'Validation Failed'
              })
            } else {
              showPopup({
                type: MESSAGE_TYPE.FAILURE,
                contextText: MESSAGE_TYPE.FAILURE,
                info: `Error: ${error.response.statusText}`
              })
            }
          } else if (error.request) {
            // The request was made but no response was received
            console.log('Error request:', error.request)
            showPopup({
              type: MESSAGE_TYPE.FAILURE,
              contextText: MESSAGE_TYPE.FAILURE,
              info: 'No response received from server!'
            })
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error message:', error.message)
            showPopup({
              type: MESSAGE_TYPE.FAILURE,
              contextText: MESSAGE_TYPE.FAILURE,
              info: `Error: ${error.message}`
            })
          }
          console.log('error in submit: ', error)
        })
    }

  // const BOMvalidationAPI = () => {
  //   // BOMvalidationAPIView
  //   const formattedCurrentDate = new Date().toLocaleDateString('en-GB').split('/').reverse().join('/');
  //   console.log('inside submit api: ', Bc?.value);
  //   const apiBody = {
  //     Project_code: selectedProjectCode?.value,
  //     ProductLine_validation_date: formattedCurrentDate,
  //     ProductLine_validater_name: userName
  //     // VC: Bc?.value,
  //     // email_to: 'dp928042.ttl@tatamotors.com'
  //   }
  //   var body = JSON.stringify(apiBody);
  //   let headerss = new Headers();
  //   headerss.append("Content-Type", "application/json");
  //   // fetch(`${baseUrl}BOMvalidationAPIView/`, {
  //   // fetch(`${baseUrl}PMTUpdateStatus/`, {
  //   fetch(`${baseUrl}UpdateProductLineStatus/`, {
  //     method: 'POST',
  //     headers: headerss,
  //     body,
  //   })
  //     .then((response) => response.json())
  //     .then((responseJson) => {
  //       console.log('responseJson', responseJson);
  //       showPopup({
  //         type: MESSAGE_TYPE.SUCCESS,
  //         contextText: MESSAGE_TYPE.SUCCESS,
  //         info: "BOM Validation Successful!",
  //       });
  //       history.push("/");
  //     })
  //     .catch((error) => {
  //       console.log('error in submit: ', error);
  //     });
  // }

  const goNextForm = () => {
    // var url;
    // var urlMethod;

    // let { userRole, userID } = auth;

    // if (userRole.includes("Purchase") || userRole.includes("Purchase-CV")) {
    //   url = "SupplierDataaddition";
    //   urlMethod = "POST";
    // }

    // const apiFormatedData = {
    //   VC: Bc?.value,
    // };

    // var apiFormatedDataJson = JSON.stringify(apiFormatedData);
    // let headerss = new Headers();
    // headerss.append("Content-Type", "application/json");
    // fetch(`${baseUrl}${url}/`, {
    //   method: urlMethod,
    //   headers: headerss,
    //   body: apiFormatedDataJson,
    // })
    //   .then((response) => response.json())
    //   .then((responseJson) => {
    //     showPopup({
    //       type: MESSAGE_TYPE.SUCCESS,
    //       contextText: MESSAGE_TYPE.SUCCESS,
    //       info: "Data is Succesfully Saved",
    //     });
    //     history.push("/");
    //   })
    //   .catch((error) => {});
  };

  const redirectToCreateProjectPage = () => history.push("/");

  const renderHTML = () => (
    <div className={styles.formGroup}>
      {loading && (
        <div className={styles.gridLoader}>
          <GridLoadingSpinner />
        </div>
      )}
      <Grid
        container
        spacing={2}
        style={{ display: 'flex', justifyContent: 'left' }}
        columns={12}
      >
        <Grid
          item
          // sm={12}
          // md={4}
          // lg={3}
          xs={4}
          // style={{ marginTop: "5px", marginBottom: "5px" }}
        >
          <div className={styles.rowDiv}>
            <div className={clsx(styles.lableDiv)} style={{ width: '40%' }}>
              <label className={styles.label}>Project Code</label>
            </div>
            <div className={styles.formDiv} style={{ width: '100%' }}>
              <CustomSelect
                name='Project Code'
                options={projectCodeArray}
                className={clsx(styles.select, styles.sel1)}
                value={selectedProjectCode}
                isMulti={false}
                defaultValue={latestProject}
                isClearable
                isMandatory
                onChange={selectedOption => {
                  setSelectedProjectCode(selectedOption)
                  // getVcForProjectCode(selectedOption); // Fetch VC for selected project code
                }}
                style={{ width: '100%' }}
              />
            </div>
          </div>
        </Grid>

        {/* Project Desc. View */}
        <Grid
          item
          xs={4}
          md={4}
          lg={4}
          // sm={12}
          style={{
            // marginTop: "5px",
            // marginBottom: "5px",
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <div className={styles.rowDiv}>
            <div className={clsx(styles.lableDiv)} style={{ width: '40%' }}>
              <label className={styles.label}>Project Desc.</label>
            </div>
            {/* <div className={styles.formDiv}>
                <Grid item xs={3} className={styles.alignCenter}> */}
            <div className={styles.formDiv}>
              <ValidatingTextField
                variant='outlined'
                size='small'
                fullWidth
                // value={vendorCode?.vendorCode}
                // value={recentProjectCodeDescription && recentProjectCodeDescription}
                value={projectDescription && projectDescription}
                name='projDesc'
                inputProps={{
                  'data-testid': 'remark-input'
                }}
                onChange={selectedOption => handleDropdownChange(selectedOption)}
                // eslint-disable-next-line react/jsx-boolean-value
                isDisabled={true}
              />
            </div>
            {/* <span className={styles.symbolCSS}>&#x20b9;</span> */}
            {/* </Grid> */}
            {/* </div> */}
          </div>
        </Grid>

        <Grid
          item
          // md={5}
          // lg={4}
          // sm={12}
          xs={3}
          style={{
            display: 'flex',
            justifyContent: 'left',
            alignItems: 'center'
          }}
        >
          {/* <div
              style={{
                // marginLeft: "5px",
                paddingRight: "10px",
                // marginTop: "1px",
                display: "flex",
                justifyContent: "left",
              }}
            > */}
          <AuthChecker operation='search'>
            {isAuthorized => (
              <Button
                className={clsx(styles.actionButton, styles.primaryActionButton)}
                style={{ marginRight: '12px !important' }}
                variant='primary'
                onClick={showTable}
                disabled={!isAuthorized}
                data-testid='confirm-action'
              >
                Show Data
              </Button>
            )}
          </AuthChecker>
        </Grid>
      </Grid>
    </div>
  )

  // const uploadDocument = () => {
  //   var input = document.createElement("input");
  //   input.type = "file";
  //   input.id = "file";
  //   input.setAttribute("accept", ".xlsx");
  //   input.click();

  //   input.onchange = (e) => {
  //     const formData = new FormData();
  //     formData.append("file", e.target.files[0]);
  //     // formData.append("Supplier_Code", Bc?.value);
  //     // formData.append('Project_code', projectCode?.value)
  //     // formData.append('')
  //     // fetch(`${baseUrl}PMTBOMData/`, {

  //     setLoader(true)
  //     fetch(`${baseUrl}UploadPMTData/`, {
  //     // fetch(`${baseUrl}UpdateMasterData/`, {
  //       // method: "PUT",
  //       method: "POST",
  //       body: formData,
  //     })
  //       // .then((response) => {
  //       //   // console.log(response)
  //       //   // return response.json()
  //       // }

  //       .then((response) => {
  //         console.log(response);
  //         if (response.status == 200) {
  //           setLoader(false)
  //           showPopup({
  //             type: MESSAGE_TYPE.SUCCESS,
  //             //  contextText:getUrl(certificateUrl),
  //             contextText: MESSAGE_TYPE.SUCCESS,
  //             info: "Document Uploaded Successfully",
  //           });
  //           // return response.json()
  //           getTableData();
  //         } else if (response.status == 400) {
  //           setLoader(false)
  //           console.log(response?.data);
  //           showPopup({
  //             type: MESSAGE_TYPE.FAILURE,
  //             contextText: MESSAGE_TYPE.FAILURE,
  //             info: response?.data?.error || `Error uploading file...`,
  //           });
  //         } else {
  //           setLoader(false)
  //           showPopup({
  //             type: MESSAGE_TYPE.FAILURE,
  //             contextText: MESSAGE_TYPE.FAILURE,
  //             info: `Server error: ${response.data.status}`,
  //           });
  //         }
  //       })
  //       // .then((responseJson) => {
  //       //   console.log(responseJson);
  //       // //  var certificateurlstampurl = 'https://tml-test-digital-invoice-s3.s3.amazonaws.com/Certificate%20%2838%29_23-12-2022T11%3A02%3A38.pdf?response-content-disposition=attachment&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAQGDNMWVUMVQVJ4YB%2F20221223%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20221223T110240Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=4da98e95872b5a7d4f2b15a2f524ce1d18178ba15b0923a4ca1b8605ad79bd1a';

  //       //   if (responseJson) {
  //       //     showPopup({
  //       //       type: MESSAGE_TYPE.SUCCESS,
  //       //       //  contextText:getUrl(certificateUrl),
  //       //         contextText:MESSAGE_TYPE.SUCCESS,
  //       //       info:"Document Uploaded Successfully"

  //       //     });
  //       //   } else {
  //       //     showPopup({
  //       //       type: MESSAGE_TYPE.FAILURE,
  //       //       contextText: MESSAGE_TYPE.FAILURE,
  //       //        info: responseJson.url,
  //       //     });
  //       //   }

  //       //   // eslint-disable-next-line radix
  //       //   // let status1 = parseInt(response.status);
  //       //   //  console.log(response.status)
  //       //   // if (status1 == "200") {
  //       //   //   //   document.removeChild(input);
  //       //   // //  getTableData();

  //       //   // }
  //       // })

  //       .catch((error) => {
  //         setLoader(false)
  //         showPopup({
  //           type: MESSAGE_TYPE.FAILURE,
  //           contextText: MESSAGE_TYPE.FAILURE,
  //           info: MESSAGE_TYPE.FAILURE,
  //         });
  //       });
  //   };
  // };


  const uploadDocument = () => {
    const input = document.createElement('input')
    input.type = 'file'
    input.id = 'file'
    input.setAttribute('accept', '.xlsx')
    input.click()

    input.onchange = async e => {
      const formData = new FormData()
      formData.append('file', e.target.files[0])

      try {
        setLoader(true)

        const response = await axios.post(`${baseUrl}UploadPMTData/`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })

        if (response.status === 200) {
          setLoader(false)
          showPopup({
            type: MESSAGE_TYPE.SUCCESS,
            contextText: MESSAGE_TYPE.SUCCESS,
            info: 'Document Uploaded Successfully'
          })
          getTableData()
        } else if (response.status === 400) {
          setLoader(false)
          showPopup({
            type: MESSAGE_TYPE.FAILURE,
            contextText: MESSAGE_TYPE.FAILURE,
            info: response?.data?.error || 'Error uploading file...'
          })
        } else {
          setLoader(false)
          showPopup({
            type: MESSAGE_TYPE.FAILURE,
            contextText: MESSAGE_TYPE.FAILURE,
            info: `Server error: ${response?.status}`
          })
        }
      } catch (error) {
        setLoader(false)
        showPopup({
          type: MESSAGE_TYPE.FAILURE,
          contextText: MESSAGE_TYPE.FAILURE,
          info: error?.response?.data?.error || 'An unexpected error occurred.'
        })
      }
    }
  }


  const secondaryActions = [
    {
      name: "Upload",
      authOperation: "upload",
      // shouldEnable: () => isLatestProjectSelected, // Enable only if the latest project is selected
      // shouldEnable: () => submitButtonFlag(),
      shouldEnable: () => true,
      actionFn: () => {
        uploadDocument();
      },
      customClass: "",
    },
    {
      name: "Download",
      authOperation: "download",
      shouldEnable: (selected) => true,
      actionFn: (selected) => {
        // getDataForExcel()
        exportExcel(tableArray);
      },
      customClass: "",
    },
    {
      name: "Download Reference Data",
      authOperation: "download",
      shouldEnable: (selected) => true,
      actionFn: (selected) => getAllQRRDataAndExportExcel(),
      customClass: "",
    }
  ];

  const handleSecondaryActionOnRole = () => {
    if (auth.userRole.includes("admin")) {
      return secondaryActions;
    }
    if (auth.userRole.includes("GDC") || auth.userRole.includes("TMLBSL")) {
      return secondaryActions.filter(
        (item) =>
          item.name === "Download Documents" ||
          item.name === "Download Certificate" ||
          item.name === "Upload"
      );
    }
    if (auth.userRole.includes("Vendor")) {
      return secondaryActions.filter(
        (item) =>
          item.name !== "Download Documents" &&
          item.name !== "Download Certificate"
      );
    }
    if (auth.userRole.includes("Purchase")) {
      return secondaryActions.filter(
        (item) =>
          item.name === "Download Documents" ||
          item.name === "Download Certificate"
      );
    }
    return secondaryActions;
  };

  return (
    <>
      <div className={styles.container} style={{ boxSizing: "border-box" }}>
        <div>
          <div
            className={styles.backButtonLastRefreshedDateWrapper}
            style={{ marginTop: "10px" }}
          >
            <BackButton
              action="Back"
              handleClick={redirectToApplicationLandingPage}
              style={{ marginBottom: "0px" }}
            />
            <div
              style={{ width: "100%" }}
              // style={{ display: 'flex', flexDirection: 'row-reverse' }}
            >
              <div className={styles.topDiv}>
                <div
                  className={styles.processHeader}
                  style={{ display: "flex", height: "3em" }}
                >
                  <div style={{ width: "100%" }}>
                    <CustomTab title="Unique Part Selection" withOutCount isSelected />
                  </div>

                  {/* <StepBar1 enable={5} /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className={styles.formContainer}>
            <CustomFormGroup body={renderHTML()} />
          </div>
        </div>
        {flag && (
          <div
            className={styles.tableView}
            style={{ height: "86%", padding: "0 2em" }}
          >
             <Table
        columns={
          !auth.userRole.includes("Vendor")
            ? [...baseDefaultColumns]
            : [...baseDefaultColumns]
        }
        defaultColumns={[...baseDefaultColumns]}
        rowHeight={38}
        rows={tableArray}
        primaryAction={{
          name: "Submit Data",
          authOperation: "submit",
          // shouldEnable: () => isLatestProjectSelected, // Enable only if the latest project is selected
          // shouldEnable: () => submitButtonFlag(),
          shouldEnable: () => true,
          actionFn: handleOpenFormModal,
        }}
        secondaryActions={secondaryActions}
        setAsnCount={setCount}
        isDataLoading={loader}
        actionButtons
        onRowSelect={(e) => {}}
        removeRowSelection
      />

          </div>
        )}
      </div>
      {/* <Button
            className={clsx(
              styles.actionButton,
              styles.primaryActionButton,
            )}
            variant="primary"
            // onClick={onDelete}
          >
            Yes
          </Button> */}
      <ResetModalF
        isPopupOpen={isPopupOpenFormModal}
        handleCloseFormModal={handleCloseFormModal}
        BOMvalidationAPI={BOMvalidationAPI}
      />

      <ResetModal
        isPopupOpen={isPopupOpen}
        handleClose={handleClose}
        goNext={goNext}
      />
    </>
  );
};
function ResetModalF({ isPopupOpen, handleCloseFormModal, BOMvalidationAPI }) {
  return (
    <Dialog
      open={isPopupOpen}
      className={styles.popContainer}
      classes={{
        paper: styles.popupBox,
      }}
      data-testid="reset-popup"
    >
      <>
        <DialogTitle>
          <span className={styles.title}>
            <WarningIcon style={{ height: "18px", width: "18px" }} />
            <span style={{ marginLeft: "8px" }} className={styles.txt}>
              Confirm Validation
            </span>
          </span>
        </DialogTitle>
        <DialogContent className={styles.content}>
          <div>
            {/* <p> On confirmation, All existing data will be overwritten by the uploaded data.</p> */}
            <p>On confirmation, BOM Part(s) will be send to Vendor(s).</p>

            <p>Are you sure ?</p>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            className={clsx(styles.actionButton, styles.transparentButton)}
            onClick={handleCloseFormModal}
          >
            Cancel
          </Button>
          <Button
            className={clsx(styles.actionButton, styles.primaryActionButton)}
            variant="primary"
            onClick={(e) => {
              e.preventDefault()
              handleCloseFormModal()
              BOMvalidationAPI()
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </>
    </Dialog>
  );
}
function ResetModal({ isPopupOpen, handleClose, goNext }) {
  return (
    <Dialog
      open={isPopupOpen}
      className={styles.popContainer}
      classes={{
        paper: styles.popupBox,
      }}
      data-testid="reset-popup"
    >
      <>
        <DialogTitle>
          <span className={styles.title}>
            <WarningIcon style={{ height: "18px", width: "18px" }} />
            <span style={{ marginLeft: "8px" }} className={styles.txt}>
              Confirm file Upload
            </span>
          </span>
        </DialogTitle>
        <DialogContent className={styles.content}>
          <div>
            <p> On confirmation ,Records will be appended.</p>
            <p>Are you sure ?</p>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            className={clsx(styles.actionButton, styles.transparentButton)}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            className={clsx(styles.actionButton, styles.primaryActionButton)}
            variant="primary"
            onClick={(e) => {
              e.preventDefault();
              handleClose();
              goNext();
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </>
    </Dialog>
  );
}
ResetModalF.propTypes = {
  isPopupOpen: PropTypes.bool.isRequired,
  handleCloseFormModal: PropTypes.func.isRequired,
  BOMvalidationAPI: PropTypes.func.isRequired,
};

ResetModal.propTypes = {
  isPopupOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  goNext: PropTypes.func.isRequired,
};

export default withAllowedOperationsProvider(VendarData, RESOURCE_TYPE.PROJECT);
