/* eslint-disable react/self-closing-comp */
/* eslint-disable vars-on-top */
/* eslint-disable eqeqeq */
/* eslint-disable prefer-const */
/* eslint-disable no-var */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-use-before-define */
/* eslint-disable array-callback-return */
/* eslint-disable camelcase */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
/* eslint-disable no-nested-ternary */

import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import {
  Modal,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Form,
  Box,
  TextField,
  Grid,
  FormGroup,
  IconButton
} from '@material-ui/core'
import WarningIcon from '@mui/icons-material/Warning'
import FormLabel from '@mui/material/FormLabel'
import * as FileSaver from 'file-saver'
import XLSX from 'sheetjs-style'
// for print user in consol
import jwt_decode from 'jwt-decode'
import { useSelector } from 'react-redux'
// for print user in consol
import axios from 'axios'

import clsx from 'clsx'
// import $ from 'jquery';
import { useHistory } from 'react-router-dom'
import Table from '../../components/Table/Table'
import styles from './BOMProductLine2.module.css'
import {
  CustomFormGroup,
  CustomSelect,
  ValidatingTextField
} from '../../components/FormComponents'
import BackButton from '../../components/BackButton/BackButton'
import {
  DATE_FORMAT,
  API_RESOURCE_URLS,
  DISPLAY_MESSAGES,
  MESSAGE_TYPE,
  MESSAGE_TYPE_CERTIFICATION,
  MESSAGE_TYPE_ANNEXURE,
  RESOURCE_TYPE,
  USER_OPERATIONS,
  Regex
} from '../../constants'
import { withAllowedOperationsProvider } from '../../hocs'
import { CustomTab, Button, AuthChecker } from '../../atomicComponents'
import { API } from '../../apis/api'
import { buildErrorMessage } from '../../apis/calls'
import { usePopupManager } from '../../providers/PopupManager/PopupManager'

import StepBar1 from '../../components/StepBar1/StepBar'
import { GridLoadingSpinner } from '../../components'

const VendarData = () => {
  const history = useHistory()
  const { showPopup } = usePopupManager()
  // const baseUrl='http://127.0.0.1:8000/file/'
  const baseUrl = useSelector(state => state.counter.baseUrl) // useSelector
  const auth = useSelector(state => state.auth) // useSelector
  const { userRole, userID, preferredUsername, userName } = auth
  // const [dynamicColumns, setDynamicColumns] = useState([]);
  // const [dynamicColumns1, setDynamicColumns1] = useState([]);
  // const [plant, setPlant] = useState(null);
  const [flag, show] = useState(false)
  // const [checkDisable, setDisable] = useState(false);
  const [projectCode, setProjectCode] = useState(null)
  const [projectCodeArray, setProjectCodeArray] = useState([])
  const [isLatestProjectSelected, setIsLatestProjectSelected] = useState(false) // New state for button enable/disable
  // const [projectCodeArray, setProjectCodeArray] = useState([]);
  const [selectedProjectCode, setSelectedProjectCode] = useState(null)
  const [latestProject, setLatestProject] = useState('')
  const [Bc, setBC] = useState(null)
  const [bcArray, setBcArray] = useState([])
  // const [plantArray, setPlantArray] = useState([]);
  const [tableArray, setTableArray] = useState([])
  // const [fyArray, setFyArray] = useState([]);
  // const [Fy, setFY] = useState(null);
  // const [quarterArray, setQuarterArray] = useState([]);
  // const [quarter, setQuarter] = useState(null);
  const [asnCount, setAsnCount] = useState(0)
  const [loading, setLoader] = useState(false)
  const [loader, setLoading] = useState(true)
  const [vcStatus, setVcStatus] = useState()
  // const [vendCode, setvendCode] = useState();
  // const [datesData, setDates] = useState({
  //   endDate: "",
  //   startDate: "",
  // })
  // const [recentProjectCodeDescription, setRecentProjectCodeDescription] = useState()
  const [projectDescription, setProjectDescription] = useState('')
  //
  const [isPopupOpenFormModal, setPopupOpenFormModal] = useState(false)
  const handleOpenFormModal = () => {
    setPopupOpenFormModal(true)
  }
  const handleCloseFormModal = () => setPopupOpenFormModal(false)

  //

  // const ROW_HEIGHT = 38;

  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const handleOpen = () => setIsPopupOpen(true)
  const handleClose = () => setIsPopupOpen(false)
  const goNext = () => {
    uploadFileData()
  }

  const redirectToApplicationLandingPage = () => {
    history.push('/')
  }

  const setCount = count => {
    setAsnCount(count)
  }

  useEffect(() => {
    getRecentProjectCode()
    getAllProjectCode()
  }, [])

  function submitButtonFlag () {
    let buttonFlag
    if (
      vcStatus?.ProductLine_status_validator2 === 'Submitted' 
      // &&
      // isLatestProjectSelected
    ) {
      buttonFlag = false
    } else if (
      vcStatus?.ProductLine_status_validator2 === 'Submitted' 
      // &&
      // !isLatestProjectSelected
    ) {
      buttonFlag = false
    } else if (
      vcStatus?.ProductLine_status_validator2 === 'Not submitted' 
      // &&
      // isLatestProjectSelected
    ) {
      buttonFlag = true
    } else if (
      vcStatus?.ProductLine_status_validator2 === 'Not submitted' 
      // &&
      // !isLatestProjectSelected
    ) {
      buttonFlag = false
    }
    return buttonFlag
  }

  const handleDropdownChange = (selectedOption, name) => {
    // console.log('selectedOption: ', selectedOption)
    if (name === 'Project_Code') {
      setSelectedProjectCode(selectedOption)
      getProjectDescription()
      // if (selectedProjectCode !== null) {
      //   getVcForProjectCode(selectedOption)
      // }
    }
    // else if (name === 'projDesc') {
    //   console.log('inside projDesc');

    //   setSelectedProjectCode(selectedOption)
    //   getProjectDescription(selectedOption)
    // }
  }

  // useEffect(() => {
  //   getAllProjectCode();
  // }, []);

  useEffect(() => {
    if (selectedProjectCode) {
      getVcForProjectCode(selectedProjectCode)
      getProjectDescription()
    }
  }, [selectedProjectCode])

  // const getdate = () => {
  //   const headerss = new Headers();
  //   headerss.append("Content-Type", "application/json");
  //   fetch(`${baseUrl}Milestone_calendarView/`, {
  //     method: "GET",
  //     headers: headerss,
  //   })
  //     .then((response) => response.json())
  //     .then((responseJson) => {
  //       const endDate = responseJson[0].Collect_DVA_from_vendors_EndDate;
  //       const startDate = responseJson[0].Collect_DVA_from_vendors_StartDate;
  //       setDates((prevData) => ({ ...prevData, endDate, startDate }));
  //     })
  //     .catch((error) => {});
  // };

  const showTable = () => {
    // console.log("ProjectCode: ", projectCode)
    // console.log("Project value: ", projectCode?.value)
    // if(projectCode === null || projectCode.value === ''){
    if (
      selectedProjectCode === null ||
      selectedProjectCode.value === '' ||
      selectedProjectCode.value === null
    ) {
      showPopup({
        type: MESSAGE_TYPE.INFORMATION,
        contextText: MESSAGE_TYPE.INFORMATION,
        info: 'Project Code is Mandatory'
      })
    } else if (
      Bc === null ||
      Bc?.value === '' ||
      Bc?.value === null ||
      Bc === undefined
    ) {
      showPopup({
        type: MESSAGE_TYPE.INFORMATION,
        contextText: MESSAGE_TYPE.INFORMATION,
        info: 'VC is Mandatory'
      })
    } else {
      getProjectDescription()
      getTableData()
      getPLStatusAPIViewforVCAndPCAPI()
      show(true)
    }
  }

  const getPLStatusAPIViewforVCAndPCAPI = async () => {
    const body = {
      Project_code: selectedProjectCode?.value,
      VC: Bc?.value
    }
    try {
      const response = await axios.post(
        // `${baseUrl}PLStatusAPIViewforVCAndPC/`,
        `${baseUrl}BOMValidater2StatusforVCAndPC/`,
        body,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      console.log('API Response:', response.data)
      if (response.data.error) {
        // alert(`API Error: ${response.data.error}`)
        showPopup({
          type: MESSAGE_TYPE.FAILURE,
          contextText: MESSAGE_TYPE.FAILURE,
          info: response?.data?.error
        })
        setLoading(false)
      } else if (Array.isArray(response.data)) {
        setVcStatus(response?.data[0])
      } else {
        // showPopup({
        //   type: MESSAGE_TYPE.INFORMATION,
        //   contextText: MESSAGE_TYPE.INFORMATION,
        //   info: "Unexpected API response format.",
        // })
        // setLoading(false)
      }
    } catch (error) {
      // showPopup({
      //   type: MESSAGE_TYPE.INFORMATION,
      //   contextText: MESSAGE_TYPE.INFORMATION,
      //   info: "Error fetching data, please try again.",
      // })
      // setLoading(false)
    }
  }

  // let Vendor_Code ='';
  // const handleSelect = (selectedOption, name) => {
  //   // alert(selectedOption.value);
  //   // console.log(selectedOption);
  //   setvendCode(selectedOption.value);
  //   if (name == "VC") {
  //     show(false);

  //     setBC(selectedOption);
  //     // getPlantData(selectedOption)
  //   } else {
  //     setPlant(selectedOption);
  //   }
  // };

  // const getTableData = async () => {
  //   // console.log("Selected Project Code:", selectedProjectCode?.value)
  //   // console.log("Selected VC:", Bc?.value)
  //   const apiFormattedData = {
  //     Project_code: selectedProjectCode?.value,
  //     VC: Bc?.value
  //   }
  //   // const apiFormattedDataJson = JSON.stringify(apiFormattedData)
  //   setLoader(true)
  //   try {
  //     const response = await axios.post(
  //       // `${baseUrl}ShowMasterDataofPCandVC/`,
  //       // `${baseUrl}ShowProductLineBOMData/`,
  //       // `${baseUrl}ShowPLBOMData/`,
  //       `${baseUrl}BOMValidater2Data/`,
  //       apiFormattedData,
  //       {
  //         headers: {
  //           'Content-Type': 'application/json'
  //         }
  //       }
  //     )
  //     // console.log("API Response:", response.data)
  //     if (response.data.error) {
  //       // alert(`API Error: ${response.data.error}`)
  //       setLoader(false)
  //       showPopup({
  //         type: MESSAGE_TYPE.INFORMATION,
  //         contextText: MESSAGE_TYPE.INFORMATION,
  //         info: response?.data?.error
  //       })
  //       setLoading(false)
  //     } else if (Array.isArray(response.data)) {
  //       // console.log("Table Data:", response.data);
  //       setLoader(false)
  //       const arr = response.data.map(item => ({
  //         ...item
  //         // Send_to_vendor: item?.Send_to_vendor === true ? "True" : "False",
  //         // is_deleted: item?.is_deleted === true ? "True" : "False",
  //         // AppliedForPLI: item?.AppliedForPLI === true ? "True" : "False",
  //         // Supplier_pli: item?.Supplier_pli !== null ? item?.Supplier_pli === true ? "True" : "False" : '',
  //       }))
  //       setTableArray(arr)
  //       setLoading(false)
  //     } else {
  //       // Unexpected format
  //       // alert("Unexpected API response format.")
  //       setLoader(false)
  //       showPopup({
  //         type: MESSAGE_TYPE.INFORMATION,
  //         contextText: MESSAGE_TYPE.INFORMATION,
  //         info: 'Unexpected API response format.'
  //       })
  //       // console.log("Unexpected API response format:", response.data);
  //       setLoading(false)
  //     }
  //   } catch (error) {
  //     // console.error("Fetch error:", error);
  //     // alert("Error fetching data, please try again.")
  //     setLoader(false)
  //     showPopup({
  //       type: MESSAGE_TYPE.FAILURE,
  //       contextText: MESSAGE_TYPE.FAILURE,
  //       info: 'Error fetching data, please try again.'
  //     })
  //     setLoading(false)
  //   }
  // }
  const getTableData = async () => {
    const apiFormattedData = {
      Project_code: selectedProjectCode?.value,
      VC: Bc?.value
    };
    setLoader(true);
    try {
      const response = await axios.post(
        `${baseUrl}BOMValidater2Data/`,
        apiFormattedData,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      if (response.data.error) {
        setLoader(false);
        showPopup({
          type: MESSAGE_TYPE.INFORMATION,
          contextText: MESSAGE_TYPE.INFORMATION,
          info: response?.data?.error
        });
        setLoading(false);
      } else if (Array.isArray(response.data)) {
        setLoader(false);
        const arr = response.data.map(item => ({
          ...item,
          Grn_sob: Number(item.Grn_sob).toFixed(2).replace(/\.00$/, ''),
          Bom_sob: Number(item.Bom_sob).toFixed(2).replace(/\.00$/, ''),
          Total_cost: Number(item.Total_cost).toFixed(2).replace(/\.00$/, ''),
        }));
        setTableArray(arr);
        setLoading(false);
      } else {
        setLoader(false);
        showPopup({
          type: MESSAGE_TYPE.INFORMATION,
          contextText: MESSAGE_TYPE.INFORMATION,
          info: 'Unexpected API response format.'
        });
        setLoading(false);
      }
    } catch (error) {
      setLoader(false);
      showPopup({
        type: MESSAGE_TYPE.FAILURE,
        contextText: MESSAGE_TYPE.FAILURE,
        info: 'Error fetching data, please try again.'
      });
      setLoading(false);
    }
  };
  

  // const getAllData = () => {
  //   let headerss = new Headers();
  //   headerss.append("Content-Type", "application/json");

  //   fetch(`${baseUrl}GetAllMasterData/`, {
  //     method: "GET",
  //     headers: headerss,
  //   })
  //     .then((response) => response.json())
  //     .then((responseJson) => {
  //       if (responseJson === null || responseJson.length === 0) {
  //         showPopup({
  //           type: MESSAGE_TYPE.FAILURE,
  //           contextText: MESSAGE_TYPE.FAILURE,
  //           info: "No data available",
  //         });
  //       } else {
  //         const transformedData = responseJson.map((item) => ({
  //           ...item,
  //           Send_to_vendor: item?.Send_to_vendor === true ? "True" : "False",
  //           is_deleted: item?.is_deleted === true ? "True" : "False",
  //           AppliedForPLI: item?.AppliedForPLI === true ? "True" : "False",
  //         }));

  //         setTableArray(transformedData);
  //         show(true);
  //         setLoading(false);
  //       }
  //     })
  //     .catch((error) => {
  //       console.log("error", error);
  //       showPopup({
  //         type: MESSAGE_TYPE.FAILURE,
  //         contextText: MESSAGE_TYPE.FAILURE,
  //         info: "Error while fetching data.",
  //       });
  //     });
  // }

  const getAllProjectCode = async () => {
    try {
      // const { data } = await axios.get(`${baseUrl}AllProjectCode/`);
      // const { data } = await axios.get(`${baseUrl}AllProjectCodeforProductline/`);
      const { data } = await axios.get(
        `${baseUrl}AllProjectCodeforProductline/`
      )
      console.log('data in getall', data)

      const allProjectCodes = data.map(({ Project_code }) => Project_code)
      const uniqueProjects = [...new Set(allProjectCodes)]
      const projectCodeOptions = uniqueProjects.map(value => ({
        value,
        label: value
      }))
      setProjectCodeArray(projectCodeOptions)
    } catch (error) {
      console.error('Error fetching project codes:', error)
    }
  }

  const getRecentProjectCode = async projectCodeOptions => {
    try {
      // const { data, status } = await axios.get(`${baseUrl}LatestProjectCode/`);
      const { data, status } = await axios.get(
        `${baseUrl}LatestProjectCodeforProductline/`
      )
      if (status !== 200) {
        throw new Error('Failed to fetch the latest project code')
      }
      // console.log('data in Project_code: ', data)
      const recentProj = {
        label: data?.Project_code,
        value: data?.Project_code
      }
      setLatestProject(recentProj)
      setSelectedProjectCode(recentProj) // Automatically select the latest project code
      getVcForProjectCode(recentProj)
      // const recentProjectCodeDescription =
    } catch (error) {
      // console.error("Error fetching recent project codes:", error);
      showPopup({
        type: MESSAGE_TYPE.FAILURE,
        contextText: MESSAGE_TYPE.FAILURE,
        info: 'Could not fetch Project Code!'
      })
    }
  }

  // Fetch project description based on project code
  const getProjectDescription = async () => {
    try {
      const headerss = { 'Content-Type': 'application/json' }
      const body = { Project_code: selectedProjectCode?.value }
      const { data } = await axios.post(
        `${baseUrl}ProjectDescriptionView/`,
        body,
        { headers: headerss }
      )
      // console.log('pro desc: ', data)
      setProjectDescription(data?.Project_Description)
    } catch (error) {
      console.error('Error fetching project description:', error)
    }
  }

  // useEffect(() => {
  //   getAllProjectCode();
  // }, []);

  useEffect(() => {
    if (latestProject) {
      setSelectedProjectCode(latestProject)
    }
  }, [latestProject])

  // Check if the selected project code is the latest project code
  useEffect(() => {
    if (selectedProjectCode && latestProject) {
      setIsLatestProjectSelected(
        selectedProjectCode.value === latestProject.value
      )
    }
  }, [selectedProjectCode, latestProject])

  // const BomPmtBoeStatusAPI = () => {

  // }

  // console.log('selected Bc: ', Bc);
  const getVcForProjectCode = async selectedCode => {
    console.log('selectedCode: ', selectedCode)
    // console.log('selectedCode ?.Project_code: ', selectedCode?.Project_code)
    console.log('selectedProjectCode', selectedProjectCode)

    const headerss = {
      'Content-Type': 'application/json'
    }

    const apiBody = {
      // project_code: selectedProjectCode?.value || selectedCode?.value,
      Project_code: selectedProjectCode?.value || selectedCode?.value
      // SPOC: preferredUsername
    }
    console.log('PL body: ', apiBody, preferredUsername)

    try {
      const response = await axios.post(
        // `${baseUrl}GetVCsByProject/`,
        // `${baseUrl}GetVCsByProjectAndSPOCForPL/`,
        `${baseUrl}GetVCsByProject/`,
        apiBody,
        { headers: headerss }
      )

      if (response.status >= 200 && response.status < 300) {
        const resArray = response.data?.map(value => ({ value, label: value }))
        console.log('resArray: ', resArray)
        setBcArray(resArray)
      } else {
        console.error(
          `Error fetching VC: ${response.status} - ${response.statusText}`
        )
        showPopup({
          type: MESSAGE_TYPE.FAILURE,
          contextText: MESSAGE_TYPE.FAILURE,
          info: `Failed to fetch data. Error: ${response.status} - ${response.statusText}`
        })
      }
    } catch (error) {
      if (error.response) {
        // Server responded with a status other than 2xx
        console.error(
          `Error fetching VC: ${error.response.status} - ${error.response.data}`
        )
        showPopup({
          type: MESSAGE_TYPE.FAILURE,
          contextText: MESSAGE_TYPE.FAILURE,
          info: `Failed to fetch data. Error: ${error.response.status} - ${error.response.data}`
        })
      } else if (error.request) {
        // Request was made but no response was received
        console.error('Error fetching VC: No response received', error.request)
        showPopup({
          type: MESSAGE_TYPE.FAILURE,
          contextText: MESSAGE_TYPE.FAILURE,
          info: 'Failed to fetch data. No response received from the server.'
        })
      } else {
        // Something else happened in setting up the request
        console.error('Error fetching VC:', error.message)
        showPopup({
          type: MESSAGE_TYPE.FAILURE,
          contextText: MESSAGE_TYPE.FAILURE,
          info: `Failed to fetch data. Error: ${error.message}`
        })
      }
    }
  }

  // const getVcForProjectCode = (selectedCode) => {
  //   console.log('selectedCode: ', selectedCode)
  //   console.log('selectedCode ?.Project_code: ', selectedCode?.Project_code);
  //   let headerss = new Headers();
  //   headerss.append("Content-Type", "application/json");

  //   const apiBody = {
  //     project_code: selectedCode?.value || selectedCode?.Project_code,
  //     spoc: preferredUsername
  //   }
  //   // fetch(`${baseUrl}VCForProjectCode/`, {
  //   fetch(`${baseUrl}GetVCsByProjectAndSPOC/`, {
  //     method: "POST",
  //     headers: headerss,
  //     body: JSON.stringify(apiBody),
  //   })
  //     .then((response) => response.json())
  //     .then((responseJson) => {

  //       if (responseJson?.ok) {
  //         console.log('res GetVCsBy: ', responseJson)
  //         const resArray = responseJson?.map(value => ({ value, label: value }))
  //         console.log('resArray: ', resArray)
  //         setBcArray(resArray)
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching VC:", error);
  //     });
  // };

  const uploadFileData = () => {
    var input = document.createElement('input')
    input.type = 'file'
    input.id = 'file'
    input.setAttribute(
      'accept',
      '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
    )
    input.click()
    input.onchange = e => {
      setLoader(true)
      const formData = new FormData()
      formData.append('file', e.target.files[0])
      fetch(`${baseUrl}SupplierDataaddition/`, {
        method: 'POST',
        body: formData
      })
        .then(response => {
          // eslint-disable-next-line radix
          let status1 = parseInt(response.status)
          if (status1 == '201') {
            //   document.removeChild(input);
            getTableData()
            showPopup({
              type: MESSAGE_TYPE.SUCCESS,
              contextText: MESSAGE_TYPE.SUCCESS,
              info: 'file uploaded sucessfully'
            })
          } else {
            showPopup({
              type: MESSAGE_TYPE.SUCCESS,
              contextText: MESSAGE_TYPE.SUCCESS,
              info: 'file uploaded failed'
            })
          }
        })

        .catch(error => {})
    }
  }

  const exportExcel = async excelData => {
    setLoader(true)
    const newExcelData = excelData?.map(
      (
        {
          Project_code,
          Project_type,
          Project_type_details,
          Financial_year,
          Quarter,
          VC,
          VC_description,
          Plant_code,
          Po_Plant,
          Sr_Order,
          Part_no,
          Part_description,
          Supplier_code,
          Supplier_name,
          Run_version,
          Operation,
          Bom_item_no,
          Supplier_pli,
          // Availing_PLI_Incentive_Yes_No,
          Part_uom,
          Assembly,
          Sr_no,

          Group_material,
          Group_material_quantity,
          Reference_material,
          Followup_material,
          Cost_run_date,
          Bom_quantity,
          Bom_uom,

         
          /* Editing as said by Pritish on 09-1-2025 */
          Total_cost,
          // BOM_price,
          Grn_sob,
          Bom_sob,
          Gr_no,
          Gr_date,
          Gr_entry_date,
          Total_gr_quantity,
          Final_quantity,
          Percentage_check,
          Po_no,
          Po_type,
          Purchase_group,
          Import_domestic,
          Contract_no,
          Contract_Price,
          Invoice_no_r,
          Invoice_date_r,
          Invoice_uom_r,
          Invoive_no_s,
          Supplier_Credit_Invoices,
          Supplier_Debit_Invoices,
          Invoice_date_s,
          Invoice_uom_s,
          Sap_invoice_quantity,
          Net_price,
          Supplementary_price,
          Gross_price,
          Total_net_price,
          Total_gross_price,
          HSN_code,
          Currency,
          PAN,
          Exchange_rate,
          Exchange_rate_date,
          Irn_no,
          // Deleted,
          BOM_validation_2_remark,
          Addition_remark,
          System_Remark,
          PL_Remark,
          Created_date
        },
        id
      ) => {
        let obj = {
          id,
          Project_code,
          Project_type,
          Project_type_details,
          Financial_year,
          Quarter,
          VC,
          VC_description,
          Plant_code,
          Po_Plant,
          Sr_Order,
          Part_no,
          Part_description,
          Supplier_code,
          Supplier_name,
          Run_version,
          Operation,
          Bom_item_no,
          Supplier_pli,
          // Availing_PLI_Incentive_Yes_No,
          Part_uom,
          Assembly,
          Sr_no,

          Group_material,
          Group_material_quantity,
          Reference_material,
          Followup_material,
          Cost_run_date,
          Bom_quantity,
          Bom_uom,
          // 
          /* Editing as said by Pritish on 09-1-2025 */
          'BOM_price':Total_cost,

          Grn_sob,
          Bom_sob,
          Gr_no,
          Gr_date,
          Gr_entry_date,
          Total_gr_quantity,
          Final_quantity,
          Percentage_check,
          Po_no,
          Po_type,
          Purchase_group,
          Import_domestic,
          Contract_no,
          Contract_Price,
          Invoice_no_r,
          Invoice_date_r,
          Invoice_uom_r,
          Invoive_no_s,
          Supplier_Credit_Invoices,
          Supplier_Debit_Invoices,
          Invoice_date_s,
          Invoice_uom_s,
          Sap_invoice_quantity,
          Net_price,
          Supplementary_price,
          Gross_price,
          Total_net_price,
          Total_gross_price,
          HSN_code,
          Currency,
          PAN,
          Exchange_rate,
          Exchange_rate_date,
          Irn_no,
          // Deleted,
          BOM_validation_2_remark,
          Addition_remark,
          System_Remark,
          PL_Remark,
          Created_date
        }
        return obj
      }
    )
    console.log('new excel: ', newExcelData)
    var fileTypel =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    var fileExt = '.xlsx'
    const ws = XLSX.utils.json_to_sheet(newExcelData)
    // const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
    const excelBuffer = XLSX.write(wb, { booktype: 'xlsx', type: 'array' })
    const data = new Blob([excelBuffer], { type: fileTypel })
    setLoader(false)
    FileSaver.saveAs(data, `BOM_Validation_2${fileExt}`)
  }

  /* New Columns 17-07-2024 */
  const baseDefaultColumns = [
    {
      width: 130,
      title: 'Project Code',
      field: 'Project_code',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 130,
      title: 'Project Type',
      field: 'Project_type',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 170,
      title: 'Project Type Details',
      // field: "Project_Type_Details",
      field: 'Project_type_details',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 130,
      title: 'Financial Year',
      field: 'Financial_year',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 130,
      title: 'Quarter',
      field: 'Quarter',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 130,
      title: 'VC',
      field: 'VC',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 130,
      title: 'VC Description',
      field: 'VC_description',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 130,
      title: 'Plant Code',
      field: 'Plant_code',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 130,
      title: 'PO Plant',
      field: 'Po_Plant',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 120,
      title: 'Sr Order',
      field: 'Sr_Order',
      enableSearch: true,
      enableFilter: true
    },

    {
      width: 150,
      title: 'Part Number',
      // field: "Part_number",
      field: 'Part_no',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 180,
      title: 'Part Description',
      // field: "Part_Description",
      field: 'Part_description',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 130,
      title: 'Supplier Code',
      // field: "Supplier_Code",
      field: 'Supplier_code',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 180,
      title: 'Supplier Name',
      // field: "Supplier_Name",
      field: 'Supplier_name',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 180,
      title: 'Run Version',
      // field: "Part_Description",
      field: 'Run_version',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 180,
      title: 'Operation',
      // field: "Part_Description",
      field: 'Operation',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 160,
      title: 'Bom Item No.',
      // field: "Part_Description",
      field: 'Bom_item_no',
      enableSearch: true,
      enableFilter: true
    },

    {
      width: 160,
      // title: "Applied For PLI(Y/N)",
      // title: "Availing PLI Incentive (Y/N)",
      title: 'Supplier Under PLI (Y/N)',
      // field: "AppliedForPLI",
      field: 'Supplier_pli',
      // field: "Availing_PLI_Incentive_Yes_No",
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 120,
      title: 'Part UOM',
      field: 'Part_uom',
      enableSearch: true,
      enableFilter: true
    },

    /*  */
    {
      width: 120,
      title: 'Assembly',
      field: 'Assembly',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 120,
      title: 'Sr. No.',
      field: 'Sr_no',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'Group Material',
      // field: "Group_Material",
      field: 'Group_material',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'Group Material Quantity',
      // field: "Group_Material_Quantity",
      field: 'Group_material_quantity',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'Reference Material',
      field: 'Reference_material',
      enableSearch: true,
      enableFilter: true
    },
    // {
    //   width: 100,
    //   title: "Reference",
    //   field: "Reference",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    {
      width: 100,
      title: 'Follow up Material',
      field: 'Followup_material',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'Cost Run Date',
      // field: "Cost_Run_Date",
      field: 'Cost_run_date',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'BOM Quantity',
      // field: "BOM_Quantity",
      field: 'Bom_quantity',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'BOM UOM',
      // field: "BOM_UOM",
      field: 'Bom_uom',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      // title: "Total Cost",
      // field: "Total_Cost",

      /* Editing as said by Pritish on 03-10-2024 */
      title: 'BOM Price',
      field: 'Total_cost',
      /* ........................................ */

      enableSearch: true,
      enableFilter: true
    },

    {
      width: 130,
      title: 'GRN SOB',
      field: 'Grn_sob',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 130,
      title: 'BOM SOB',
      field: 'Bom_sob',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 130,
      title: 'GR No',
      // field: "GR_No",
      field: 'Gr_no',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 130,
      title: 'GR Date',
      // field: "GR_date",
      field: 'Gr_date',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 130,
      title: 'GR Entry Date',
      // field: "GR_Entry_Date",
      field: 'Gr_entry_date',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 130,
      title: 'Total GR Quantity',
      field: 'Total_gr_quantity',
      enableSearch: true,
      enableFilter: true
    },

    {
      width: 130,
      title: 'Final Quantity',
      // field: "Final_Quantity",
      field: 'Final_quantity',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 130,
      title: 'Percentage Check',
      field: 'Percentage_check',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 130,
      title: 'PO No.',
      // field: "PO_No",
      field: 'Po_no',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 130,
      title: 'PO Type',
      // field: "PO_Type",
      field: 'Po_type',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 130,
      title: 'Purchase Group',
      field: 'Purchase_group',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 130,
      title: 'Import/Domestic',
      // field: "ImportOrDomestic",
      field: 'Import_domestic',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 130,
      title: 'Contract No.',
      // field: "Contact_No",
      field: 'Contract_no',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 130,
      title: 'Contract Price.',
      field: 'Contract_Price',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 150,
      title: 'Invoice No(R)',
      field: 'Invoice_no_r',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 150,
      title: 'Invoice No(S)',
      field: 'Invoive_no_s',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 150,
      title: 'Supplier Credit Invoices',
      field: 'Supplier_Credit_Invoices',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 150,
      title: 'Supplier Debit Invoices',
      field: 'Supplier_Debit_Invoices',
      enableSearch: true,
      enableFilter: true
    },

    {
      width: 150,
      title: 'Invoice Date(S)',
      field: 'Invoice_date_s',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 150,
      title: 'Invoice Date(R)',
      field: 'Invoice_date_r',
      enableSearch: true,
      enableFilter: true
    },

    {
      width: 150,
      title: 'Invoice UOM(R)',
      field: 'Invoice_uom_r',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 130,
      title: 'Invoice UOM(S)',
      field: 'Invoice_uom_s',
      enableSearch: true,
      enableFilter: true
    },

    {
      width: 130,
      title: 'SAP Invoice Quantity',
      field: 'Sap_invoice_quantity',
      enableSearch: true,
      enableFilter: true
    },

    {
      width: 150,
      title: 'Net Price Per Part',
      field: 'Net_price',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 150,
      title: 'Supplementary Price',
      field: 'Supplementary_price',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 150,
      title: 'Gross Price',
      field: 'Gross_price',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'Total Net Price',
      field: 'Total_net_price',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'Total Gross Price',
      field: 'Total_gross_price',
      enableSearch: true,
      enableFilter: true
    },

    {
      width: 100,
      title: 'HSN Code',
      field: 'HSN_code',
      enableSearch: true,
      enableFilter: true
    },

    {
      width: 100,
      title: 'Billing Currency',
      field: 'Currency',
      enableSearch: true,
      enableFilter: true
    },

    {
      width: 100,
      title: 'IRN Number',
      field: 'Irn_no',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'GSTIN',
      field: 'GSTIN',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'PAN',
      field: 'PAN',
      enableSearch: true,
      enableFilter: true
    },

    {
      width: 100,
      title: 'System Remark',
      field: 'System_Remark',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'PL Remark',
      field: 'PL_Remark',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'BOM Validation-2 Remark',
      field: 'BOM_validation_2_remark',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'Addition Remark',
      field: 'Addition_remark',
      enableSearch: true,
      enableFilter: true
    },
    {
      width: 100,
      title: 'Created Date',
      field: 'Created_date',
      enableSearch: true,
      enableFilter: true
    }
  ]

  /* Old Fetch way */
  // const BOMvalidationAPI = () => {
  //   // BOMvalidationAPIView
  //   console.log('inside submit api: ', Bc?.value);
  //   const apiBody = {
  //     Project_code: selectedProjectCode?.value,
  //     // VC: Bc?.value,
  //     email_to: 'dp928042.ttl@tatamotors.com'
  //   }
  //   console.log('Submit body: ', apiBody);
  //   var body = JSON.stringify(apiBody);
  //   let headerss = new Headers();
  //   headerss.append("Content-Type", "application/json");
  //   // fetch(`${baseUrl}BOMvalidationAPIView/`, {
  //   fetch(`${baseUrl}UpdateStatus_/`, {
  //     method: 'POST',
  //     headers: headerss,
  //     body,
  //   })
  //     .then((response) => response.json())
  //     .then((responseJson) => {
  //       console.log('responseJson', responseJson);
  //       if (responseJson?.message) {
  //       showPopup({
  //         type: MESSAGE_TYPE.SUCCESS,
  //         contextText: MESSAGE_TYPE.SUCCESS,
  //         info: "BOM Validation Successful!",
  //       })
  //       history.push("/");
  //     } else {
  //       showPopup({
  //         type: MESSAGE_TYPE.FAILURE,
  //         contextText: MESSAGE_TYPE.FAILURE,
  //         // info: "BOM Validation Successful!",
  //         info: responseJson?.error,
  //       })
  //     }
  //     })
  //     .catch((error) => {
  //       console.log('error in submit: ', error);
  //     });
  // }
  // const BOMvalidationAPI = () => {
  //   // BOMvalidationAPIView
  //   console.log('inside submit api: ', Bc?.value);
  //   const apiBody = {
  //     Project_code: selectedProjectCode?.value,
  //     ProductLine_validation_date: formattedCurrentDate,
  //     ProductLine_validater_name: userName
  //     // VC: Bc?.value,
  //     // email_to: 'dp928042.ttl@tatamotors.com'
  //   }
  //   var body = JSON.stringify(apiBody);
  //   let headerss = new Headers();
  //   headerss.append("Content-Type", "application/json");
  //   // fetch(`${baseUrl}BOMvalidationAPIView/`, {
  //   // fetch(`${baseUrl}PMTUpdateStatus/`, {
  //   fetch(`${baseUrl}UpdateProductLineStatus/`, {
  //     method: 'POST',
  //     headers: headerss,
  //     body,
  //   })
  //     .then((response) => response.json())
  //     .then((responseJson) => {
  //       console.log('responseJson', responseJson);
  //       showPopup({
  //         type: MESSAGE_TYPE.SUCCESS,
  //         contextText: MESSAGE_TYPE.SUCCESS,
  //         info: "BOM Validation Successful!",
  //       });
  //       history.push("/");
  //     })
  //     .catch((error) => {
  //       console.log('error in submit: ', error);
  //     });
  // }
  /* New axios way */
  const formattedCurrentDate = new Date()
    .toLocaleDateString('en-GB')
    .split('/')
    .join('/')
  const BOMvalidationAPI = () => {
    const apiBody = {
      Project_code: selectedProjectCode?.value,
      // email_to: 'dp928042.ttl@tatamotors.com'
      VC: Bc?.value,
      BOm_validation_date: formattedCurrentDate,
      BOM_validater_name: userName
    }

    // console.log('Submit body: ', apiBody)

    setLoader(true)
    axios
      // .post(`${baseUrl}UpdateStatus/`, apiBody, {
      // .post(`${baseUrl}UpdateProductLineStatus/`, apiBody, {
      // .post(`${baseUrl}UpdatePLStatus/`, apiBody, {
      .post(`${baseUrl}BomValidater2Status/`, apiBody, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        const responseJson = response.data
        console.log('responseJson', responseJson)
        if (responseJson?.message) {
          setLoader(false)
          showPopup({
            type: MESSAGE_TYPE.SUCCESS,
            contextText: MESSAGE_TYPE.SUCCESS,
            info: 'BOM Validation Successful!'
          })
          history.push('/')
        } else {
          setLoader(false)
          showPopup({
            type: MESSAGE_TYPE.FAILURE,
            contextText: MESSAGE_TYPE.FAILURE,
            info: responseJson?.error
          })
        }
      })
      .catch(error => {
        setLoader(false)
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log('Error response:', error.response)
          if (error.response.status === 400) {
            showPopup({
              type: MESSAGE_TYPE.FAILURE,
              contextText: MESSAGE_TYPE.FAILURE,
              info: 'Bad Request: Please check your input!'
            })
          } else if (error.response.status === 404) {
            showPopup({
              type: MESSAGE_TYPE.FAILURE,
              contextText: MESSAGE_TYPE.FAILURE,
              info: 'Validation Failed'
            })
          } else {
            showPopup({
              type: MESSAGE_TYPE.FAILURE,
              contextText: MESSAGE_TYPE.FAILURE,
              info: `Error: ${error.response.statusText}`
            })
          }
        } else if (error.request) {
          // The request was made but no response was received
          console.log('Error request:', error.request)
          showPopup({
            type: MESSAGE_TYPE.FAILURE,
            contextText: MESSAGE_TYPE.FAILURE,
            info: 'No response received from server!'
          })
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error message:', error.message)
          showPopup({
            type: MESSAGE_TYPE.FAILURE,
            contextText: MESSAGE_TYPE.FAILURE,
            info: `Error: ${error.message}`
          })
        }
        console.log('error in submit: ', error)
      })
    setLoader(false)
  }

  const goNextForm = () => {
    // var url;
    // var urlMethod;
    // let { userRole, userID } = auth;
    // if (userRole.includes("Purchase") || userRole.includes("Purchase-CV")) {
    //   url = "SupplierDataaddition";
    //   urlMethod = "POST";
    // }
    // const apiFormatedData = {
    //   VC: Bc?.value,
    // };
    // var apiFormatedDataJson = JSON.stringify(apiFormatedData);
    // let headerss = new Headers();
    // headerss.append("Content-Type", "application/json");
    // fetch(`${baseUrl}${url}/`, {
    //   method: urlMethod,
    //   headers: headerss,
    //   body: apiFormatedDataJson,
    // })
    //   .then((response) => response.json())
    //   .then((responseJson) => {
    //     showPopup({
    //       type: MESSAGE_TYPE.SUCCESS,
    //       contextText: MESSAGE_TYPE.SUCCESS,
    //       info: "Data is Succesfully Saved",
    //     });
    //     history.push("/");
    //   })
    //   .catch((error) => {});
  }

  // const redirectToCreateProjectPage = () => history.push("/");

  const renderHTML = () => (
    <div className={styles.formGroup}>
      {loading && (
        <div className={styles.gridLoader}>
          <GridLoadingSpinner />
        </div>
      )}
      <Grid
        container
        spacing={1}
        style={{ display: 'flex', justifyContent: 'left' }}
        columns={12}
      >
        <Grid
          item
          // sm={12}
          md={3}
          lg={3}
          xs={3}
          style={{
            marginTop: '5px',
            marginBottom: '5px',
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          {/* <div className={styles.rowDiv}> */}
          <div className={clsx(styles.lableDiv)} style={{ width: '36%' }}>
            <label className={styles.label} style={{ width: '' }}>
              Project Code
            </label>
          </div>
          <div className={styles.formDiv} style={{ width: '54%' }}>
            <CustomSelect
              name='Project_Code'
              options={projectCodeArray}
              className={clsx(styles.select, styles.sel1)}
              value={selectedProjectCode}
              isMulti={false}
              defaultValue={latestProject}
              isClearable
              isMandatory
              onChange={selectedOption => {
                handleDropdownChange(selectedOption)
                setSelectedProjectCode(selectedOption)
                // if (selectedOption !== null) {
                // getVcForProjectCode(selectedOption)
                // }
                // getVcForProjectCode(selectedOption); // Fetch VC for selected project code

                // const selectedValue = selectedOption.target.value;
                // const selectedLabel = options.find(option => option.value === selectedValue)?.label || ''
                // console.log('selectedLabel: ', selectedLabel)
                // setSelectedOption({ value: selectedValue, label: selectedLabel })
              }}
              style={{ width: '100% !important' }}
            />
          </div>
          {/* </div> */}
        </Grid>

        <Grid
          item
          xs={3}
          md={3}
          lg={3}
          // sm={12}
          style={{
            marginTop: '5px',
            marginBottom: '5px',
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          {/* <div className={styles.rowDiv}> */}
          <div className={clsx(styles.lableDiv)} style={{ width: '7%' }}>
            <label className={styles.label}>VC</label>
          </div>
          <div className={styles.formDiv} style={{ width: '78%' }}>
            <CustomSelect
              name='bc'
              options={bcArray}
              // isDisabled={checkDisable}
              className={clsx(styles.select, styles.sel1)}
              value={Bc}
              isMulti={false}
              isClearable
              onChange={selectedOption => {
                setBC(selectedOption)
              }}
            />
          </div>
          {/* </div> */}
        </Grid>

        {/* <Grid item md={2} lg={2} sm={12}>
         */}

        {/* Project Desc. View */}
        <Grid
          item
          xs={4}
          md={4}
          lg={4}
          // sm={12}
          style={{
            marginTop: '5px',
            marginBottom: '5px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <div className={styles.rowDiv}>
            <div className={clsx(styles.lableDiv)} style={{ width: '40%' }}>
              <label className={styles.label}>Project Desc.</label>
            </div>
            {/* <div className={styles.formDiv}>
              <Grid item xs={3} className={styles.alignCenter}> */}
            <div className={styles.formDiv}>
              <ValidatingTextField
                variant='outlined'
                size='small'
                fullWidth
                // value={vendorCode?.vendorCode}
                // value={recentProjectCodeDescription && recentProjectCodeDescription}
                value={projectDescription && projectDescription}
                name='projDesc'
                inputProps={{
                  'data-testid': 'remark-input'
                }}
                onChange={selectedOption =>
                  handleDropdownChange(selectedOption)
                }
                // eslint-disable-next-line react/jsx-boolean-value
                isDisabled={true}
              />
            </div>
            {/* <span className={styles.symbolCSS}>&#x20b9;</span> */}
            {/* </Grid> */}
            {/* </div> */}
          </div>
        </Grid>

        {/* Show Data Button Grid */}
        <Grid
          it2m
          xs={2}
          md={2}
          // lg={4}
          // sm={12}
          style={{
            marginTop: '5px',
            marginBottom: '5px',
            display: 'flex',
            justifyContent: 'end',
            alignItems: 'center'
          }}
        >
          <div
            style={{
              marginLeft: '5px',
              paddingRight: '10px',
              marginTop: '1px',
              display: 'flex',
              justifyContent: 'left'
            }}
          >
            <AuthChecker operation='search'>
              {isAuthorized => (
                <Button
                  className={clsx(
                    styles.actionButton,
                    styles.primaryActionButton
                  )}
                  style={{ marginRight: '12px !important' }}
                  variant='primary'
                  onClick={showTable}
                  disabled={!isAuthorized}
                  data-testid='confirm-action'
                >
                  Show Data
                </Button>
              )}
            </AuthChecker>

            {/* {!auth.userRole.includes("Vendor") ? (
              <AuthChecker operation="search">
                {(isAuthorized) => (
                  <Button
                    className={clsx(
                      styles.actionButton,
                      styles.primaryActionButton
                    )}
                    style={{
                      marginRight: "12px !important",
                      marginLeft: "5px",
                    }}
                    variant="primary"
                    onClick={() => {
                      getAllData();
                    }}
                    disabled={!isAuthorized}
                    data-testid="confirm-action"
                  >
                    Show All
                  </Button>
                )}
              </AuthChecker>
            ) : null} */}

            {/* {
      loader==false&&
  
        <>
      <AuthChecker operation="download">

        {isAuthorized => (
             <Button
               className={clsx(
                 styles.actionButton,
                 styles.primaryActionButton,
               )}
               variant="primary"
               //   onClick={() => runPreSubmissionChecks() && handleSubmit()}
               onClick={()=>{

                if(tableArray.length>=1){exportExcel(tableArray);}
               }}
                disabled={!isAuthorized}
               data-testid="confirm-action"
               style={{marginLeft:"5px"}}

             >
               download
             </Button>
           )}
 
         </AuthChecker>
     
     </> 
  } */}
          </div>
        </Grid>
      </Grid>
    </div>
  )

  // const uploadDocument = () => {
  //   var input = document.createElement("input");
  //   input.type = "file";
  //   input.id = "file";
  //   input.setAttribute("accept", ".xlsx");
  //   input.click();

  //   input.onchange = (e) => {
  //     const formData = new FormData();
  //     formData.append("file", e.target.files[0]);
  //     // formData.append("Supplier_Code", Bc?.value);
  //     // formData.append('Project_code', projectCode?.value)
  //     // formData.append('')
  //     // fetch(`${baseUrl}UpdateMasterData/`, {
  //     // fetch(`${baseUrl}ProductLineBOMData/`, {
  //     // fetch(`${baseUrl}UploadProductlineData/`, {
  //     fetch(`${baseUrl}UploadPLIdata/`, {
  //       // method: "PUT",
  //       method: "POST",
  //       body: formData,
  //     })
  //       // .then((response) => {
  //       //   // console.log(response)
  //       //   // return response.json()
  //       // }

  //       .then((response) => {
  //         console.log('file upload res: ', response);
  //         if (response.status == 200) {
  //           showPopup({
  //             type: MESSAGE_TYPE.SUCCESS,
  //             //  contextText:getUrl(certificateUrl),
  //             contextText: MESSAGE_TYPE.SUCCESS,
  //             info: "Document Uploaded Successfully",
  //           });
  //           // return response.json()
  //           getTableData();
  //         } else if (response.status == 400) {
  //           showPopup({
  //             type: MESSAGE_TYPE.FAILURE,
  //             contextText: MESSAGE_TYPE.FAILURE,
  //             info: `${response.data.status}`,
  //           });
  //         } else {
  //           showPopup({
  //             type: MESSAGE_TYPE.FAILURE,
  //             contextText: MESSAGE_TYPE.FAILURE,
  //             info: `Server error: ${response.data.status}`,
  //           });
  //         }
  //       })
  //       // .then((responseJson) => {
  //       //   console.log(responseJson);
  //       // //  var certificateurlstampurl = 'https://tml-test-digital-invoice-s3.s3.amazonaws.com/Certificate%20%2838%29_23-12-2022T11%3A02%3A38.pdf?response-content-disposition=attachment&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAQGDNMWVUMVQVJ4YB%2F20221223%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20221223T110240Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=4da98e95872b5a7d4f2b15a2f524ce1d18178ba15b0923a4ca1b8605ad79bd1a';

  //       //   if (responseJson) {
  //       //     showPopup({
  //       //       type: MESSAGE_TYPE.SUCCESS,
  //       //       //  contextText:getUrl(certificateUrl),
  //       //         contextText:MESSAGE_TYPE.SUCCESS,
  //       //       info:"Document Uploaded Successfully"

  //       //     });
  //       //   } else {
  //       //     showPopup({
  //       //       type: MESSAGE_TYPE.FAILURE,
  //       //       contextText: MESSAGE_TYPE.FAILURE,
  //       //        info: responseJson.url,
  //       //     });
  //       //   }

  //       //   // eslint-disable-next-line radix
  //       //   // let status1 = parseInt(response.status);
  //       //   //  console.log(response.status)
  //       //   // if (status1 == "200") {
  //       //   //   //   document.removeChild(input);
  //       //   // //  getTableData();

  //       //   // }
  //       // })

  //       .catch((error) => {
  //         showPopup({
  //           type: MESSAGE_TYPE.FAILURE,
  //           contextText: MESSAGE_TYPE.FAILURE,
  //           info: error,
  //         });
  //       });
  //   };
  // };

  const uploadDocument = () => {
    var input = document.createElement('input')
    input.type = 'file'
    input.id = 'file'
    input.setAttribute('accept', '.xlsx')
    input.click()

    input.onchange = e => {
      const formData = new FormData()
      formData.append('file', e.target.files[0])
      // formData.append("Supplier_Code", Bc?.value);
      // formData.append('Project_code', projectCode?.value)
      // formData.append('')

      // axios.post(`${baseUrl}UploadPLIdata/`, formData)
      setLoader(true)
      axios
        .post(`${baseUrl}UploadBomvalidater2Data/`, formData)
        .then(response => {
          // console.log('file upload res: ', response);
          if (response.status === 200 || response.status === 201) {
            setLoader(false)
            showPopup({
              type: MESSAGE_TYPE.SUCCESS,
              // contextText: getUrl(certificateUrl),
              contextText: MESSAGE_TYPE.SUCCESS,
              info: 'Document Uploaded Successfully'
            })
            getTableData()
          } else if (response.status === 400) {
            showPopup({
              type: MESSAGE_TYPE.FAILURE,
              contextText: MESSAGE_TYPE.FAILURE,
              info: `${response.data ? response.data.status : 'Bad Request'}`
            })
          } else {
            showPopup({
              type: MESSAGE_TYPE.FAILURE,
              contextText: MESSAGE_TYPE.FAILURE,
              info: `Server error: ${
                response.data ? response.data.status : response.status
              }`
            })
          }
        })
        .catch(error => {
          setLoader(false)
          showPopup({
            type: MESSAGE_TYPE.FAILURE,
            contextText: MESSAGE_TYPE.FAILURE,
            info: error.message
          })
        })
    }
  }

  const secondaryActions = [
    {
      name: 'Upload',
      authOperation: 'upload',
      // shouldEnable: () => isLatestProjectSelected, // Enable only if the latest project is selected
      shouldEnable: () => submitButtonFlag(),
      actionFn: () => {
        uploadDocument()
      },
      customClass: ''
    },

    {
      name: 'Download',
      authOperation: 'download',
      shouldEnable: selected => true,
      actionFn: selected => {
        // getDataForExcel()
        exportExcel(tableArray)
      },
      customClass: ''
    }
  ]

  const handleSecondaryActionOnRole = () => {
    if (auth.userRole.includes('admin')) {
      return secondaryActions
    }
    if (auth.userRole.includes('GDC') || auth.userRole.includes('TMLBSL')) {
      return secondaryActions.filter(
        item =>
          item.name === 'Download Documents' ||
          item.name === 'Download Certificate' ||
          item.name === 'Upload'
      )
    }
    if (auth.userRole.includes('Vendor')) {
      return secondaryActions.filter(
        item =>
          item.name !== 'Download Documents' &&
          item.name !== 'Download Certificate'
      )
    }
    if (auth.userRole.includes('Purchase')) {
      return secondaryActions.filter(
        item =>
          item.name === 'Download Documents' ||
          item.name === 'Download Certificate'
      )
    }
    return secondaryActions
  }

  return (
    <>
      <div className={styles.container} style={{ boxSizing: 'border-box' }}>
        <div>
          <div
            className={styles.backButtonLastRefreshedDateWrapper}
            style={{ marginTop: '10px' }}
          >
            <BackButton
              action='Back'
              handleClick={redirectToApplicationLandingPage}
              style={{ marginBottom: '0px' }}
            />
            <div
              style={{ width: '100%' }}
              // style={{ display: 'flex', flexDirection: 'row-reverse' }}
            >
              <div className={styles.topDiv}>
                <div
                  className={styles.processHeader}
                  style={{ display: 'flex', height: '3em' }}
                >
                  <div style={{ width: '100%' }}>
                    <CustomTab
                      title='BOM Validation - 2'
                      withOutCount
                      isSelected
                    />
                  </div>

                  {/* <StepBar1 enable={5} /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className={styles.formContainer}>
            <CustomFormGroup body={renderHTML()} />
          </div>
        </div>
        {flag && (
          <div
            className={styles.tableView}
            style={{ height: '86%', padding: '0 2em' }}
          >
            <Table
              columns={
                !auth.userRole.includes('Vendor')
                  ? [...baseDefaultColumns]
                  : [...baseDefaultColumns]
              }
              defaultColumns={[...baseDefaultColumns]}
              rowHeight={38}
              rows={tableArray}
              primaryAction={{
                name: 'Submit Data',
                authOperation: 'submit',
                // shouldEnable: () => isLatestProjectSelected, // Enable only if the latest project is selected
                shouldEnable: () => submitButtonFlag(),
                actionFn: handleOpenFormModal
              }}
              secondaryActions={secondaryActions}
              setAsnCount={setCount}
              isDataLoading={loader}
              actionButtons
              onRowSelect={e => {}}
              removeRowSelection
            />
          </div>
        )}
      </div>
      {/* <Button
            className={clsx(
              styles.actionButton,
              styles.primaryActionButton,
            )}
            variant="primary"
            // onClick={onDelete}
          >
            Yes
          </Button> */}
      <ResetModalF
        isPopupOpen={isPopupOpenFormModal}
        handleCloseFormModal={handleCloseFormModal}
        BOMvalidationAPI={BOMvalidationAPI}
      />

      <ResetModal
        isPopupOpen={isPopupOpen}
        handleClose={handleClose}
        goNext={goNext}
      />
    </>
  )
}
function ResetModalF ({ isPopupOpen, handleCloseFormModal, BOMvalidationAPI }) {
  return (
    <Dialog
      open={isPopupOpen}
      className={styles.popContainer}
      classes={{
        paper: styles.popupBox
      }}
      data-testid='reset-popup'
    >
      <>
        <DialogTitle>
          <span className={styles.title}>
            <WarningIcon style={{ height: '18px', width: '18px' }} />
            <span style={{ marginLeft: '8px' }} className={styles.txt}>
              Confirm Validation
            </span>
          </span>
        </DialogTitle>
        <DialogContent className={styles.content}>
          <div>
            {/* <p> On confirmation, All existing data will be overwritten by the uploaded data.</p> */}
            <p>On confirmation, BOM will be validated</p>

            <p>Are you sure ?</p>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            className={clsx(styles.actionButton, styles.transparentButton)}
            onClick={handleCloseFormModal}
          >
            Cancel
          </Button>
          <Button
            className={clsx(styles.actionButton, styles.primaryActionButton)}
            variant='primary'
            onClick={e => {
              e.preventDefault()
              handleCloseFormModal()
              BOMvalidationAPI()
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </>
    </Dialog>
  )
}
function ResetModal ({ isPopupOpen, handleClose, goNext }) {
  return (
    <Dialog
      open={isPopupOpen}
      className={styles.popContainer}
      classes={{
        paper: styles.popupBox
      }}
      data-testid='reset-popup'
    >
      <>
        <DialogTitle>
          <span className={styles.title}>
            <WarningIcon style={{ height: '18px', width: '18px' }} />
            <span style={{ marginLeft: '8px' }} className={styles.txt}>
              Confirm file Upload
            </span>
          </span>
        </DialogTitle>
        <DialogContent className={styles.content}>
          <div>
            <p> On confirmation ,Records will be appended.</p>
            <p>Are you sure ?</p>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            className={clsx(styles.actionButton, styles.transparentButton)}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            className={clsx(styles.actionButton, styles.primaryActionButton)}
            variant='primary'
            onClick={e => {
              e.preventDefault()
              handleClose()
              goNext()
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </>
    </Dialog>
  )
}
ResetModalF.propTypes = {
  isPopupOpen: PropTypes.bool.isRequired,
  handleCloseFormModal: PropTypes.func.isRequired,
  BOMvalidationAPI: PropTypes.func.isRequired
}

ResetModal.propTypes = {
  isPopupOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  goNext: PropTypes.func.isRequired
}

export default withAllowedOperationsProvider(VendarData, RESOURCE_TYPE.PROJECT)
