/* eslint-disable react/self-closing-comp */
/* eslint-disable vars-on-top */
/* eslint-disable eqeqeq */
/* eslint-disable prefer-const */
/* eslint-disable no-var */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-use-before-define */
/* eslint-disable array-callback-return */
/* eslint-disable camelcase */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import {
  Modal,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Form,
  Box,
  TextField,
  Grid,
  FormGroup,
  IconButton
} from '@material-ui/core'
import WarningIcon from '@mui/icons-material/Warning'
import FormLabel from '@mui/material/FormLabel'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import * as FileSaver from 'file-saver'
import XLSX from 'sheetjs-style'
// for print user in consol
import jwt_decode from 'jwt-decode'
import { useSelector } from 'react-redux'
// for print user in consol
import axios from 'axios'

import clsx from 'clsx'
// import $ from 'jquery';
import { useHistory } from 'react-router-dom'
// import Table from '../../components/Table/Table'
import styles from './UserManual.module.css'
import { CustomFormGroup, CustomSelect } from '../../components/FormComponents'
import BackButton from '../../components/BackButton/BackButton'
import {
  DATE_FORMAT,
  API_RESOURCE_URLS,
  DISPLAY_MESSAGES,
  MESSAGE_TYPE,
  MESSAGE_TYPE_CERTIFICATION,
  MESSAGE_TYPE_ANNEXURE,
  RESOURCE_TYPE,
  USER_OPERATIONS,
  Regex
} from '../../constants'
import { withAllowedOperationsProvider } from '../../hocs'
import { CustomTab, Button, AuthChecker } from '../../atomicComponents'
import { API } from '../../apis/api'
import { buildErrorMessage } from '../../apis/calls'
import { usePopupManager } from '../../providers/PopupManager/PopupManager'

import StepBar1 from '../../components/StepBar1/StepBar'
import { GridLoadingSpinner } from '../../components'

const PartBuyerMapping = () => {
  const history = useHistory()
  const { showPopup } = usePopupManager()
  // const baseUrl='http://127.0.0.1:8000/file/'
  const baseUrl = useSelector(state => state.counter.baseUrl) // useSelector
  const auth = useSelector(state => state.auth) // useSelector
  console.log('auth in Supplier: ', auth)
  const [dynamicColumns, setDynamicColumns] = useState([])
  const [dynamicColumns1, setDynamicColumns1] = useState([])
  const [plant, setPlant] = useState(null)
  const [flag, show] = useState(false)
  const [checkDisable, setDisable] = useState(true)
  const [projectCode, setProjectCode] = useState(null)
  const [projectCodeArray, setProjectCodeArray] = useState([])
  const [isLatestProjectSelected, setIsLatestProjectSelected] = useState(false) // New state for button enable/disable
  // const [projectCodeArray, setProjectCodeArray] = useState([]);
  const [selectedProjectCode, setSelectedProjectCode] = useState(null)
  console.log('Project state: ', selectedProjectCode)
  const [latestProject, setLatestProject] = useState('')
  const [Bc, setBC] = useState(null)
  const [bcArray, setBcArray] = useState([])
  const [plantArray, setPlantArray] = useState([])
  const [tableArray, setTableArray] = useState([])
  const [buyerPartMappingArray, setBuyerPartMappingArray] = useState([])
  const [fyArray, setFyArray] = useState([])
  const [Fy, setFY] = useState(null)
  const [quarterArray, setQuarterArray] = useState([])
  const [quarter, setQuarter] = useState(null)
  const [asnCount, setAsnCount] = useState(0)
  const [loading, setLoader] = useState(false)
  const [loader, setLoading] = useState(true)

  //
  const [isPopupOpenFormModal, setPopupOpenFormModal] = useState(false)
  const handleOpenFormModal = () => {
    setPopupOpenFormModal(true)
  }
  const handleCloseFormModal = () => setPopupOpenFormModal(false)

  //

  const ROW_HEIGHT = 38

  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const handleOpen = () => setIsPopupOpen(true)
  const handleClose = () => setIsPopupOpen(false)
  const goNext = () => {
    // uploadFileData()
  }

  const redirectToApplicationLandingPage = () => {
    history.push('/')
  }

  const setCount = count => {
    setAsnCount(count)
  }

  

  const BOMvalidationAPI = () => {
    console.log('inside submit api: ', selectedProjectCode?.value)

    if (!selectedProjectCode?.value) {
      console.error('Project code is missing.')
      showPopup({
        type: MESSAGE_TYPE.FAILURE,
        contextText: MESSAGE_TYPE.FAILURE,
        info: 'Project code is required.'
      })
      return
    }

    const apiBody = {
      Project_code: selectedProjectCode.value
    }
    setLoader(true)
    axios
      .post(`${baseUrl}BuyerPartMappingSubmissionstatus/`, apiBody, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        console.log('responseJson', response.data)
        if (response.data.error) {
          throw new Error(response.data.error)
        }
        showPopup({
          type: MESSAGE_TYPE.SUCCESS,
          contextText: MESSAGE_TYPE.SUCCESS,
          info: 'Part Buyer Mapping Submitted Successfully'
        })
        history.push('/')
      })
      .catch(error => {
        let errorMessage = error.response?.data?.details
          ? JSON.parse(error.response.data.details).messages
          : error.response?.data?.error || error.message
        console.error('Error in submit:', errorMessage)
        showPopup({
          type: MESSAGE_TYPE.FAILURE,
          contextText: MESSAGE_TYPE.FAILURE,
          info: `Error: ${errorMessage}`
        })
      })
    setLoader(false)
  }


const renderHTML = () => (
    <div className={styles.formGroup}>
      {loading && (
        <div className={styles.gridLoader}>
          <GridLoadingSpinner />
        </div>
      )}
      <TableContainer
       style={{
        border: '2px solid #ccc', 
        borderRadius: '8px', 
        overflow: 'hidden',
        width: '50%' ,
        position: 'absolute', 
        top: '50%', 
        left: '50%', 
        transform: 'translate(-50%, -50%)', 
        background: ' rgb(255, 255, 255)'
      }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold', padding: '8px' }}>File Names</TableCell>
              <TableCell align="right" style={{ fontWeight: 'bold', padding: '8px', paddingRight: '8px' }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {/* Row for User Manual AFS */}
            <TableRow>
              <TableCell style={{ padding: '8px' }}>User Manual AFS</TableCell>
              <TableCell align="right" style={{ padding: '8px' }}>
                <AuthChecker operation="search">
                  {isAuthorized => (
                    <button
                      type="button"
                      aria-label="Download User Manual AFS"
                      style={{
                        cursor: isAuthorized ? 'pointer' : 'not-allowed',
                        color: isAuthorized ? '#1976d2' : '#ccc',
                        background: 'none',
                        border: 'none',
                        padding: 0,
                      }}
                      disabled={!isAuthorized}
                      title="Download User Manual AFS"
                      onClick={() => {
                        if (isAuthorized) {
                          const link = document.createElement('a');
                          link.href = `${process.env.PUBLIC_URL}/UserGuideAFS.docx`;
                          link.download = 'UserGuideAFS.docx';
                          link.click();
                        }
                      }}
                    >
                      <DownloadIcon />
                    </button>
                  )}
                </AuthChecker>
              </TableCell>
            </TableRow>
            {/* Row for User Guide DVA and Declaration */}
            <TableRow>
              <TableCell style={{  padding: '8px' }}>User Guide DVA and Declaration</TableCell>
              <TableCell align="right" style={{ padding: '8px' }}>
                <AuthChecker operation="search">
                  {isAuthorized => (
                    <button
                      type="button"
                      aria-label="Download User Guide DVA and Declaration"
                      style={{
                        cursor: isAuthorized ? 'pointer' : 'not-allowed',
                        color: isAuthorized ? '#1976d2' : '#ccc',
                        background: 'none',
                        border: 'none',
                        padding: 0,
                      }}
                      disabled={!isAuthorized}
                      title="Download User Guide DVA and Declaration"
                      onClick={() => {
                        if (isAuthorized) {
                          const link = document.createElement('a');
                          link.href = `${process.env.PUBLIC_URL}/UserGuideDvaAndDeclaration.docx`;
                          link.download = 'UserGuideDvaAndDeclaration.docx';
                          link.click();
                        }
                      }}
                    >
                      <DownloadIcon />
                    </button>
                  )}
                </AuthChecker>
              </TableCell>
            </TableRow>
            {/* Row for User Guide Supplier Under PLI */}
            <TableRow>
              <TableCell style={{ padding: '8px', marginLeft: '10px' }}>User Guide Supplier Under PLI</TableCell>
              <TableCell align="right" style={{ padding: '8px' }}>
                <AuthChecker operation="search">
                  {isAuthorized => (
                    <button
                      type="button"
                      aria-label="Download User Guide Supplier Under PLI"
                      style={{
                        cursor: isAuthorized ? 'pointer' : 'not-allowed',
                        color: isAuthorized ? '#1976d2' : '#ccc',
                        background: 'none',
                        border: 'none',
                        padding: 0,
                      }}
                      disabled={!isAuthorized}
                      title="Download User Guide Supplier Under PLI"
                      onClick={() => {
                        if (isAuthorized) {
                          const link = document.createElement('a');
                          link.href = `${process.env.PUBLIC_URL}/UserGuideSupplierUnderPLI.docx`;
                          link.download = 'UserGuideSupplierUnderPLI.docx';
                          link.click();
                        }
                      }}
                    >
                      <DownloadIcon />
                    </button>
                  )}
                </AuthChecker>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ padding: '8px', marginLeft: '10px' }}>DVA SOP PLI Auto</TableCell>
              <TableCell align="right" style={{ padding: '8px' }}>
                <AuthChecker operation="search">
                  {isAuthorized => (
                    <button
                      type="button"
                      aria-label="DVA SOP PLI Auto"
                      style={{
                        cursor: isAuthorized ? 'pointer' : 'not-allowed',
                        color: isAuthorized ? '#1976d2' : '#ccc',
                        background: 'none',
                        border: 'none',
                        padding: 0,
                      }}
                      disabled={!isAuthorized}
                      title="DVA SOP PLI Auto"
                      onClick={() => {
                        if (isAuthorized) {
                          const link = document.createElement('a');
                          link.href = `${process.env.PUBLIC_URL}/DVA_SOP_PLIAuto.pdf`;
                          link.download = 'DVA_SOP_PLIAuto.pdf';
                          link.click();
                        }
                      }}
                    >
                      <DownloadIcon />
                    </button>
                  )}
                </AuthChecker>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
  
  
  
  const secondaryActions = [
    {
      name: 'Upload',
      authOperation: 'upload',
      shouldEnable: selected => true,
      actionFn: () => {
        // uploadFileData()
      },
      customClass: ''
    },

    {
      name: 'Download',
      authOperation: 'download',
      shouldEnable: selected => true,
      actionFn: selected => {
        //  getDataForExcel()
        // exportExcel(tableArray)
      },
      customClass: ''
    }
  ]

  return (
    <>
      <div className={styles.container} style={{ boxSizing: 'border-box' }}>
        <div>
          <div
            className={styles.backButtonLastRefreshedDateWrapper}
            style={{ marginTop: '10px' }}
          >
            <BackButton
              action='Back'
              handleClick={redirectToApplicationLandingPage}
              style={{ marginBottom: '0px' }}
            />
            <div
              style={{ width: '100%' }}
              // style={{ display: 'flex', flexDirection: 'row-reverse' }}
            >
              <div className={styles.topDiv}>
                <div
                  className={styles.processHeader}
                  style={{ display: 'flex', height: '3em' }}
                >
                  <div style={{ width: '100%' }}>
                    <CustomTab
                      title='User Manuals and General Guidelines'
                      withOutCount
                      isSelected
                    />
                  </div>

                  {/* <StepBar1 enable={5} /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className={styles.formContainer}>
            <CustomFormGroup body={renderHTML()} />
          </div>
        </div>
        {flag && (
          <div
            className={styles.tableView}
            style={{ height: '86%', padding: '0 2em' }}
          >
            <Table
              columns={
                !auth.userRole.includes('Vendor')
                  // ? [...baseDefaultColumns]
                  // : [...baseDefaultColumns]
              }
              // defaultColumns={[...baseDefaultColumns]}
              rowHeight={38}
              rows={tableArray}
              primaryAction={{
                name: 'Submit',
                authOperation: 'submit',
                // shouldEnable: () => isLatestProjectSelected, // Enable only if the latest project is selected
                shouldEnable: selected => true,
                actionFn: handleOpenFormModal
              }}
              secondaryActions={secondaryActions}
              setAsnCount={setCount}
              isDataLoading={loader}
              actionButtons
              onRowSelect={e => {}}
              removeRowSelection
            />
          </div>
        )}
      </div>
      {/* <Button
            className={clsx(
              styles.actionButton,
              styles.primaryActionButton,
            )}
            variant="primary"
            // onClick={onDelete}
          >
            Yes
          </Button> */}
      <ResetModalF
        isPopupOpen={isPopupOpenFormModal}
        handleCloseFormModal={handleCloseFormModal}
        BOMvalidationAPI={BOMvalidationAPI}
      />

      <ResetModal
        isPopupOpen={isPopupOpen}
        handleClose={handleClose}
        goNext={goNext}
      />
    </>
  )
}
function ResetModalF ({ isPopupOpen, handleCloseFormModal, BOMvalidationAPI }) {
  return (
    <Dialog
      open={isPopupOpen}
      className={styles.popContainer}
      classes={{
        paper: styles.popupBox
      }}
      data-testid='reset-popup'
    >
      <>
        <DialogTitle>
          <span className={styles.title}>
            <WarningIcon style={{ height: '18px', width: '18px' }} />
            <span style={{ marginLeft: '8px' }} className={styles.txt}>
              Confirm Validation
            </span>
          </span>
        </DialogTitle>
        <DialogContent className={styles.content}>
          <div>
            {/* <p> On confirmation, All existing data will be overwritten by the uploaded data.</p> */}
            <p>On confirmation, Part Buyer Mapping Will be Submitted.</p>

            <p>Are you sure ?</p>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            className={clsx(styles.actionButton, styles.transparentButton)}
            onClick={handleCloseFormModal}
          >
            Cancel
          </Button>
          <Button
            className={clsx(styles.actionButton, styles.primaryActionButton)}
            variant='primary'
            onClick={e => {
              e.preventDefault()
              handleCloseFormModal()
              BOMvalidationAPI()
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </>
    </Dialog>
  )
}
function ResetModal ({ isPopupOpen, handleClose, goNext }) {
  return (
    <Dialog
      open={isPopupOpen}
      className={styles.popContainer}
      classes={{
        paper: styles.popupBox
      }}
      data-testid='reset-popup'
    >
      <>
        <DialogTitle>
          <span className={styles.title}>
            <WarningIcon style={{ height: '18px', width: '18px' }} />
            <span style={{ marginLeft: '8px' }} className={styles.txt}>
              Confirm file Upload
            </span>
          </span>
        </DialogTitle>
        <DialogContent className={styles.content}>
          <div>
            <p> On confirmation ,Records will be appended.</p>
            <p>Are you sure ?</p>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            className={clsx(styles.actionButton, styles.transparentButton)}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            className={clsx(styles.actionButton, styles.primaryActionButton)}
            variant='primary'
            onClick={e => {
              e.preventDefault()
              handleClose()
              goNext()
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </>
    </Dialog>
  )
}
ResetModalF.propTypes = {
  isPopupOpen: PropTypes.bool.isRequired,
  handleCloseFormModal: PropTypes.func.isRequired,
  BOMvalidationAPI: PropTypes.func.isRequired
}

ResetModal.propTypes = {
  isPopupOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  goNext: PropTypes.func.isRequired
}

export default withAllowedOperationsProvider(
  PartBuyerMapping,
  RESOURCE_TYPE.PROJECT
)
