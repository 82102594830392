/* eslint-disable react/self-closing-comp */
/* eslint-disable vars-on-top */
/* eslint-disable eqeqeq */
/* eslint-disable prefer-const */
/* eslint-disable no-var */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-use-before-define */
/* eslint-disable array-callback-return */
/* eslint-disable camelcase */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-lonely-if */
/* eslint-disable object-shorthand */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import {
  Modal,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Form,
  Box,
  TextField,
  Grid,
  FormGroup,
  IconButton,
} from "@material-ui/core";
import WarningIcon from "@mui/icons-material/Warning";
import FormLabel from "@mui/material/FormLabel";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
// for print user in consol
import jwt_decode from "jwt-decode";
import { useSelector } from "react-redux";
// for print user in consol
import axios from "axios";

import clsx from "clsx";
import { useHistory } from "react-router-dom";
// import Table from "../../components/Table/Table";
import Table from "../../components/Table1/Table1";

import styles from "./MpfdPurchaseTmlbsl.module.css";
import { CustomFormGroup, CustomSelect,ValidatingTextField  } from "../../components/FormComponents";
import BackButton from "../../components/BackButton/BackButton";
import {
  DATE_FORMAT,
  API_RESOURCE_URLS,
  DISPLAY_MESSAGES,
  MESSAGE_TYPE,
  MESSAGE_TYPE_CERTIFICATION,
  MESSAGE_TYPE_ANNEXURE,
  RESOURCE_TYPE,
  USER_OPERATIONS,
  Regex,
} from "../../constants";
import { withAllowedOperationsProvider } from "../../hocs";
import { CustomTab, Button, AuthChecker } from "../../atomicComponents";
import { API } from "../../apis/api";
import { buildErrorMessage } from "../../apis/calls";
import { usePopupManager } from "../../providers/PopupManager/PopupManager";
import { GridLoadingSpinner } from "../../components";
import StepBar1 from '../../components/StepBar1/StepBar';
// import { error } from "jquery";

const VendarData = () => {


  const history = useHistory();
  const { showPopup } = usePopupManager();
  // const baseUrl='http://127.0.0.1:8000/file/'
  const baseUrl = useSelector((state) => state.counter.baseUrl); // useSelector
  const auth = useSelector((state) => state.auth); // useSelector
  // console.log('auth in Supplier: ', auth);
  const { userRole, userID, userName : username } = auth
  const [dynamicColumns, setDynamicColumns] = useState([]);
  const [dynamicColumns1, setDynamicColumns1] = useState([]);
  const [plant, setPlant] = useState(null);
  const [flag, show] = useState(false);
  const [checkDisable, setDisable] = useState(true);
  const [Bc, setBC] = useState(null);
  const [projectCode, setProjectCode] = useState(null)
  const [bcArray, setBcArray] = useState([]);
  const [plantArray, setPlantArray] = useState([]);
  const [tableArray, setTableArray] = useState([]);
  const [asnCount, setAsnCount] = useState(0);
  const [Fy, setFY] = useState(null)
  const [fyArray, setFyArray] = useState([])
  const [quarter, setQuarter] = useState(null)
  const [quarterArray, setQuarterArray] = useState([])
  const [projectCodeArray, setProjectCodeArray] = useState([])
  const [latestProject, setLatestProject] = useState("")
  const [selectedProjectCode, setSelectedProjectCode] = useState(null)
  const [recentProjectCodeDescription, setRecentProjectCodeDescription] = useState()
  const [tlmbslSubmit, setTmlbslSubmit] = useState(null)

  const [pliSubmissionStatus, setPliSubmissionStatus] = useState({})
  console.log('pliSubmissionStatus: ', pliSubmissionStatus)  

  // const handleOpen1 = (selected) => {setIsPopupOpen1(true); setSelectedRow(selected)};
  
  const [loading, setLoader] = useState(false);
  const [loader, setLoading] = useState(true);
  const [vendCode, setvendCode] = useState();
  const [datesData, setDates] = useState({
    endDate: "",
    startDate: "",
  });

  
  const [vcArray, setVcArray] = useState([]); // State for VC options
  const [selectedVc, setSelectedVc] = useState(null); // State for selected VC
  // 

  // 
  const [isPopupOpenFormModal, setPopupOpenFormModal] = useState(false);
  const handleOpenFormModal = () => {
    setPopupOpenFormModal(true);
  }
  const handleCloseFormModal = () => setPopupOpenFormModal(false);

  // 

  const ROW_HEIGHT = 38;
  const [isValidatePopupOpen, setIsValidatePopupOpen] = useState(false)
  const handleOpenValidatePopup = () => {
    setIsValidatePopupOpen(true)
  }
  const handleCloseValidatePopup = () => {
    setIsValidatePopupOpen(false)
  }

  const [isSubmissionPopupOpen, setIsSubmissionPopupOpen] = useState(false)
  const handleOpenSubmissionPopup = () => {setIsSubmissionPopupOpen(true)}
  const handleCloseSubmissionPopup = () => {setIsSubmissionPopupOpen(false)}
  const [projectDescription, setProjectDescription] = useState('')
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const handleOpen = () => setIsPopupOpen(true);
  const handleClose = () => setIsPopupOpen(false);
  const goNext = () => {
    uploadFileData();
  };

  const redirectToApplicationLandingPage = () => {
    history.push("/");
  };

  const setCount = (count) => {
    setAsnCount(count);
  };
  // SubmitStatusUpdate

  const isAdminUser = userRole?.includes('admin')
  const isGDCUser = userRole?.includes('TMLBSL')
  const isPurchaseUser = userRole?.includes('Purchase')
  const isVendorUser = userRole?.includes('Vendor')
  const isPMTUser = userRole?.includes('PMT')
  const isProductLineUser = userRole?.includes('Product-line')
  const isBOEUser = userRole?.includes('BOE')

  useEffect(() => {
    // getData()
    getRecentProjectCode()
    getAllProjectCode()
    // getdate();
    //  Uploadcertification();
//   }, [bcArray]);
}, []);

useEffect(() => {

}, [bcArray])
console.log('Supplier BC: ', Bc);


  const apiBody = {
    Project_code: projectCode?.value,
    Supplier_code: Bc?.value,
    Plant_code: plant?.value,
    Financial_year: Fy?.value,
    Quarter: quarter?.value
    }


    const handleDropdownChange = (selectedOption, name) => {
      // console.log('selectedOption: ', selectedOption)
      if (name === 'Project_Code') {
        setSelectedProjectCode(selectedOption)
        getProjectDescription()
        // if (selectedProjectCode !== null) {
        //   getVcForProjectCode(selectedOption)
        // }
      }
      // else if (name === 'projDesc') {
      //   console.log('inside projDesc');
        
      //   setSelectedProjectCode(selectedOption)
      // }
    }

    useEffect(() => {
      if (selectedProjectCode) {
        // getVcForProjectCode(selectedProjectCode)
        getProjectDescription()
      }
    }, [selectedProjectCode]);

    const getProjectDescription = async () => {
      try {
        const headerss = { 'Content-Type': 'application/json' }
        const body = { Project_code: selectedProjectCode?.value }
        const { data } = await axios.post(
          `${baseUrl}ProjectDescriptionView/`,
          body,
          { headers: headerss }
        )
        // console.log('pro desc: ', data)
        setProjectDescription(data?.Project_Description)
      } catch (error) {
        console.error('Error fetching project description:', error)
      }
    }
  
  const getRecentProjectCode = async (projectCodeOptions) => {
    try {
      // const { data, status } = await axios.get(`${baseUrl}LatestProjectCode/`);
      const { data, status } = await axios.get(`${baseUrl}LatestProjectCodeforProductline/`);
      if (status !== 200) {
        throw new Error("Failed to fetch the latest project code");
      }
      console.log('data in Project_code: ', data)
      const recentProj = {
        label: data?.Project_code,
        value: data?.Project_code,
      }
      setLatestProject(recentProj)
      setSelectedProjectCode(recentProj) // Automatically select the latest project code
      setProjectCode(recentProj)
      // getVcForProjectCode(recentProj)
      // const recentProjectCodeDescription = 
      getData(recentProj)
    } catch (error) {
      // console.error("Error fetching recent project codes:", error);
      showPopup({
        type: MESSAGE_TYPE.FAILURE,
        contextText: MESSAGE_TYPE.FAILURE,
        info: 'Could not fetch Project Code!',
      });
    }
  }

  const getAllProjectCode = async () => {
    try {
      // const { data } = await axios.get(`${baseUrl}AllProjectCode/`);
      const { data } = await axios.get(`${baseUrl}AllProjectCodeforProductline/`);
      const allProjectCodes = data.map(({ Project_code }) => Project_code);
      const uniqueProjects = [...new Set(allProjectCodes)];
      const projectCodeOptions = uniqueProjects.map((value) => ({
        value,
        label: value,
      }));

      setProjectCodeArray(projectCodeOptions)
      // const projectDesc = uniqueProjects?.maps((item) => ( item?.Project_code === latestProject))
      const projectDesc = uniqueProjects.map((item) => { 
        console.log('item: ', item); 
        return item?.Project_code === latestProject})
      console.log('projectDesc: ', projectDesc)
      console.log('projectdesc latest: ', latestProject, data)
      // const lastElement = array[array.length - 1];
      const latestProjectCodeDesc = data[data?.length - 1]?.Project_code_Description
      console.log('projectDesc last: ', latestProjectCodeDesc);
      setRecentProjectCodeDescription(latestProjectCodeDesc)
      // await getRecentProjectCode(projectCodeOptions);
      // const recentProjectCodeDescription = 
    } catch (error) {
      console.error("Error fetching project codes:", error);
    }
  }

const showTable = () => {
    // Check if any of the required fields are empty
    if (!projectCode?.value || !Bc?.value ) {
      let message = "";
      
      if (!projectCode?.value) {
        message = "Project Code is Mandatory";
      } else if (!Bc?.value) {
        message = "Supplier Code is Mandatory";
      }
      //  else if (!selectedVc?.value) {
      //   message = "Part Number is Mandatory";
      // }
  
      // Show the popup with the appropriate message
      showPopup({
        type: MESSAGE_TYPE.INFORMATION,
        contextText: MESSAGE_TYPE.INFORMATION,
        info: message,
      });
      return; // Exit if any required field is missing
    }
  
    // If all required fields are present, proceed to fetch data
    // getSupplierDVAstatusForbutton();
   
    getTableData();
    
    show(true); // Show the data table
  };
  





  useEffect(() => {
    if (projectCode?.value && Bc?.value) {
      handleVCForMPFD(); 
    }
  }, [projectCode, Bc]); 

  // const handleVCForMPFD = () => {
    
  //   const payload = {
  //     Project_code: projectCode?.value,
  //     Supplier_code: Bc?.value,
  //   };
  
  //   if (!payload.Project_code || !payload.Supplier_code) {
  //     showPopup({
  //       type: MESSAGE_TYPE.FAILURE,
  //       contextText: MESSAGE_TYPE.FAILURE,
  //       info: 'Project code and Supplier code are required to fetch Part No data.',
  //     });
  //     return;
  //   }
  
  //   axios
  //     .post(`${baseUrl}VCForMPFD/`, payload, {
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //     })
  //     .then((response) => {
  //       console.log('VCForMPFD API Response:', response);
  //       // Filter out items with null VC values and set data for the VC dropdown options
  //       const newVcArray = (response.data || [])
  //         .filter((vcItem) => vcItem.Part_no !== null) 
  //         .map((vcItem) => ({
  //           label: vcItem.Part_no, 
  //           value: vcItem.Part_no, 
  //         }));
        
  //       setVcArray(newVcArray); 
  //     })
  //     .catch((error) => {
  //       console.error('Error while fetching Part No data:', error.response?.data || error);
  //       showPopup({
  //         type: MESSAGE_TYPE.FAILURE,
  //         contextText: MESSAGE_TYPE.FAILURE,
  //         info: 'Error while fetching Part No data.',
  //       });
  //     });
  // };
  

  const handleVCForMPFD = () => {
    const payload = {
      Project_code: projectCode?.value,
      Supplier_code: Bc?.value,
    };
  
    if (!payload.Project_code || !payload.Supplier_code) {
      showPopup({
        type: MESSAGE_TYPE.FAILURE,
        contextText: MESSAGE_TYPE.FAILURE,
        info: 'Project code and Supplier code are required to fetch Part No data.',
      });
      return;
    }
  
    axios
      .post(`${baseUrl}VCForMPFDPV/`, payload, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        console.log('VCForMPFD API Response:', response);
        const newVcArray = (response.data || [])
          .filter((vcItem) => vcItem.Part_no !== null)
          .map((vcItem) => ({
            label: vcItem.Part_no,
            value: vcItem.Part_no,
            description: vcItem.Part_description, // Include description in the object
          }));
  
        setVcArray(newVcArray); // Update VC dropdown options
      })
      .catch((error) => {
        console.error('Error while fetching Part No data:', error.response?.data || error);
        showPopup({
          type: MESSAGE_TYPE.FAILURE,
          contextText: MESSAGE_TYPE.FAILURE,
          info: 'Error while fetching Part No data.',
        });
      });
  };
  console.log("part Description",selectedVc?.description)
  
  // let Vendor_Code ='';
 const handleSelect = (selectedOption, name) => {
    // alert(selectedOption.value);
    // console.log(selectedOption);
    setvendCode(selectedOption.value);
    if (name == "VC") {
      show(false);

      setBC(selectedOption);
      // getPlantData(selectedOption)
    } else {
      setPlant(selectedOption);
    }
  };

const getTableData = () => {
    if (!projectCode?.value || !Bc?.value ) {
      showPopup({
        type: MESSAGE_TYPE.FAILURE,
        contextText: MESSAGE_TYPE.FAILURE,
        info: 'Project code, Supplier code, and Part No are required to fetch data.',
      });
      return; 
    }
  
    setLoading(true); 
   const apiFormatedData = {
      Project_code: projectCode?.value,
      Supplier_code: Bc?.value,
      Part_no: selectedVc?.value, 
    };
  
    const apiFormatedDataJson = JSON.stringify(apiFormatedData);
  
    // console.log('Payload being sent:', apiFormatedDataJson);
  
    let headerss = new Headers();
    headerss.append("Content-Type", "application/json");
  
     const url = 'MpfdDownloadFromS3PV';
  
    fetch(`${baseUrl}${url}/`, {
      method: "POST",
      headers: headerss,
      body: apiFormatedDataJson,
    })
      .then((response) => {
        
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((responseJson) => {
        console.log('MpfdDownloadFromS3 API Response:', responseJson);
        const { Project_code, Supplier_code, Part_no, files } = responseJson;
        const tableData = files || []; 
  
        const formattedTableData = tableData.map((file, index) => ({
            id: index + 1,
            projectCode: Project_code, 
            supplierCode: Supplier_code,
            partNo: Part_no,
            fileName: file.file_name,
            fileUrl: file.url,
        }));
        
       setTableArray(formattedTableData);
      })
      .catch((error) => {
        console.error('Error while fetching table data:', error);
        showPopup({
          type: MESSAGE_TYPE.INFORMATION,
          contextText: MESSAGE_TYPE.INFORMATION,
          info: 'No files found for the specified criteria.',
        });
      })
      .finally(() => {
        setLoading(false); 
      });
  };
  
const getData = (recentProj) => {
    let headerss = new Headers();
    headerss.append("Content-Type", "application/json");
    // const url = auth.userRole[0] === 'Vendor' ? 'SupplierDvaCollection' : 'SupplierDvaCollectionAdminGDC'
    const url = auth.userRole[0] === 'Vendor' ? 'ShowVendorData' : 'SupplierDvaCollectionAdminGDCshow'
    const formattedBody = {
      // Project_code: latestProject?.value
      Project_code: recentProj?.value
    }
    // fetch(`${baseUrl}SupplierDvaCollection/`, {
    fetch(`${baseUrl}LoadVendorcodeandplantwrtprojectcode/`, {
      method: "POST",
      headers: headerss,
      body: JSON.stringify(formattedBody)
      // body: formattedBody
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log('responseJson: ', responseJson);
      // Extract unique values for different fields
        const uniqueSuppliers = [...new Set(responseJson.map(item => item.Supplier_code))].filter(Boolean);
        const uniquePlants = [...new Set(responseJson.map(item => item.Plant_code))].filter(Boolean);
        const uniqueQuarters = [...new Set(responseJson.map(item => item.Quarter))].filter(Boolean);
        const uniqueYears = [...new Set(responseJson.map(item => item.Financial_year))].filter(Boolean);

          if (bcArray.length === 0) {
          setBcArray(uniqueSuppliers.map(value => ({ value, label: value })));
        }
        console.log(bcArray)
        // setProjectCodeArray(uniqueProjects.map(value => ({ value, label: value })));
        console.log(projectCodeArray)
        setPlantArray(uniquePlants.map(value => ({ value, label: value })));
        setQuarterArray(uniqueQuarters.map(value => ({ value, label: value })));
        setFyArray(uniqueYears.map(value => ({ value, label: value })));

        // Extract userRole and userID from auth object
        // const { userRole, userID } = auth;
        console.log('User Role:', userRole, 'User ID:', userID);

        // Check user role to conditionally set BC state
        if (userRole.includes("Vendor-CV") || userRole.includes("Vendor")) {
          const singleVendor = bcArray.find(item => item.value === userID);
          if (singleVendor) {
            setBC(singleVendor);
            setDisable(true); // Disable UI elements
          }
        } else {
          setDisable(false); // Enable UI elements
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        // Handle error (e.g., show error message)
      });
  };

 

  const uploadFileData = () => {
    var input = document.createElement("input");
    input.type = "file";
    input.id = "file";
    input.setAttribute(
      "accept",
      ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
    );
    input.click();
    input.onchange = (e) => {
      setLoader(true)
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      fetch(`${baseUrl}SupplierDataaddition/`, {
        method: "POST",
        body: formData,
      })
        .then((response) => {
          // eslint-disable-next-line radix
          let status1 = parseInt(response.status);
          if (status1 == "201") {
            //   document.removeChild(input);
            getTableData();
            showPopup({
              type: MESSAGE_TYPE.SUCCESS,
              contextText: MESSAGE_TYPE.SUCCESS,
              info: "file uploaded sucessfully",
            });
          } else {
            showPopup({
              type: MESSAGE_TYPE.SUCCESS,
              contextText: MESSAGE_TYPE.SUCCESS,
              info: "file uploaded failed",
            });
          }
        })

        .catch((error) => { });
    };
  };

  
    
    

    



  const baseDefaultColumns = [
    // {
    //   width: 130,
    //   title: "Project Code",
    //   field: "Project_code",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    // {
    //   width: 130,
    //   title: "Plant code",
    //   field: "Plant_code",
    //   enableSearch: true,
    //   enableFilter: true,
    // },
    
  ];

 
const baseDefaultColumnsGDC = [
    {
        width: 150,
        title: "Project Code",
        field: "projectCode", 
        enableSearch: true,
        enableFilter: true,
    },
    {
        width: 150,
        title: "Supplier Code",
        field: "supplierCode", 
        enableSearch: true,
        enableFilter: true,
    },
    {
        width: 150,
        title: "Part Number",
        field: "partNo", 
        enableSearch: true,
        enableFilter: true,
    },
    {
        width: 310,
        title: "File Name",
        field: "fileName",
        enableSearch: true,
        enableFilter: true,
    },
    {
        width: 150,
        title: "Download",
        field: "download",
        render: (rowData) => (
            <Button
                className={clsx(styles.actionButton, styles.primaryActionButton)}
                variant='primary'
                onClick={() => downloadDvaDocuments(rowData.fileUrl)} 
                data-testid='confirm-action'
                style={{
                    padding: '4px 8px',
                    minWidth: '70px',
                    height: '24px',
                    margin: '4px 0',
                }}
            >
                Download
            </Button>
        ),
    }
];


const validationAPIFunction = async () => {
    let url;
    const dte = new Date();
    const formattedDate = dte.toLocaleDateString('en-GB').replace(/\//g, '-');
    console.log("username", username);
  
 let apiBodyValidated = {
      Project_code: projectCode?.value,
      Supplier_code: Bc?.value,
      Plant_code: plant?.value,
      Financial_year: Fy?.value,
      Quarter: quarter?.value,
    };
  
    // Conditionally add the fields based on the userRole
    if (userRole?.includes("Purchase")) {
      apiBodyValidated = {
        ...apiBodyValidated,
        Purchase_submitted_Name: username,
        Purchase_submitted_Date: formattedDate,
      };
      url = 'PurchaseSubmissionstatus';
    } else if (userRole?.includes('TMLBSL')) {
      apiBodyValidated = {
        ...apiBodyValidated,
        TMLBSL_submitted_Name: username,
        TMLBSL_submitted_Date: formattedDate,
      };
      url = 'TMLBSLSubmissionstatus';
    }
  
    const apiFormattedDataJson = JSON.stringify(apiBodyValidated);
    const headerss = {
      'Content-Type': 'application/json',
    };
  
    try {
      const response = await axios.post(`${baseUrl}${url}/`, apiFormattedDataJson, { headers: headerss });
      console.log('response from axios: ', response);
      if (response.data?.ok || response?.status == 200) {
        showPopup({
          type: MESSAGE_TYPE.SUCCESS,
          contextText: MESSAGE_TYPE.SUCCESS,
          info: 'Data validation successful!',
        });
      } else {
        showPopup({
          type: MESSAGE_TYPE.FAILURE,
          contextText: MESSAGE_TYPE.FAILURE,
          info: 'Data validation failed!',
        });
      }
  
      history.push('/');
    } catch (error) {
      console.error('API error: ', error);
      showPopup({
        type: MESSAGE_TYPE.FAILURE,
        contextText: MESSAGE_TYPE.FAILURE,
        info: 'An error occurred during data validation!',
      });
    }
  };
  

  const goNextForm = async () => {
    let url;
    // let { userRole } = auth;
  
    if (userRole.includes("Purchase")) {
      url = 'SendBackPurchaseStatusForSupplierDva'
      // url = "SupplierDataaddition"
      // urlMethod = "POST"
    } else if (userRole?.includes('TMLBSL')) {
      url = 'SendBackTMLBSLStatusForSupplierDva'
    }
  
    const apiFormattedDataJson = JSON.stringify(apiBody)
    const headerss = {
      'Content-Type': 'application/json'
    };
  
    try {
      const response = await axios.post(`${baseUrl}${url}/`, apiFormattedDataJson, { headers: headerss });

      if (response.data?.ok || response?.data?.status === 'Records updated successfully' || response?.status == '200') {
        showPopup({
          type: MESSAGE_TYPE.SUCCESS,
          contextText: MESSAGE_TYPE.SUCCESS,
          info: 'Data sent back for Re-Submission'
        });
      } else {
        showPopup({
          type: MESSAGE_TYPE.FAILURE,
          contextText: MESSAGE_TYPE.FAILURE,
          info: 'Could not send data for Re-Submission!'
        });
      }
  
      history.push('/')
    } catch (error) {
      console.error('API error: ', error);
      showPopup({
        type: MESSAGE_TYPE.FAILURE,
        contextText: MESSAGE_TYPE.FAILURE,
        info: 'An error occurred while sending data for Re-Submission!'
      });
    }
  };
  
  const formattedCurrentDate = new Date().toLocaleDateString('en-GB').split('/').join('/')

  const submitSupplierDVACollection = async () => {
    const newAPIBody = {
      ...apiBody,
      Vendor_submitted_Date: formattedCurrentDate
    }
    const apiFormattedDataJson = JSON.stringify(newAPIBody);
    const headerss = {
      'Content-Type': 'application/json'
    };
  
    try {
      // const response = await axios.post(`${baseUrl}SubmitStatusUpdate/`, apiFormattedDataJson, { headers: headerss });
      const response = await axios.post(`${baseUrl}SupplierSubmissionstatus/`, apiFormattedDataJson, { headers: headerss });
      
      // Handle the response
      console.log("responseJson", response.data);
  
      showPopup({
        type: MESSAGE_TYPE.SUCCESS,
        contextText: MESSAGE_TYPE.SUCCESS,
        info: "Submitted Successfully!",
      })
      history.push('/')
    } catch (error) {
      console.error('API error:', error);
      
      showPopup({
        // type: MESSAGE_TYPE.FAILURE,
        // contextText: MESSAGE_TYPE.FAILURE,
        type: MESSAGE_TYPE.SUCCESS,
        contextText: MESSAGE_TYPE.SUCCESS,
        // info: 'An error occurred while submitting Supplier DVA!'
        info: 'Submitted Successfully!'
      });
    }
  };
  

  const redirectToCreateProjectPage = () => history.push("/");
//   const renderHTML = () => (
//     <div className={styles.formGroup}>

//       <Grid
//         container
//         spacing={1}

//         style={{ display: 'flex', justifyContent: "left" }}
//         columns={12}
//       >
//         <Grid
//           item
//           // sm={12}
//           md={2}
//           lg={2}
//           // style={{ marginTop: "5px", marginBottom: '5px' }}
//         >
//           <div className={styles.rowDiv}>
//             <div className={clsx(styles.lableDiv)} style={{ width: '30%' }}>
//               <label className={styles.label}>Project </label>
//             </div>
//             <div className={styles.formDiv}>
//               <CustomSelect
//                 name='Project Code'
//                 options={projectCodeArray}
//                 // isDisabled={checkDisable}
//                 className={clsx(styles.select, styles.sel1)}
//                 // value={projectCode}
//                 value={selectedProjectCode}
//                 defaultValue={latestProject}
//                 isMulti={false}
//                 isMandatory={
//                   auth.userRole[0] === 'Vendor' || auth.userRole[0] === 'admin'
//                 }
//                 isClearable
//                 onChange={selectedOption => {
//                   // handleSelect(e, "VC");
//                   setProjectCode(selectedOption)
//                   setSelectedProjectCode(selectedOption)
//                 }}
//               />
//             </div>
//           </div>
//         </Grid>

//         <Grid 
//           item 
//           md={3} 
//           lg={3} 
//           // sm={12} 
//           // style={{ marginTop: "5px", marginBottom: '5px' }}
//         >
//           <div className={styles.rowDiv}>
//             <div className={clsx(styles.lableDiv)} style={{ width: "33%" }}>
//               <label className={styles.label}>Supplier</label>
//             </div>
//             <div className={styles.formDiv} >
//               <CustomSelect
//                 name="bc"
//                 options={bcArray}
//                 isDisabled={checkDisable}
//                 isMandatory={auth.userRole[0] === 'Vendor'}
//                 className={clsx(styles.select, styles.sel1)}
//                 value={Bc}
//                 isMulti={false}
//                 isClearable
//                 onChange={(selectedOption) => {
//                   // handleSelect(e, "VC");
//                   setBC(selectedOption)
//                 }}
//               />
//             </div>
//           </div>
//         </Grid>

//         <Grid item md={3} lg={3}>
//   <div className={styles.rowDiv}>
//     <div className={clsx(styles.lableDiv)} style={{ width: "33%" }}>
//       <label className={styles.label}>Part No</label>
//     </div>
//     <div className={styles.formDiv}>
//       <CustomSelect
//         name="VC"
//         options={vcArray}
//         className={clsx(styles.select, styles.sel1)}
//         value={selectedVc}
//         isMulti={false}
//         isClearable
//         onChange={(selectedOption) => {
//           setSelectedVc(selectedOption); // Set the selected VC value
//         }}
//       />
//     </div>
//   </div>
// </Grid>
// <Grid item md={3} lg={3}>
//         <div className={styles.rowDiv}>
//           <div className={clsx(styles.lableDiv)} style={{ width: '20%' }}>
//             <label className={styles.label}></label>
//           </div>
//           <div className={styles.formDiv}>
//           <ValidatingTextField
//             variant="outlined"
//             size="small"
//             fullWidth
//             value={selectedVc} // Bound to the updated state
//             name="projDesc"
//             inputProps={{
//            'data-testid': 'remark-input',
//          }}
//          isDisabled // Boolean attribute corrected
//          />
//           </div>
//         </div>
//       </Grid>
//         {/* <Grid item sm={12} md={5} lg={4} style={{ marginTop: "5px", marginBottom: '5px' }}> */}
//           {/* <div className={styles.rowDiv}>
//             <div className={clsx(styles.lableDiv)} style={{ width: "40%" }}>
//               <label className={styles.label}>Financial Year</label>
//             </div>
//             <div className={styles.formDiv} >
//               <CustomSelect
//                 name="Fy"
//                 options={fyArray}
//                 // isDisabled={checkDisable}
//                 className={clsx(styles.select, styles.sel1)}
//                 value={Fy}
//                 isMulti={false}
//                 // isMandatory = {auth.userRole === ''}
//                 isClearable
//                 onChange={(selectedOption) => {
//                   // handleSelect(e, "VC");
//                   setFY(selectedOption)
//                 }}
//               />
//             </div>
//           </div> */}
//         {/* </Grid> */}
//         {/* <Grid item xs={12} md={5} lg={4} style={{ marginTop: "5px", marginBottom: '5px' }}> */}
//           {/* <div className={styles.rowDiv}>
//             <div className={clsx(styles.lableDiv)} style={{ width: "40%" }}>
//               <label className={styles.label}>Quarter</label>
//             </div>
//             <div className={styles.formDiv}>
//               <CustomSelect
//                 name="Quarter"
//                 options={quarterArray}
//                 // isDisabled={checkDisable}
//                 className={clsx(styles.select, styles.sel1)}
//                 value={quarter}
//                 isMulti={false}
//                 isClearable
//                 onChange={(selectedOption) => {
//                   // handleSelect(e, "VC");
//                   setQuarter(selectedOption)
//                 }}
//               />
//             </div>
//           </div> */}
//         {/* </Grid> */}

//         {/* <Grid item md={2} lg={2} sm={12}>
//          */}


//       <Grid
//         item
//         md={3}
//         lg={3}
//         // sm={12}
//         // style={{ marginTop: '5px', marginBottom: '5px' }}
//       >
//         <div
//           style={{
//             marginLeft: '5px',
//             paddingRight: '',
//             marginTop: '1px',
//             display: 'flex',
//             justifyContent: 'right'
//           }}
//         >
//           <AuthChecker operation='search'>
//             {isAuthorized => (
//               <Button
//                 className={clsx(styles.actionButton, styles.primaryActionButton)}
//                 style={{ marginRight: '12px !important' }}
//                 variant='primary'
//                 //   onClick={() => runPreSubmissionChecks() && handleSubmit()}
//                 onClick={showTable}
//                 disabled={!isAuthorized}
//                 data-testid='confirm-action'
//               >
//                 Show Data
//               </Button>
//             )}
//           </AuthChecker>
//            </div>
//       </Grid>


//       </Grid>

//     </div>
//   );

// const renderHTML = () => (
//   <div className={styles.formGroup}>
//     <Grid
//       container
//       spacing={2} // Adjust spacing between grid items
//       alignItems="center" // Align items vertically
//       wrap="nowrap" // Prevent items from wrapping to the next line
//     >
//       {/* Project Code */}
//       <Grid item md={4} lg={4}>
//         <div className={styles.rowDiv} style={{ gap: '10px' }}>
//           <label className={styles.label} style={{ marginRight: '8px' }}>
//             Project
//           </label>
//           <CustomSelect
//             name="Project Code"
//             options={projectCodeArray}
//             className={clsx(styles.select, styles.sel1)}
//             style={{ width: '100%' }}
//             value={selectedProjectCode}
//             defaultValue={latestProject}
//             isMulti={false}
//             isMandatory={auth.userRole[0] === 'Vendor' || auth.userRole[0] === 'admin'}
//             isClearable
//             onChange={(selectedOption) => {
//               setProjectCode(selectedOption);
//               setSelectedProjectCode(selectedOption);
//             }}
//           />
//         </div>
//       </Grid>

//       {/* Supplier */}
//       <Grid item md={4} lg={4}>
//         <div className={styles.rowDiv} style={{ gap: '10px' }}>
//           <label className={styles.label} style={{ marginRight: '8px' }}>
//             Supplier
//           </label>
//           <CustomSelect
//             name="Supplier"
//             options={bcArray}
//             className={clsx(styles.select, styles.sel1)}
//             style={{ width: '100%' }}
//             value={Bc}
//             isDisabled={checkDisable}
//             isMandatory={auth.userRole[0] === 'Vendor'}
//             isClearable
//             onChange={(selectedOption) => {
//               setBC(selectedOption);
//             }}
//           />
//         </div>
//       </Grid>

//       {/* Part No */}
//       <Grid item md={4} lg={4}>
//         <div className={styles.rowDiv} style={{ gap: '10px' }}>
//           <label className={styles.label} style={{ marginRight: '8px' }}>
//             Part No
//           </label>
//           <CustomSelect
//             name="Part No"
//             options={vcArray}
//             className={clsx(styles.select, styles.sel1)}
//             style={{ width: '100%' }}
//             value={selectedVc}
//             isMulti={false}
//             isClearable
//             onChange={(selectedOption) => {
//               setSelectedVc(selectedOption);
//             }}
//           />
//         </div>
//       </Grid>

//       {/* Part Desc */}
//       <Grid item md={4} lg={4}>
//         <div className={styles.rowDiv} style={{ gap: '10px' }}>
//           <label className={styles.label} style={{ marginRight: '8px' }}>
//             Part Desc
//           </label>
//           <ValidatingTextField
//             variant="outlined"
//             size="small"
//             fullWidth
//             value={selectedVc?.description || ''}
//             name="partDesc"
//             inputProps={{ 'data-testid': 'part-desc-input' }}
//             isDisabled
//           />
         
//         </div>
//       </Grid>

//       {/* Show Data Button */}
//       <Grid item md={2} lg={2}>
//         <div
//           style={{
//             display: 'flex',
//             // justifyContent: 'flex-end',
//           }}
//         >
//           <AuthChecker operation="search">
//             {(isAuthorized) => (
//               <Button
//                 className={clsx(styles.actionButton, styles.primaryActionButton)}
//                 variant="primary"
//                 onClick={showTable}
//                 disabled={!isAuthorized}
//                 data-testid="show-data-button"
//               >
//                 Show Data
//               </Button>
//             )}
//           </AuthChecker>
//         </div>
//       </Grid>
//     </Grid>
//   </div>
// );





const renderHTML = () => (
  <div className={styles.formGroup}>
    {
      loading && (
        <div className={styles.gridLoader}>
          <GridLoadingSpinner />
        </div>
      )
    }

    <Grid
      container
      spacing={2}

      style={{ display: 'flex', justifyContent: "space-between" }}
      columns={12}
    >
      <Grid
        item
        sm={12}
        md={4}
        lg={4}
        // style={{ marginTop: "5px", marginBottom: '5px' }}
      >
      <div
        style={{
          marginLeft: '5px',
          paddingRight: '',
          // marginTop: '1px',
          display: 'flex',
          justifyContent: 'right'
        }}
      >
        <div className={styles.rowDiv}>
          <div className={clsx(styles.lableDiv)} style={{ width: '34%' }}>
            <label className={styles.label}>Project Code</label>
          </div>
          <div className={styles.formDiv}>
          <CustomSelect
                name='Project Code'
                options={projectCodeArray}
                // isDisabled={checkDisable}
                className={clsx(styles.select, styles.sel1)}
                // value={projectCode}
                value={selectedProjectCode}
                defaultValue={latestProject}
                isMulti={false}
                isMandatory={
                  auth.userRole[0] === 'Vendor' || auth.userRole[0] === 'admin'
                }
                isClearable
                onChange={selectedOption => {
                  // handleSelect(e, "VC");
                  setProjectCode(selectedOption)
                  setSelectedProjectCode(selectedOption)
                  getData(selectedOption)
                }}
              />
          </div>
          </div>
        </div>
      </Grid>

      <Grid 
        item 
        md={4} 
        lg={4} 
        // sm={12} 
        // style={{ marginTop: "5px", marginBottom: '5px' }}
      >
        <div className={styles.rowDiv}>
          <div className={clsx(styles.lableDiv)} style={{ width: "33%" }}>
            <label className={styles.label}>Supplier</label>
          </div>
          <div className={styles.formDiv} >
          <CustomSelect
                name="bc"
                options={bcArray}
                isDisabled={checkDisable}
                isMandatory={auth.userRole[0] === 'Vendor'}
                className={clsx(styles.select, styles.sel1)}
                value={Bc}
                isMulti={false}
                isClearable
                onChange={(selectedOption) => {
                  // handleSelect(e, "VC");
                  setBC(selectedOption)
                }}
              />
          </div>
        </div>
      </Grid>

      {/* <Grid
        item
        md={3}
        lg={3}
        // sm={12}
        // style={{ marginTop: '5px', marginBottom: '5px' }}
      >
        <div className={styles.rowDiv}>
          <div className={clsx(styles.lableDiv)} style={{ width: '20%' }}>
            <label className={styles.label}>Plant</label>
          </div>
          <div className={styles.formDiv}>
            <CustomSelect
              name='Plant'
              options={plantArray}
              // isDisabled={checkDisable}
              className={clsx(styles.select, styles.sel1)}
              value={plant}
              isMulti={false}
              isClearable
              isMandatory={auth.userRole[0] === 'Vendor'}
              onChange={selectedOption => {
                // handleSelect(e, "VC");
                setPlant(selectedOption)
              }}
            />
          </div>
        </div>
      </Grid> */}

      
 <Grid item md={4} lg={4}>
      <div className={styles.rowDiv}>
        <div className={clsx(styles.lableDiv)} style={{ width: '30%' }}>
          <label className={styles.label}>Part No</label>
        </div>
        <div className={styles.formDiv}>
        <CustomSelect
            name="Part No"
            options={vcArray}
            className={clsx(styles.select, styles.sel1)}
            style={{ width: '100%' }}
            value={selectedVc}
            isMulti={false}
            isClearable
            onChange={(selectedOption) => {
              setSelectedVc(selectedOption);
            }}
          />
         
        </div>
      </div>
    </Grid>

    

      </Grid>

      {/* <Grid item sm={12} md={5} lg={4} style={{ marginTop: "5px", marginBottom: '5px' }}> */}
        {/* <div className={styles.rowDiv}>
          <div className={clsx(styles.lableDiv)} style={{ width: "40%" }}>
            <label className={styles.label}>Financial Year</label>
          </div>
          <div className={styles.formDiv} >
            <CustomSelect
              name="Fy"
              options={fyArray}
              // isDisabled={checkDisable}
              className={clsx(styles.select, styles.sel1)}
              value={Fy}
              isMulti={false}
              // isMandatory = {auth.userRole === ''}
              isClearable
              onChange={(selectedOption) => {
                // handleSelect(e, "VC");
                setFY(selectedOption)
              }}
            />
          </div>
        </div> */}
      {/* </Grid> */}
      {/* <Grid item xs={12} md={5} lg={4} style={{ marginTop: "5px", marginBottom: '5px' }}> */}
        {/* <div className={styles.rowDiv}>
          <div className={clsx(styles.lableDiv)} style={{ width: "40%" }}>
            <label className={styles.label}>Quarter</label>
          </div>
          <div className={styles.formDiv}>
            <CustomSelect
              name="Quarter"
              options={quarterArray}
              // isDisabled={checkDisable}
              className={clsx(styles.select, styles.sel1)}
              value={quarter}
              isMulti={false}
              isClearable
              onChange={(selectedOption) => {
                // handleSelect(e, "VC");
                setQuarter(selectedOption)
              }}
            />
          </div>
        </div> */}
      {/* </Grid> */}

      {/* <Grid item md={2} lg={2} sm={12}>
       */}


    {/* Project Desc. View */}
      <Grid
        container
        spacing={1}

        style={{ display: 'flex', justifyContent: "space-between" }}
        columns={12}
      >
        <Grid
          item
          xs={5}
          md={5}
          lg={5}
          style={{
            marginTop: '5px',
            display: 'flex',
            // justifyContent: 'center',
            alignItems: 'center'
          }}
        >
            <div className={styles.rowDiv}>
              <div className={clsx(styles.lableDiv)} style={{ width: '26%' }}>
                <label className={styles.label}>Project Desc.</label>
              </div>
              {/* <div className={styles.formDiv}>
                  <Grid item xs={3} className={styles.alignCenter}> */}
              <div className={styles.formDiv}>
                <ValidatingTextField
                  variant='outlined'
                  size='small'
                  fullWidth
                  // value={vendorCode?.vendorCode}
                  // value={recentProjectCodeDescription && recentProjectCodeDescription}
                  value={projectDescription && projectDescription}
                  name='projDesc'
                  inputProps={{
                    'data-testid': 'remark-input'
                  }}
                  onChange={selectedOption => handleDropdownChange(selectedOption)}
                  // eslint-disable-next-line react/jsx-boolean-value
                  isDisabled={true}
                />
              </div>
              {/* <span className={styles.symbolCSS}>&#x20b9;</span> */}
              {/* </Grid> */}
              {/* </div> */}
            </div>
        </Grid>
        <Grid
          item
          xs={4}
          md={4}
          lg={4}
          style={{
            marginTop: '5px',
            display: 'flex',
            // justifyContent: 'center',
            alignItems: 'center'
          }}
        >
      <div className={styles.rowDiv}>
        <div className={clsx(styles.lableDiv)} style={{ width: '26%' }}>
          <label className={styles.label}>Part Desc.</label>
        </div>
        <div className={styles.formDiv}>
        <ValidatingTextField
            variant="outlined"
            size="small"
            fullWidth
            value={selectedVc?.description || ''}
            name="partDesc"
            inputProps={{ 'data-testid': 'part-desc-input' }}
            isDisabled
          />
        </div>
      </div>
    </Grid>

        <Grid
          item
          md={3}
          lg={3}
          sm={3}
        // style={{ marginTop: '5px', marginBottom: '5px' }}
        >
          <div
            style={{
              marginLeft: '5px',
              paddingRight: '',
              marginTop: '5px',
              display: 'flex',
              justifyContent: 'right'
            }}
          >
            <AuthChecker operation='search'>
              {isAuthorized => (
                <Button
                className={clsx(styles.actionButton, styles.primaryActionButton)}
                style={{ marginRight: '12px !important' }}
                variant='primary'
                //   onClick={() => runPreSubmissionChecks() && handleSubmit()}
                onClick={showTable}
                disabled={!isAuthorized}
                data-testid='confirm-action'
              >
                Show Data
              </Button>
              )}
            </AuthChecker>
            
          </div>
        </Grid>
      </Grid>

  
  </div>
)



const downloadDvaDocuments = (fileUrl) => {
    const formData = new FormData();
    formData.append('Project_code', projectCode?.value);
    formData.append('Supplier_code', Bc?.value);
    formData.append('Part_no', selectedVc?.value);

    // First, call the MpfdDownloadFromS3 endpoint if needed
    fetch(`${baseUrl}MpfdDownloadFromS3PV/`, {
        method: 'POST',
        body: formData
    })
    .then(async response => {
        const res = await response.json();

        // Check for any errors from the API response
        if (res?.error) {
            showPopup({
                type: MESSAGE_TYPE.FAILURE,
                contextText: MESSAGE_TYPE.FAILURE,
                info: 'Failed to fetch document information'
            });
        } else {
            // Proceed to download the specified file
            const link = document.createElement('a');
            link.href = fileUrl; // Use the passed fileUrl for downloading
            document.body.appendChild(link);
            link.dispatchEvent(
                new MouseEvent('click', {
                    bubbles: true,
                    cancelable: true,
                    view: window
                })
            );
            document.body.removeChild(link);
        }
    })
    .catch(error => {
        console.log('Error while fetching document information: ', error);
        showPopup({
            type: MESSAGE_TYPE.INFORMATION,
            contextText: MESSAGE_TYPE.INFORMATION,
            info: 'Error while processing document download'
        });
    });
};




  const secondaryActions = [
    // {
    //   name: "Upload",
    //   authOperation: "upload",
    //   shouldEnable: (selected) => true,
    //   // shouldEnable : (selected,userRole) => auth.userRole.includes('admin','Vendor')  ,
    //   actionFn: (selected) => {
    //     // handleOpen();
    //     uploadDocument()
    //   },
    //   customClass: "",
    // },
    // {
    //   name: "Generate Certificate",
    //   authOperation: "submit",
    //   shouldEnable: (selected) => true,
    //   // shouldEnable : (selected,userRole) => auth.userRole.includes('admin','Vendor')  ,
    //   actionFn: (selected) => {
    //     downloadFile();
    //   },
    //   customClass: "",
    // },
    // {
    //   name: "Upload Certificate",
    //   authOperation: "uploadCertificate",
    //   shouldEnable: (selected) => true,
    //   // shouldEnable : (selected,userRole) => auth.userRole.includes('admin','Vendor'),
    //   actionFn: (selected) => {
    //     Uploadcertification();
    //   },
    //   customClass: "",
    // },
    // {
    //   name: "Other Documents",
    //   authOperation: "uploadAnnexure",
    //   shouldEnable: (selected) => true,
    //   // shouldEnable : (selected,userRole) => auth.userRole.includes('admin','Vendor')  ,
    //   actionFn: (selected) => {
    //     Uploadannexure();
    //   },
    //   customClass: "",
    // },
    // {
    //   name: "Download Certificate",
    //   // authOperation: "uploadAnnexure",
    //   authOperation: "download",
    //   shouldEnable: (selected) => true,
    //   // shouldEnable : (selected,userRole) => auth.userRole.includes('admin','TMLBSL','GDC','Purchase'),
    //   actionFn: (selected) => {
    //     downloadDvaCertificate();
    //   },
    //   customClass: "",
    // },
    // {
    //   name: "Download Documents",
    //   // authOperation: "uploadAnnexure",
    //   authOperation: "download",
    //   shouldEnable: (selected) => true,
    //   // shouldEnable: (selected) => auth.userRole.some(role => ['admin', 'TMLBSL', 'GDC', 'Purchase'].includes(role)) ,
    //   actionFn: (selected) => {
    //     // Uploadannexure();
    //     downloadDvaDocuments()
    //   },
    //   customClass: "",
    // },

  ]
  const primaryActions = [
    // {
    //   name: "Submit Data",
    //   authOperation: "submit",
    //   shouldEnable: () => !isSubmitDataDisabled,  // Disable if all statuses are 'Submitted'
    //   // shouldEnable: () => buttonFlags.submitData, // Enable based on Submit Data flag
    //   actionFn: handleOpenSubmissionPopup,
    // }, {
    //   name: "Validate",
    //   authOperation: "submit",
    //   // shouldEnable: () => !submitButtonFlag(),
    //   // shouldEnable: () => buttonFlags.validate, // Enable based on Validate flag
    //   shouldEnable: () => isValidateEnabled,  // Use the updated flag
    //   // shouldEnable: () => submitButtonFlag(),
    //   actionFn: handleOpenValidatePopup,
    //   // actionFn: handleOpenFormModal,
    // }, {
    //   name: "Send Back",
    //   authOperation: "submit",
    //   shouldEnable: () => true,
    //   // shouldEnable: () => submitButtonFlag(),
    //   actionFn: handleOpenFormModal,
    // }

  ]
 const handleSecondaryActionOnRole = () => {
    if (auth.userRole.includes('admin')) {
      return secondaryActions;
    }
    if (auth.userRole.includes('GDC') || auth.userRole.includes('TMLBSL') || auth.userRole.includes('Purchase')) {
      return secondaryActions.filter((item) => item.name === 'Download Documents' || item.name === 'Download Certificate' || item.name === 'Upload');
    }
    if (auth.userRole.includes('Vendor')) {
      return secondaryActions.filter((item) => item.name !== 'Download Documents' && item.name !== 'Download Certificate');
    }
    return secondaryActions;
  };
  const handlePrimaryActionOnRole = () => {
    if (auth.userRole.includes('Vendor')) {
      return primaryActions.filter((item) => item.name === 'Submit Data')
    }
    if (auth.userRole.includes('GDC') || auth.userRole.includes('TMLBSL') || auth.userRole.includes('Purchase') || auth.userRole.includes('admin')) {
      return primaryActions.filter((item) => item.name === 'Validate' || item.name === 'Send Back')
    }
    return primaryActions
  }

  // console.log('tableArray',tableArray);
  return (
    <>
      <div className={styles.container} style={{ boxSizing: "border-box" }}>
        <div>
          <div className={styles.backButtonLastRefreshedDateWrapper} style={{ marginTop: '10px' }}>
            <BackButton
              action="Back"
              handleClick={redirectToApplicationLandingPage}
              style={{ marginBottom: '0px' }}
            />
            <div
              style={{ width: '100%' }}
            // style={{ display: 'flex', flexDirection: 'row-reverse' }}
            >
              <div className={styles.topDiv}>
                <div className={styles.processHeader} style={{ display: 'flex', height: '3em' }}>
                  <div style={{ width: "100%" }}>
                    <CustomTab title="MPFD" withOutCount isSelected />
                  </div>

                  {/* <StepBar1 enable={5} /> */}

                </div>
              </div>
            </div>


          </div>


        </div>
        <div>
          <div className={styles.formContainer}>
            <CustomFormGroup body={renderHTML()} />
          </div>
        </div>
        {flag && (
          <div
            className={styles.tableView}
            style={{ height: "86%", padding: "0 2em" }}
          >
            <Table
              // columns={[...baseDefaultColumns]}
              // defaultColumns={[...baseDefaultColumns]}
              columns={!auth.userRole.includes('Vendor') ? [...baseDefaultColumnsGDC] : [...baseDefaultColumns]}
              defaultColumns={!auth.userRole.includes('Vendor') ? [...baseDefaultColumnsGDC] : [...baseDefaultColumns]}
              rowHeight={38}
              rows={tableArray}
              primaryAction={handlePrimaryActionOnRole()}
              secondaryActions={handleSecondaryActionOnRole()}
              setAsnCount={setCount}
              isDataLoading={loader}
              actionButtons
              onRowSelect={(e) => { }}
              removeRowSelection
            />
          </div>
        )}
      </div>
      {/* <Button
            className={clsx(
              styles.actionButton,
              styles.primaryActionButton,
            )}
            variant="primary"
            // onClick={onDelete}
          >
            Yes
          </Button> */}
      <ResetModalF
        isPopupOpen={isPopupOpenFormModal}
        handleCloseFormModal={handleCloseFormModal}
        goNextForm={goNextForm}
      />

      <ResetModal
        isPopupOpen={isPopupOpen}
        handleClose={handleClose}
        goNext={goNext}
      />
      <ValidationModal
        isValidatePopupOpen={isValidatePopupOpen}
        handleCloseValidatePopup={handleCloseValidatePopup}
        validationAPIFunction={validationAPIFunction}
      />
      <SubmitDataModal
        isSubmissionPopupOpen={isSubmissionPopupOpen}
        handleCloseSubmissionPopup={handleCloseSubmissionPopup}
        submitSupplierDVACollection={submitSupplierDVACollection}
      />
    </>
  );
};
function ValidationModal({ isValidatePopupOpen, handleCloseValidatePopup, validationAPIFunction }) {
  return (
    <Dialog
      open={isValidatePopupOpen}
      className={styles.popContainer}
      classes={{
        paper: styles.popupBox,
      }}
      data-testid="reset-popup"
    >
      <>
        <DialogTitle>
          <span className={styles.title}>
            <WarningIcon style={{ height: '18px', width: '18px' }} />
            <span style={{ marginLeft: "8px" }} className={styles.txt}>Confirm Validation</span>
          </span>
        </DialogTitle>
        <DialogContent className={styles.content}>
          <div>
            {/* <p> On confirmation, All existing data will be overwritten by the uploaded data.</p> */}
            <p>On confirmation, data will be validated.</p>
            <p>Are you sure ?</p>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            className={clsx(
              styles.actionButton,
              styles.transparentButton
            )}
            onClick={handleCloseValidatePopup}
          >Cancel</Button>
          <Button
            className={clsx(
              styles.actionButton,
              styles.primaryActionButton,
            )}
            variant="primary"
            onClick={(e) => {
              e.preventDefault();
              handleCloseValidatePopup()
              validationAPIFunction()
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </>
    </Dialog>
  )
}
function SubmitDataModal({ isSubmissionPopupOpen, handleCloseSubmissionPopup, submitSupplierDVACollection }) {
  return (
    <Dialog
      open={isSubmissionPopupOpen}
      className={styles.popContainer}
      classes={{
        paper: styles.popupBox,
      }}
      data-testid="reset-popup"
    >
      <>
        <DialogTitle>
          <span className={styles.title}>
            <WarningIcon style={{ height: '18px', width: '18px' }} />
            <span style={{ marginLeft: "8px" }} className={styles.txt}>Confirm Submission</span>
          </span>
        </DialogTitle>
        <DialogContent className={styles.content}>
          <div>
            {/* <p> On confirmation, All existing data will be overwritten by the uploaded data.</p> */}
            <p>On confirmation, data will be Submitted.</p>
            <p>Are you sure ?</p>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            className={clsx(
              styles.actionButton,
              styles.transparentButton
            )}
            onClick={handleCloseSubmissionPopup}
          >Cancel</Button>
          <Button
            className={clsx(
              styles.actionButton,
              styles.primaryActionButton,
            )}
            variant="primary"
            onClick={(e) => {
              e.preventDefault();
              handleCloseSubmissionPopup()
              submitSupplierDVACollection()
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </>
    </Dialog>
  )
}
function ResetModalF({ isPopupOpen, handleCloseFormModal, goNextForm }) {
  return (
    <Dialog
      open={isPopupOpen}
      className={styles.popContainer}
      classes={{
        paper: styles.popupBox,
      }}
      data-testid="reset-popup"
    >
      <>
        <DialogTitle>
          <span className={styles.title}>
            <WarningIcon style={{ height: '18px', width: '18px' }} />
            <span style={{ marginLeft: "8px" }} className={styles.txt}>Confirm Send Back</span>
          </span>
        </DialogTitle>
        <DialogContent className={styles.content}>
          <div>
            {/* <p> On confirmation, All existing data will be overwritten by the uploaded data.</p> */}
            <p>On confirmation, data will be sent back for Re-Submission</p>
            <p>Are you sure ?</p>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            className={clsx(
              styles.actionButton,
              styles.transparentButton
            )}
            onClick={handleCloseFormModal}
          >Cancel</Button>
          <Button
            className={clsx(
              styles.actionButton,
              styles.primaryActionButton,
            )}
            variant="primary"
            onClick={(e) => {
              e.preventDefault();
              handleCloseFormModal()
              goNextForm()
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </>
    </Dialog>
  )
}
function ResetModal({ isPopupOpen, handleClose, goNext }) {
  return (
    <Dialog
      open={isPopupOpen}
      className={styles.popContainer}
      classes={{
        paper: styles.popupBox,
      }}
      data-testid="reset-popup"
    >
      <>
        <DialogTitle>
          <span className={styles.title}>
            <WarningIcon style={{ height: "18px", width: "18px" }} />
            <span style={{ marginLeft: "8px" }} className={styles.txt}>
              Confirm file Upload
            </span>
          </span>
        </DialogTitle>
        <DialogContent className={styles.content}>
          <div>
            <p>
              {" "}

              On confirmation ,Records will be appended.
            </p>
            <p>Are you sure ?</p>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            className={clsx(styles.actionButton, styles.transparentButton)}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            className={clsx(styles.actionButton, styles.primaryActionButton)}
            variant="primary"
            onClick={(e) => {
              e.preventDefault();
              handleClose();
              goNext();
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </>
    </Dialog>
  );
}
ResetModalF.propTypes = {
  isPopupOpen: PropTypes.bool.isRequired,
  handleCloseFormModal: PropTypes.func.isRequired,
  goNextForm: PropTypes.func.isRequired,
};

ResetModal.propTypes = {
  isPopupOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  goNext: PropTypes.func.isRequired,
}

ValidationModal.propTypes = {
  isValidatePopupOpen: PropTypes.bool.isRequired,
  handleCloseValidatePopup: PropTypes.func.isRequired,
  validationAPIFunction: PropTypes.func.isRequired,
}
SubmitDataModal.propTypes = {
  isSubmissionPopupOpen: PropTypes.bool.isRequired,
  handleCloseSubmissionPopup: PropTypes.func.isRequired,
  submitSupplierDVACollection: PropTypes.func.isRequired,
}

export default withAllowedOperationsProvider(VendarData, RESOURCE_TYPE.PROJECT);
